import toast from 'react-hot-toast';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useJoinOneWorkspace } from 'src/api/workspace/useJoinOneWorkspace';
import { useWorkSpaceByInvitation } from 'src/api/workspace/workspace.service';
import LogoPurple from 'src/components/logo/LogoPurple';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import { useUser } from 'src/context/userContext';
import Media from '../../components/Media';

function AcceptInvitePageByLink() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const { user } = useUser();

  const {
    loading: workspaceInvitationLoading,
    data: workspaceInvitation,
    error: workspaceInvitationError,
  } = useWorkSpaceByInvitation({
    variables: { inviteToken: token },
  });

  const [joinOneWorkspace, { loading: joinWorkspaceLoading }] =
    useJoinOneWorkspace();

  const handleAcceptInvite = async () => {
    if (!workspaceInvitation) return;

    await joinOneWorkspace({
      variables: {
        where: { id: workspaceInvitation.workspaceByInviteToken.id },
      },
      onCompleted: async () => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={`Successfully joined ${workspaceInvitation.workspaceByInviteToken.name}`}
          />
        ));
        navigate(
          `/workspace/${workspaceInvitation.workspaceByInviteToken.slug}/project`
        );
      },
      onError: (error) => {
        toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
      },
    });
  };

  if (workspaceInvitationLoading || joinWorkspaceLoading) return <PageLoader />;

  if (workspaceInvitationError) {
    return (
      <div className="w-full flex flex-col gap-6 items-center justify-center">
        <div>
          <div className="flex items-center gap-1">
            <LogoPurple className="w-[18.655px] h-auto" />
            <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
              overlap
            </h1>
          </div>
        </div>
        <div className="max-w-[40rem] w-full flex p-8 flex-col items-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)]">
          <h1 className="text-heading-5-small md:text-2xl md:leading-8 font-semibold text-center text-[var(--text-default)]">
            Error
          </h1>
          <p className="px-3 py-1 font-normal text-center rounded bg-[var(--backgrounds-default)] text-[var(--text-on-bg)]">
            {workspaceInvitationError.message}
          </p>
        </div>
      </div>
    );
  }

  return (
    <div className="w-full flex flex-col gap-6 items-center justify-center">
      <div>
        <div className="flex items-center gap-1">
          <LogoPurple className="w-[18.655px] h-auto" />
          <h1 className="text-heading font-semibold text-2xl text-[var(--text-default)]">
            overlap
          </h1>
        </div>
      </div>
      <div className="flex p-8 flex-col items-center gap-6 rounded-2xl bg-[var(--backgrounds-on-canvas)]">
        <Media
          src={workspaceInvitation.workspaceByInviteToken.logo?.url}
          alt={workspaceInvitation.workspaceByInviteToken.name}
          width={40}
          height={40}
          fontSize={16}
        />
        <h1 className="text-heading-5-small md:text-2xl md:leading-8 font-semibold text-center text-[var(--text-default)]">
          {workspaceInvitation?.inviter?.detail?.fullName ?? 'The inviter'} has
          invited you to join {workspaceInvitation.workspaceByInviteToken.name}
          ’s workspace
        </h1>
        <p className="px-3 py-1 font-normal text-center rounded bg-[var(--backgrounds-default)] text-[var(--text-on-bg)]">
          You’re joining as{' '}
          <span className="font-semibold">
            {user?.detail?.fullName ?? 'human being'}
          </span>
        </p>
        <Button
          value={'Accept invitation'}
          size={'large'}
          btnType={'primary'}
          disabled={joinWorkspaceLoading}
          onClick={handleAcceptInvite}
          loading={joinWorkspaceLoading}
        />
      </div>
    </div>
  );
}

export default AcceptInvitePageByLink;
