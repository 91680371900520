import React from 'react';
import { cn } from 'src/lib/utils';
import '../ui.css';

declare module 'react' {
  interface CSSProperties {
    '--fill'?: string;
  }
}

interface StatusTagProps {
  color:
    | 'default'
    | 'info'
    | 'success'
    | 'negative'
    | 'warning'
    | 'message'
    | 'disabled';
  size: 'large' | 'medium' | 'small';
  type?: 'filled' | 'stroke';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  className?: string;
  value: string | number | React.ReactNode;
}

const colorStyles = {
  default: {
    color: 'var(--text-on-bg)',
    background: 'var(--backgrounds-default)',
    icon: 'var(--icon-primary)',
  },
  info: {
    color: 'var(--text-positive)',
    background: 'var(--backgrounds-positive)',
    icon: 'var(--icon-positive)',
  },
  success: {
    color: 'var(--text-success)',
    background: 'var(--backgrounds-success)',
    icon: 'var(--icon-success)',
  },
  negative: {
    color: 'var(--text-negative)',
    background: 'var(--backgrounds-nagative)',
    icon: 'var(--icon-negative)',
  },
  warning: {
    color: 'var(--text-warning)',
    background: 'var(--backgrounds-warning)',
    icon: 'var(--icon-warning)',
  },
  message: {
    color: 'var(--mentions-text)',
    background: 'var(--backgrounds-message)',
    icon: 'var(--icon-neutral)',
  },
  disabled: {
    color: 'var(--text-disabled)',
    background: 'var(--backgrounds-default)',
    icon: 'var(--icon-neutral)',
  },
};

const sizeStyles = {
  large: {
    fontSize: '14px',
    padding: '0px 8px',
    height: '28px',
    borderRadius: '8px',
    gap: '8px',
  },
  medium: {
    fontSize: '12px',
    padding: '0px 6px',
    height: '20px',
    borderRadius: '6px',
    gap: '4px',
  },
  small: {
    fontSize: '10px',
    padding: '0px 4px',
    height: '18px',
    borderRadius: '4px',
    gap: '2px',
  },
};

const StatusTag: React.FC<StatusTagProps> = ({
  color,
  size,
  type = 'filled',
  leftIcon,
  rightIcon,
  value,
  className,
}) => {
  const styles: React.CSSProperties = {
    ...colorStyles[color],
    ...sizeStyles[size],
    border:
      type === 'stroke' ? `1px solid ${colorStyles[color].color}` : 'none',
    display: 'flex',
    alignItems: 'center',
    '--fill': colorStyles[color]?.icon as string,
  };

  return (
    <div className="status-tag w-fit" style={styles}>
      {leftIcon && <div className="icon left-icon">{leftIcon}</div>}
      <div className={cn('medium', className)}>{value}</div>
      {rightIcon && <div className="icon right-icon">{rightIcon}</div>}
    </div>
  );
};

export default StatusTag;
