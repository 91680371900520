import { useDebounce, useLocalStorage } from '@uidotdev/usehooks';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';
import {
  useCreateOneUserWorkspace,
  useLazyWorkspace,
} from 'src/api/workspace/workspace.service';
import { Plus, Trash } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Button from 'src/components/ui/Button/Button';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import Spinner from 'src/components/ui/spinner/bar-spinner/Spinner';
import { useUser } from 'src/context/userContext';
import { useImageUploader } from 'src/hooks/useImageUploader';
import { MAX_FILE_UPLOAD_SIZE } from 'src/lib/consts';
import Validator from 'validatorjs';
import UrlInput from '../components/UrlInput';
import ValidationErrorMessages from '../components/ValidationErrorMessages';

type FormData = {
  name: string;
  slug: string;
  website: string;
};

function CreateWorkspacePage() {
  const navigate = useNavigate();
  const [userId] = useLocalStorage('userId', undefined);

  const { userSetupComplete, refetchUserData } = useUser();
  const { images, handleFileChange, removeImage } = useImageUploader({
    maxFiles: 1,
  });

  const [formData, setFormData] = useState<FormData>({
    name: '',
    slug: '',
    website: '',
  });
  const [isSlugManuallyEdited, setIsSlugManuallyEdited] = useState(false);

  const debouncedFormData = useDebounce(formData, 300);
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [createOneUserWorkspace, { loading, error }] =
    useCreateOneUserWorkspace();
  const errorMessage = error && `Submission error! ${error.message}`;
  const isUploadingImage = images.length > 0 && images[0].uploading;
  const hasUploadedImage =
    images.length > 0 && !images[0].uploading && !images[0].error;

  const [
    workspaceSlug,
    {
      data: workspaceSlugData,
      loading: workspaceSlugLoading,
      error: workspaceSlugError,
    },
  ] = useLazyWorkspace();

  const handleFileButton = () => {
    if (hasUploadedImage) {
      removeImage(0);
    } else {
      document.getElementById('workspace-logo')?.click();
    }
  };

  const handleLogoUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0].size > MAX_FILE_UPLOAD_SIZE.bytes) {
      toast.dismiss();
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={`The size limit for image uploads is ${MAX_FILE_UPLOAD_SIZE.humanReadable}. Want to try another image?`}
        />
      ));
    } else {
      handleFileChange(e.target.files);
    }
    if (e.target) e.target.value = '';
  };

  const handleInputChange =
    (field: keyof FormData) =>
    (e: React.ChangeEvent<HTMLInputElement> | string) => {
      const value = typeof e === 'string' ? e : e.target.value;
      setFormData({ ...formData, [field]: value });
      setFormErrors(null);
    };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      name: 'required',
      slug: 'required',
      website: 'required|url',
    });

    if (validation.passes()) {
      setFormErrors(null);

      const logoData =
        images[0] && !images[0].error
          ? {
              filename: images[0].file.name,
              mimetype: images[0].file.type,
              url: images[0].dataURL,
            }
          : undefined;

      createOneUserWorkspace({
        onCompleted: ({ createOneUserWorkspace }) => {
          if (!userSetupComplete) {
            navigate(
              `/workspace/${createOneUserWorkspace.workspace.slug}/get-started`
            );
            return;
          }

          navigate(
            `/invite-to-workspace?workspace=${createOneUserWorkspace.workspace.slug}`,
            {
              replace: true,
            }
          );
        },
        variables: {
          data: {
            user: {
              connect: { id: userId },
            },
            workspace: {
              create: {
                logo: logoData ? { set: logoData } : undefined,
                name: formData.name,
                slug: formData.slug,
                website: formData.website,
              },
            },
          },
        },
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  useEffect(() => {
    if (debouncedFormData.slug) {
      workspaceSlug({
        variables: { where: { slug: debouncedFormData.slug } },
        fetchPolicy: 'network-only',
      });
    }
  }, [debouncedFormData.slug, workspaceSlug]);

  return (
    <form onSubmit={handleSubmit} className="w-full max-w-md pb-4">
      <div className="flex flex-col gap-10 md:gap-14">
        <div className="space-y-2">
          <h3 className="text-heading-3-small bold text-[var(--text-default)] md:text-heading-3">
            Create workspace
          </h3>
        </div>

        <div className="flex flex-col gap-8 md:gap-10">
          <div className="flex flex-col gap-3 items-start w-full">
            <label
              className="text-body-small regular text-[var(--text-default)]"
              htmlFor="workspace-logo"
            >
              <HeaderText subTitle="Logo" />
            </label>
            <input
              id="workspace-logo"
              onChange={handleLogoUpload}
              className="sr-only"
              accept="image/*"
              type="file"
            />
            <div className="relative">
              <button
                type="button"
                onClick={handleFileButton}
                disabled={isUploadingImage}
                style={{
                  backgroundImage:
                    images[0] && !isUploadingImage
                      ? `url(${images[0].dataURL})`
                      : '',
                }}
                className={classNames(
                  'aspect-square relative bg-center rounded-lg bg-cover bg-no-repeat w-10 h-10',
                  hasUploadedImage ? 'group relative' : 'neutral'
                )}
              >
                {!isUploadingImage && !hasUploadedImage && (
                  <span className="absolute inset-0 grid place-content-center">
                    <Plus />
                  </span>
                )}

                {hasUploadedImage && (
                  <span className="absolute inset-0 grid place-content-center opacity-0 group-hover:opacity-100 group-hover:bg-black/75">
                    <Trash />
                  </span>
                )}
              </button>
              {isUploadingImage && (
                <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                  <Spinner />
                </div>
              )}
            </div>
            <p className="text-body-micro text-[var(--text-neutral)]">
              Upload a logo for your workspace, you can add and update it
              anytime.
            </p>
          </div>

          <HorizontalDivider />

          <div className="flex flex-col gap-6">
            <HeaderText subTitle="Workspace details" />

            <div className="flex flex-col gap-6">
              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-name"
                >
                  Workspace name
                </label>
                <input
                  id="workspace-name"
                  placeholder="Example Inc."
                  className="w-full input"
                  value={formData.name}
                  onChange={(e) => handleInputChange('name')(e)}
                />
                <ValidationErrorMessages name="name" errors={formErrors} />
              </div>

              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-url"
                >
                  Workspace URL
                </label>
                <UrlInput
                  id="workspace-url"
                  slugOnly
                  value={formData.slug}
                  onChange={(value) => handleInputChange('slug')(value)}
                  domain="overlap.work"
                  loading={workspaceSlugLoading}
                  success={Boolean(workspaceSlugError)}
                  error={Boolean(workspaceSlugData)}
                  generateFrom={
                    !isSlugManuallyEdited ? formData.name : undefined
                  }
                  onManualEdit={() => setIsSlugManuallyEdited(true)}
                />
                <ValidationErrorMessages name="slug" errors={formErrors} />
              </div>

              <div className="flex flex-col gap-2 items-start w-full">
                <label
                  className="text-body-small regular text-[var(--text-default)]"
                  htmlFor="workspace-website"
                >
                  Website
                </label>
                <UrlInput
                  id="workspace-website"
                  value={formData.website}
                  onChange={(value) => handleInputChange('website')(value)}
                  placeholder="www.example.com"
                />
                <ValidationErrorMessages name="website" errors={formErrors} />
              </div>
            </div>

            {errorMessage && (
              <p className="text-xs text-red-500 text-center">{errorMessage}</p>
            )}

            <Button
              type="submit"
              className="w-full"
              disabled={loading || isUploadingImage}
              loading={loading}
              value={'Continue'}
              size={'large'}
              btnType={'primary'}
            />
          </div>
        </div>
      </div>
    </form>
  );
}

export default CreateWorkspacePage;
