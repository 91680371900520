import { useMutation } from '@apollo/client';
import { INVITE_TEAM_TO_ONE_WORKSPACE } from 'src/lib/mutations';
import { WORKSPACE } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useInviteTeamToOneWorkspace() {
  const options = useApolloOptions();
  return useMutation(INVITE_TEAM_TO_ONE_WORKSPACE, {
    ...options,
    refetchQueries: [WORKSPACE],
  });
}
