import { Menu, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Check, ChevronDown } from 'src/assets/icons';
import Media from 'src/components/Media';
import { useUser } from 'src/context/userContext';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import { useLogout } from 'src/hooks/useLogout';
import { DropItem } from './ui/DropGroup';
import HorizontalDivider from './ui/HorizontalDivider';

interface WorkspaceMenuProps {
  mobile?: boolean;
}

const WorkspaceMenu: React.FC<WorkspaceMenuProps> = (props) => {
  const { mobile } = props;

  const logout = useLogout();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{
    workspaceSlug: string;
  }>();

  const { user } = useUser();
  const { currentWorkspace, userWorkspaces } = useWorkspaceContext();

  if (!user) {
    return null;
  }

  return (
    <Menu as="div" className={!mobile ? 'sticky top-0 z-10 w-full' : ''}>
      {({ open }) => (
        <Fragment>
          <Menu.Button
            className={`w-fit md:w-full z-50
            hover:bg-[var(--backgrounds-hover-clicked)]
            h-12 ${open ? '!bg-[var(--backgrounds-hover-clicked)]' : ''}
            duration-300 hover:!duration-0 rounded-lg cursor-pointer
            flex items-center space-x-2 p-3 px-2`}
          >
            <Media
              src={currentWorkspace?.workspace.logo?.url}
              width={32}
              height={32}
              alt={currentWorkspace?.workspace.name || 'Workspace logo'}
              fontSize={14}
            />
            {!mobile && (
              <>
                <div className="flex-1 truncate text-left">
                  <div className="text-heading-6 text-[var(--text-default)] medium capitalize">
                    {currentWorkspace?.workspace.name}
                  </div>
                  <div
                    className="text-body-micro regular text-[var(--text-neutral)] truncate"
                    title={user?.email}
                  >
                    {user?.email}
                  </div>
                </div>
                <div>
                  <ChevronDown />
                </div>
              </>
            )}
          </Menu.Button>
          <Transition
            as={Fragment}
            enter="transition ease-out duration-100"
            enterFrom="transform opacity-0 scale-95"
            enterTo="transform opacity-100 scale-100"
            leave="transition ease-in duration-75"
            leaveFrom="transform opacity-100 scale-100"
            leaveTo="transform opacity-0 scale-95"
          >
            <Menu.Items className="origin-top-left absolute max-w-[350px] w-full mt-2 p-1 flex flex-col items-start gap-2 rounded-lg border border-[var(--border-on-bg)] bg-[var(--backgrounds-dropdowns)] shadow-[0px_4px_8px_0px_rgba(89,_89,_89,_0.10)]">
              <div className="flex flex-col gap-2 items-start w-full">
                <div
                  className="px-3 text-body-micro bold text-[var(--text-neutral)] truncate"
                  title={user?.email}
                >
                  {user?.email}
                </div>

                <div className="flex flex-col gap-1 items-start w-full">
                  {userWorkspaces?.map((ws) => (
                    <Menu.Item key={ws.workspace.id}>
                      {({ active }) => (
                        <button
                          className={`${
                            active
                              ? 'bg-[var(--backgrounds-hover-clicked)]'
                              : ''
                          } rounded cursor-pointer flex items-center gap-2.5 px-2 h-10 w-full`}
                          onClick={() => {
                            navigate(`/workspace/${ws.workspace.slug}/project`);
                          }}
                        >
                          <Media
                            src={ws.workspace?.logo?.url}
                            width={32}
                            height={32}
                            alt={ws.workspace.name}
                            fontSize={14}
                          />
                          <div className="flex-1 truncate text-left capitalize">
                            <div className="text-body-small medium text-[var(--text-neutral)]">
                              {ws.workspace.name}
                            </div>
                          </div>
                          {ws.workspace.id ===
                            currentWorkspace?.workspace.id && <Check />}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </div>

              <HorizontalDivider />

              <div className="space-y-0.5 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <Link to={`/workspace/${workspaceSlug}/settings/general`}>
                      <DropItem item={{ name: 'Settings' }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <DropItem
                      item={{ name: 'Invite new members' }}
                      onItemClick={() =>
                        navigate(
                          `/invite-to-workspace?workspace=${workspaceSlug}`
                        )
                      }
                    />
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/create-workspace">
                      <DropItem item={{ name: 'Create new workspace' }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <Link to="/join-workspace">
                      <DropItem item={{ name: 'Add workspace' }} />
                    </Link>
                  )}
                </Menu.Item>
              </div>

              <HorizontalDivider />

              <div className="space-y-0.5 w-full">
                <Menu.Item>
                  {({ active }) => (
                    <Link to="">
                      <DropItem item={{ name: 'Help & support' }} />
                    </Link>
                  )}
                </Menu.Item>
                <Menu.Item>
                  {({ active }) => (
                    <div onClick={logout}>
                      <DropItem item={{ name: 'Log out' }} />
                    </div>
                  )}
                </Menu.Item>
              </div>
            </Menu.Items>
          </Transition>
        </Fragment>
      )}
    </Menu>
  );
};

export default WorkspaceMenu;
