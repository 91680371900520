import { gql, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ONE_TASK,
  DELETE_ONE_TASK,
  UPDATE_ONE_TASK,
} from 'src/lib/mutations';
import { TASKS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useTasks(args: object) {
  const options = useApolloOptions();

  return useQuery(TASKS, {
    ...options,
    fetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useCreateOneTask() {
  const options = useApolloOptions();
  return useMutation(CREATE_ONE_TASK, {
    ...options,
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          tasks(existingTasks = []) {
            const newTaskRef = cache.writeFragment({
              data: data?.createOneTask,
              fragment: gql`
                fragment NewTask on Task {
                  id
                  title
                  description
                  dueDate
                  status
                  workStream {
                    id
                    name
                  }
                  assignee {
                    id
                    detail {
                      fullName
                    }
                  }
                }
              `,
            });
            return [...existingTasks, newTaskRef];
          },
        },
      });
    },
  });
}

export function useUpdateOneTask() {
  const options = useApolloOptions();
  return useMutation(UPDATE_ONE_TASK, {
    ...options,
    update: (cache, { data: updateOneTask }) => {
      if (!updateOneTask?.updateOneTask) {
        console.error('No data returned from updateOneTask mutation');
        return;
      }

      const updatedTask = updateOneTask.updateOneTask;

      cache.modify({
        fields: {
          tasks(existingTasks = [], { readField, toReference }) {
            return existingTasks.map((taskRef: any) => {
              if (readField('id', taskRef) === updatedTask.id) {
                // update to normalized reference
                return toReference(
                  {
                    __typename: 'Task',
                    id: updatedTask.id,
                  },
                  true
                );
              }
              return taskRef;
            });
          },
        },
      });

      // individual update
      cache.writeFragment({
        id: cache.identify(updatedTask),
        fragment: gql`
          fragment UpdatedTask on Task {
            title
            description
            status
            dueDate
            assignee {
              id
            }
            workStream {
              id
            }
          }
        `,
        data: updatedTask,
      });
    },
  });
}

export function useDeleteOneTask() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_TASK, {
    ...options,
    update(cache, { data }) {
      cache.modify({
        fields: {
          tasks(existingTasks = [], { readField }) {
            return existingTasks.filter(
              (taskRef: { __ref: string }) =>
                data.deleteOneTask.id !== readField('id', taskRef)
            );
          },
        },
      });
    },
  });
}
