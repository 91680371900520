import { PropsWithChildren } from 'react';
import { Copy, ThreeDots, Trash } from 'src/assets/icons';
import IconButton from 'src/components/ui/Button/IconButton';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/Popover';
import { cn } from 'src/lib/utils';

interface ITaskActionsDialogProps extends PropsWithChildren<{}> {
  align?: 'start' | 'center' | 'end';
  className?: string;
  open?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  onCopyAction: () => void;
  onDeleteAction: () => void;
  canDelete?: boolean;
}

const TaskActionsDialog = (props: ITaskActionsDialogProps) => {
  const {
    className,
    align = 'end',
    open,
    onOpenChange,
    onCopyAction,
    onDeleteAction,
    canDelete,
  } = props;

  return (
    <Popover open={open} onOpenChange={onOpenChange}>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-center data-[state='open']:bg-[var(--button-neutral-hover-clicked)] rounded-md">
          <IconButton icon={<ThreeDots />} size={'micro'} type={'ghost'} />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'min-w-[200px] flex flex-col items-center gap-y-1 border-[var(--border-on-bg)] rounded-md p-1',
          className
        )}
        align={align}
        sideOffset={8}
      >
        <ActionItem title="Copy link" icon={<Copy />} onClick={onCopyAction} />
        {canDelete && (
          <ActionItem
            title="Delete"
            icon={<Trash />}
            onClick={onDeleteAction}
          />
        )}
      </PopoverContent>
    </Popover>
  );
};

function ActionItem({
  icon,
  title,
  onClick,
}: {
  icon?: JSX.Element;
  title: string;
  onClick: () => void;
}) {
  return (
    <button
      onClick={onClick}
      className="w-full flex items-center justify-start gap-x-[10px] px-2 min-h-[32px] hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer rounded text-[var(--text-neutral)]"
    >
      {icon}
      <span className="text-body-small medium capitalize">{title}</span>
    </button>
  );
}

export default TaskActionsDialog;
