import { gql, useMutation, useQuery } from '@apollo/client';
import {
  CREATE_ONE_POST,
  DELETE_ONE_POST,
  UPDATE_ONE_POST,
} from 'src/lib/mutations';
import { POSTS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function usePosts(args: object) {
  const options = useApolloOptions();

  return useQuery(POSTS, {
    ...options,
    fetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useCreateOnePost() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_POST, {
    ...options,
    update: (cache, { data }) => {
      cache.modify({
        fields: {
          posts(existingPosts = []) {
            const newPostRef = cache.writeFragment({
              data: data?.createOnePost,
              fragment: gql`
                fragment NewPost on Post {
                  id
                  title
                  comment
                  topic {
                    id
                    name
                  }
                  type
                  project {
                    id
                    name
                  }
                  attachments {
                    filename
                    mimetype
                    url
                  }
                  mentionedUsers {
                    id
                    detail {
                      fullName
                    }
                  }
                }
              `,
            });
            return [...existingPosts, newPostRef];
          },
        },
      });
    },
  });
}

export function useUpdateOnePost() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_POST, {
    ...options,
    update: (cache, { data: updateOnePost }) => {
      if (!updateOnePost?.updateOnePost) {
        console.error('No data returned from updateOnePost mutation');
        return;
      }

      const updatedPost = updateOnePost.updateOnePost;

      cache.modify({
        fields: {
          posts(existingPosts = [], { readField, toReference }) {
            return existingPosts.map((postRef: any) => {
              if (readField('id', postRef) === updatedPost.id) {
                return toReference(
                  {
                    __typename: 'Post',
                    id: updatedPost.id,
                  },
                  true
                );
              }
              return postRef;
            });
          },
        },
      });

      // individual update
      cache.writeFragment({
        id: cache.identify(updatedPost),
        fragment: gql`
          fragment UpdatedPost on Post {
            id
            title
            comment
            topic {
              id
              name
            }
            type
            project {
              id
              name
            }
            attachments {
              filename
              mimetype
              url
            }
            mentionedUsers {
              id
              detail {
                fullName
              }
            }
          }
        `,
        data: updatedPost,
      });
    },
  });
}

export function useDeleteOnePost() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_POST, {
    ...options,
    update(cache, { data }) {
      cache.modify({
        fields: {
          posts(existingPosts = [], { readField }) {
            return existingPosts.filter(
              (postRef: { __ref: string }) =>
                data.deleteOnePost.id !== readField('id', postRef)
            );
          },
        },
      });
    },
  });
}
