import { useMutation, useQuery } from '@apollo/client';
import { READ_ALL_INBOX, READ_ONE_INBOX } from 'src/lib/mutations';
import { INBOX, INBOX_COUNT } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useInbox(args?: object) {
  const options = useApolloOptions();

  return useQuery(INBOX, {
    ...options,
    fetchPolicy: 'network-only' as const,
    ...args,
  });
}

export function useInboxCount(args?: object) {
  const options = useApolloOptions();

  return useQuery(INBOX_COUNT, {
    ...options,
    fetchPolicy: 'network-only' as const,
    ...args,
  });
}

export function useReadAllInbox() {
  const options = useApolloOptions();
  return useMutation(READ_ALL_INBOX, {
    ...options,
    refetchQueries: [INBOX, INBOX_COUNT],
  });
}

export function useReadOneInbox() {
  const options = useApolloOptions();
  return useMutation(READ_ONE_INBOX, {
    ...options,
    refetchQueries: [INBOX_COUNT, INBOX],
  });
}
