import parse from 'html-react-parser';
import toast from 'react-hot-toast';
import {
  ArrowBack,
  Circle,
  ClockOutline,
  Files,
  IconInformation,
  Lock,
  Status,
  Unlock,
  UserCircle,
  Users,
} from 'src/assets/icons';
import useMobile from 'src/hooks/useMobile';
import { getProjectStatusColor, transformToTitleCase } from 'src/lib/helpers';
import { IProject } from 'src/types';
import LinkLogo from '../LinkLogo';
import ToastMessage from '../ToastMessage';
import SectionWrap from '../common/SectionWrap';
import UserDetailItem from '../common/project/UserDetailItem';
import AssignItem from '../ui/AssignItem';
import Avatar from '../ui/Avatar/Avatar';
import IconButton from '../ui/Button/IconButton';
import Links from '../ui/Links';
import Timestamp from '../ui/TimeStamp';
import StatusTag from '../ui/tags/StatusTags';
import ProjectDetailPopover from './ProjectDetailPopover';

interface ProjectSidePanelProps {
  userId: string | undefined;
  workspaceSlug: string;
  projectId: string;
  project: IProject;
  state: any;
  setState: any;
}

const ProjectSidePanel = (props: ProjectSidePanelProps) => {
  const { project, state, setState } = props;

  const copyLink = (url: string) => {
    navigator.clipboard
      .writeText(url)
      .then(() => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Link URL is copied to clipboard.'} />
        ));
      })
      .catch(() => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard'} />
        ));
      });
  };

  const isMobile = useMobile();
  return (
    <aside
      className={`absolute z-10 md:z-0 md:sticky right-0 h-screen md:h-[calc(100vh-16px)] md:!translate-x-0 top-0 md:max-w-[406px] w-full transition-all lg:block bg-[rgba(7,7,7,0.5)] md:bg-transparent ${
        isMobile && state.openPanel ? 'slide-in-panel' : 'close-panel'
      }`}
    >
      <SectionWrap className="sticky top-0 h-full p-5 overflow-y-auto max-w-[92%] md:max-w-full md:w-full ml-auto">
        <div className="mb-8 md:hidden">
          <IconButton
            icon={<ArrowBack />}
            size={'small'}
            type={'ghost'}
            onClick={() => setState({ ...state, openPanel: false })}
          />
        </div>

        <table className="-my-3 w-full font-medium text-sm">
          <tbody>
            <tr>
              <td className="w-[120px] md:w-[133px] py-3 flex items-start">
                <AssignItem
                  icon={<IconInformation className="!w-3.5 !h-3.5" />}
                  text="About"
                />
              </td>
              <td className="py-3">
                <div className="ck ck-content text-[var(--text-neutral)] text-body-micro regular !px-1.5">
                  {parse(project.description)}
                </div>
              </td>
            </tr>

            {/* Project lead */}
            <tr>
              <td className="w-[120px] md:w-[133px] py-3">
                <AssignItem
                  icon={<UserCircle className="!w-3.5 !h-3.5" />}
                  text="Project Lead"
                />
              </td>
              <td className="py-3">
                <ProjectDetailPopover
                  align="end"
                  triggerChildren={<LeadItem project={project} />}
                  contentClassName="min-w-[250px]"
                >
                  <UserDetailItem
                    userName={
                      project.owner?.detail?.fullName ?? project.owner?.email
                    }
                    userPosition={project.owner?.detail?.position}
                    avatarUrl={project.owner?.detail?.avatar?.url}
                  />
                </ProjectDetailPopover>
              </td>
            </tr>

            <tr>
              <td className="w-[120px] md:w-[133px] py-3">
                <AssignItem
                  icon={<ClockOutline className="!w-3.5 !h-3.5" />}
                  text="Created"
                />
              </td>
              <td className="py-3">
                <Timestamp
                  createdAt={project.createdAt as string}
                  className="px-[6px] text-body-micro medium text-[var(--text-on-bg)]"
                />
              </td>
            </tr>

            {/* Collaborators */}
            <tr>
              <td className="w-[133px] py-3">
                <AssignItem
                  icon={<Users className="!w-3.5 !h-3.5" />}
                  text="Collaborators"
                />
              </td>
              <td className="py-3">
                {project.collaborators?.length < 1 ? (
                  <div className="w-fit !px-1.5">
                    <StatusTag
                      value="No collaborators yet"
                      color="default"
                      size="medium"
                    />
                  </div>
                ) : (
                  <ProjectDetailPopover
                    align="end"
                    triggerChildren={<CollaboratorsList project={project} />}
                    contentClassName="min-w-[250px]"
                  >
                    {project.collaborators?.map((collaborator) => (
                      <UserDetailItem
                        key={collaborator.id}
                        userName={
                          collaborator.detail?.fullName ?? collaborator.email
                        }
                        userPosition={collaborator.detail?.position}
                        avatarUrl={collaborator.detail?.avatar?.url}
                      />
                    ))}
                  </ProjectDetailPopover>
                )}
              </td>
            </tr>

            {/* Status */}
            <tr>
              <td className="py-3">
                <AssignItem
                  icon={<Status className="!w-3.5 !h-3.5" />}
                  text="Status"
                />
              </td>
              <td className="py-3">
                <div className="w-fit !px-1.5">
                  <StatusTag
                    color={getProjectStatusColor[project.status]}
                    value={transformToTitleCase(project.status)}
                    size="medium"
                    leftIcon={<Circle />}
                  />
                </div>
              </td>
            </tr>

            {/* Access */}
            <tr>
              <td className="py-3">
                <AssignItem
                  icon={<Lock className="!w-3.5 !h-3.5" />}
                  text="Access"
                />
              </td>
              <td className="py-3">
                <div className="w-fit !px-1.5">
                  <StatusTag
                    value={project.isPublic ? 'Public' : 'Private'}
                    color={'default'}
                    size={'medium'}
                    leftIcon={
                      project.isPublic ? (
                        <Unlock className="w-3 h-3" />
                      ) : (
                        <Lock className="w-3 h-3" />
                      )
                    }
                  />
                </div>
              </td>
            </tr>

            {/* Resources */}
            <tr className="">
              <td className="py-3 flex">
                <AssignItem
                  icon={<Files className="!w-3.5 !h-3.5" />}
                  text="Resources"
                />
              </td>
              <td className="py-3">
                {project.links?.length < 1 ? (
                  <div className="px-1.5">
                    <StatusTag
                      value="No resources yet"
                      color="default"
                      size="medium"
                    />
                  </div>
                ) : (
                  <ul className="space-y-3">
                    {project.links?.map((link) => {
                      return (
                        <li
                          key={link.id}
                          className="flex items-center space-x-2"
                        >
                          <Links
                            href={link.url}
                            icon={<LinkLogo link={link} />}
                            value={link.label}
                            onClick={() => {
                              copyLink(link.url);
                            }}
                          />
                        </li>
                      );
                    })}
                  </ul>
                )}
              </td>
            </tr>
          </tbody>
        </table>
      </SectionWrap>
    </aside>
  );
};

function LeadItem({ project }: { project: IProject }) {
  return (
    <div className="cursor-pointer flex gap-1.5 items-center rounded-lg w-fit hover:bg-[var(--backgrounds-default)] py-1 !px-1.5">
      <Avatar
        name={project.owner?.detail?.fullName ?? project.owner?.email}
        src={project.owner?.detail?.avatar?.url}
        alt={project.owner?.detail?.fullName}
        size={16}
      />
      <p className="text-body-micro medium text-[var(--text-default)] max-w-[150px] truncate">
        {project.owner?.detail?.fullName ?? project.owner?.email}
      </p>
    </div>
  );
}

function CollaboratorsList({ project }: { project: IProject }) {
  return (
    <ul className="cursor-pointer flex items-center -space-x-2 rounded-lg w-fit hover:bg-[var(--backgrounds-default)] !px-1.5 py-1">
      {project.collaborators?.slice(0, 3)?.map((collaborator, index) => (
        <li
          key={collaborator.id}
          className={`relative rounded-full ${
            index > 0 ? 'border border-[var(--backgrounds-default)]' : ''
          }`}
          style={{ zIndex: index }}
        >
          <Avatar
            name={collaborator.detail?.fullName ?? collaborator.email}
            src={collaborator.detail?.avatar?.url}
            alt={collaborator.detail?.fullName}
            size={16}
          />
        </li>
      ))}
      <li>
        <div className="text-body-micro medium text-[var(--text-on-bg)] bg-[var(--backgrounds-default)] ml-3 p-1 rounded-md h-5 w-5 flex flex-col justify-center items-center">
          {project.collaborators?.length}
        </div>
      </li>
    </ul>
  );
}

export default ProjectSidePanel;
