import { renderToStaticMarkup } from 'react-dom/server';
import Avatar from 'src/components/ui/Avatar/Avatar';
import { IMedia } from 'src/lib/types';

export interface IMentionFeed {
  id: string;
  userId: string;
  avatar?: IMedia;
  name: string;
  position: string;
}

const CustomMentionItem = (item: IMentionFeed) => {
  const itemElement = document.createElement('span');

  itemElement.innerHTML = renderToStaticMarkup(
    <div className="flex space-x-2 items-center">
      <Avatar
        name={item.name}
        alt={item.name[0]}
        src={item.avatar?.url}
        size={28}
      />
      <div>
        <div className="!font-medium !text-base !text-[var(--text-on-bg)]">
          {item.name}
        </div>
        <div className="!text-sm !text-[var(--text-on-bg)]">
          {item.position}
        </div>
      </div>
    </div>
  );

  return itemElement;
};

export default CustomMentionItem;
