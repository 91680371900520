import { CKEditor } from '@ckeditor/ckeditor5-react';
import BalloonEditor from 'ckeditor5-custom-build';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ArrowUp, IconChatReply } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import StatusTag from 'src/components/ui/tags/StatusTags';
import { useComment } from 'src/hooks/project/post/useCommentAction';
import { getUniqueCommentAuthors } from 'src/lib/helpers';
import { IComment, IUser } from 'src/types';
import PostCommentBody from './PostCommentBody';
import ReplyCommentBody from './ReplyCommentBody';

interface IPostCommentItemProps {
  myData: IUser | null;
  collaborators: IUser[];
  comment: IComment;
  postId: string;
}

function PostCommentItem({
  myData,
  collaborators,
  comment,
  postId,
}: IPostCommentItemProps) {
  const commentAuthors = getUniqueCommentAuthors(comment.responses);
  const [showReplyInput, setShowReplyInput] = useState<string | null>(null);

  const {
    control,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment: loading,
    editorConfig,
  } = useComment({
    postId: postId as string,
    onReplyCreated: () => {
      setShowReplyInput(null);
    },
    collaborators,
    editorPlaceholder: 'Add a reply',
  });

  return (
    <Fragment>
      <div className="duration-1000 flex flex-col gap-4">
        <PostCommentBody comment={comment} collaborators={collaborators} />

        <div className="flex items-center justify-start gap-2 relative group">
          {commentAuthors.length > 0 && (
            <div className="avatars-group flex items-center -space-x-1">
              {commentAuthors.slice(0, 3).map((user, key) => (
                <div key={key}>
                  <Avatar
                    name={user.detail?.fullName ?? user.email}
                    size={20}
                    alt={user.detail?.fullName}
                    src={user.detail.avatar?.url}
                    className="border border-[var(--backgrounds-on-canvas)]"
                  />
                </div>
              ))}
            </div>
          )}

          <button className="w-[fit-content]">
            <StatusTag
              color={'default'}
              size={'medium'}
              value={
                comment.responses.length > 0 ? comment.responses.length : ''
              }
              leftIcon={<IconChatReply />}
            />
          </button>
          <div className="h-1 w-1 rounded-full bg-[rgba(141,141,141,1)]" />
          <Button
            value={showReplyInput === comment.id ? 'Cancel' : 'Reply'}
            size={'small'}
            btnType={'ghost'}
            onClick={() => {
              setShowReplyInput((state) => {
                if (state === comment.id) {
                  return null;
                }
                return comment.id;
              });
            }}
            className={
              '!h-5 !p-1.5 w-[fit-content] !bg-[var(--backgrounds-default)] hover:bg-[var(--button-neutral-hover-clicked)]'
            }
          />
        </div>

        {comment.responses.length > 0 && (
          <div className="comments p-4 md:p-6 flex flex-col gap-3 rounded-lg bg-[var(--backgrounds-default)]">
            {comment.responses.map((response, index) => (
              <div key={response.id}>
                <ReplyCommentBody
                  comment={response}
                  collaborators={collaborators}
                />
                {index !== comment.responses.length - 1 && (
                  <div className="pt-3">
                    <HorizontalDivider />
                  </div>
                )}
              </div>
            ))}
          </div>
        )}
      </div>

      <AnimatePresence>
        {showReplyInput === comment.id && (
          <motion.div
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            exit={{ opacity: 0, y: 10 }}
            className="flex items-center w-full relative"
          >
            <div className="absolute left-0 inset-y-0 px-2 top-2.5 grid place-content-start">
              <Avatar
                name={myData?.detail?.fullName ?? (myData?.email as string)}
                src={myData?.detail.avatar?.url}
                alt={`${myData?.detail?.fullName} pfp`}
                size={24}
              />
            </div>
            <div className="textarea-editor placeholder:!text-base placeholder:!font-medium w-full !bg-[var(--backgrounds-default)] !pl-11 !pr-11 placeholder:!text-[var(--text-disabled)] shadow-none !max-h-[182px]">
              <Controller
                name="reply"
                control={control}
                render={({ field }) => (
                  <CKEditor
                    editor={BalloonEditor}
                    data={field.value}
                    config={editorConfig as any}
                    onChange={(event, editor) => {
                      field.onChange(editor.getData());
                      handleEditorChange(editor);
                    }}
                  />
                )}
              />
            </div>
            <div className="absolute right-2 inset-y-0 bottom-1.5 grid place-content-end">
              <IconButton
                onClick={handleSubmit((data) => {
                  handleCreateComment(data, true, comment.id);
                })}
                type="primary"
                size="standard"
                rounded="full"
                disabled={loading}
                loading={loading}
                icon={<ArrowUp />}
              />
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
}

export default PostCommentItem;
