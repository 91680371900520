import { Fragment } from 'react';
import { MegaPhone, UserCircle, WorkStream } from 'src/assets/icons';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import { IUser } from 'src/types';
import ProjectFilterDropdown from '../filter/ProjectFilterDropdown';

export default function SkeletalPostSection({ myData }: { myData: IUser }) {
  return (
    <Fragment>
      <div className="w-full flex justify-between mt-6 pb-2 items-start px-6">
        <div className="w-max flex items-start gap-x-2">
          <ProjectFilterDropdown
            value={[]}
            onChange={(dummy: string[]) => {}}
            icon={<WorkStream className="w-3 h-3" />}
            label="Workstreams"
            options={[]}
          />
          <ProjectFilterDropdown
            value={[]}
            onChange={(dummy: string[]) => {}}
            icon={<MegaPhone className="w-3 h-3" />}
            label="Type"
            options={[]}
          />
          <ProjectFilterDropdown
            value={[]}
            onChange={(dummy: string[]) => {}}
            icon={<UserCircle className="w-3 h-3" />}
            label="Members"
            options={[]}
          />
        </div>
        <div
          className="min-w-[57px] p-[1.5px] min-h-[27px] invisible"
          aria-label="invisible block"
        />
      </div>

      <div className="h-full overflow-y-hidden relative w-full">
        <ul className="flex flex-col flex-grow">
          {Array.from({ length: 4 }).map((_, index) => (
            <li key={index} className="w-full">
              <div className="flex flex-col items-start space-y-2 py-4 px-6 gap-y-6 bg-red">
                <div className="w-full py-2 flex items-center justify-between">
                  <div className="flex items-center justify-start gap-x-2">
                    <div className="h-6 w-6 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                    <div className="w-[90px] h-6 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                  </div>
                  <div className="w-[154px] h-6 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                </div>
                <div className="w-full flex flex-col items-start gap-1">
                  <div className="w-[176px] h-8 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                  <div className="w-full h-8 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                  <div className="w-full h-8 rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
                </div>
                <div className="w-full h-6 w-[90px] rounded-lg bg-[var(--backgrounds-default)] animate-pulse" />
              </div>
              <HorizontalDivider />
            </li>
          ))}
        </ul>
      </div>
    </Fragment>
  );
}
