import {
  IPricingPlan,
  IProject,
  IUser,
  IUserWorkspace,
  IWorkspace,
  IWorkspacePlan,
} from 'src/types';

export const findWorkspaceBySlug = (
  userWorkspaces: IUserWorkspace[] | null,
  slug: string
): IUserWorkspace | undefined => {
  return userWorkspaces?.find(
    (userWorkspace: IUserWorkspace) => userWorkspace.workspace.slug === slug
  );
};

export const findProjectById = (
  workspace: IWorkspace | null,
  projectId: string
): IProject | undefined => {
  return workspace?.projects?.find(
    (project: IProject) => project.id === projectId
  );
};

export const checkShouldSkipUserAction = (
  user: IUser,
  isOwner: boolean
): boolean => {
  if (!user) return false;
  const { actionStatus } = user;

  return (
    !isOwner &&
    !actionStatus.hasVerifiedDomain &&
    actionStatus.hasCreatedProject &&
    actionStatus.hasInvitedTeamMembers &&
    actionStatus.hasCreatedPost
  );
};

export const findPlanDetailsByActivePlan = (
  workspacePricingPlans: IPricingPlan[],
  activePlanType: string | undefined
): IPricingPlan | undefined => {
  return workspacePricingPlans.find(
    (plan: IPricingPlan) => plan.type === activePlanType
  );
};

export const findCheckoutDetailsByTypeAndCycle = (
  workspacePlans: IWorkspacePlan[],
  type: string,
  cycle: string
): IWorkspacePlan | undefined => {
  return workspacePlans.find(
    (plan: IWorkspacePlan) => plan.name === type && plan.billingCycle === cycle
  );
};
