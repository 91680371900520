import React, { Fragment, useState } from 'react';
import '../ui.css';

export interface Tab {
  id: string;
  value: string;
}

interface TabsProps {
  tabs: Tab[];
  initialSelectedTab?: string;
  onTabChange: (selectedTab: string) => void;
}

const Tabs: React.FC<TabsProps> = ({
  tabs,
  initialSelectedTab,
  onTabChange,
}) => {
  const [selectedTab, setSelectedTab] = useState<string>(
    initialSelectedTab || tabs[0].id
  );

  function handleChange(tab: Tab) {
    const newSelectedTab = tab.id;
    setSelectedTab(newSelectedTab);
    onTabChange(newSelectedTab);
  }

  return (
    <div className="flex items-center gap-2 p-0.5 bg-[var(--backgrounds-default)] shadow-[0px_1px_2px_0px_rgba(7,7,7,0.08)] rounded-lg w-full">
      {tabs.map((tab) => (
        <Fragment key={tab.id}>
          <TabItem
            value={tab.value}
            active={selectedTab === tab.id}
            onClick={() => handleChange(tab)}
          />
        </Fragment>
      ))}
    </div>
  );
};

function TabItem({
  value,
  active,
  onClick,
}: {
  value: string | number;
  active: boolean;
  onClick: () => void;
}) {
  return (
    <div
      className={`capitalize flex items-center justify-center rounded-md h-7 px-4 py-2.5 text-body-small medium text-[var(--text-neutral)] hover:text-[var(--text-default)] cursor-pointer w-full transition duration-100 ease-in-out ${
        active
          ? 'active_tab bg-[var(--backgrounds-on-bg)] shadow-[0px_1px_2px_0px_rgba(7,7,7,0.08)]'
          : ''
      }`}
      onClick={onClick}
    >
      <span>{value}</span>
    </div>
  );
}

export default Tabs;
