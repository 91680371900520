import { useCallback, useState } from 'react';
import { toast } from 'react-hot-toast';
import { useGenerateUploadSignature } from 'src/api/misc/misc.service';
import ToastMessage from 'src/components/ToastMessage';
import { MAX_FILE_UPLOAD_SIZE } from 'src/lib/consts';

export interface ImageUploadState {
  file: File;
  dataURL: string;
  uploading: boolean;
  error: string | null;
}

interface UseImageUploadOptions {
  maxFiles?: number;
  maxFileSize?: number;
  allowedFileTypes?: string[];
}

export const useImageUploader = (options: UseImageUploadOptions = {}) => {
  const {
    maxFiles = 4,
    maxFileSize = MAX_FILE_UPLOAD_SIZE.bytes,
    allowedFileTypes = ['image/*'],
  } = options;

  const [images, setImages] = useState<ImageUploadState[]>([]);
  const [generateUploadSignature] = useGenerateUploadSignature();

  const handleFileChange = useCallback(
    async (files: FileList | null) => {
      if (!files) return;

      const fileArray = Array.from(files).slice(0, maxFiles - images.length);
      const oversizedFiles: string[] = [];
      const validFiles: File[] = [];

      for (const file of fileArray) {
        if (file.size > maxFileSize) {
          oversizedFiles.push(file.name);
        } else if (allowedFileTypes.some((type) => file.type.match(type))) {
          validFiles.push(file);
        }
      }

      if (oversizedFiles.length > 0) {
        toast((t) => (
          <ToastMessage
            id={t.id}
            //Images [${oversizedFiles.join(', ')}] will be skipped.
            title={`The size limit for image uploads is ${MAX_FILE_UPLOAD_SIZE.humanReadable}. Want to try another image?`}
          />
        ));
      }

      if (validFiles.length > 0) {
        const newImages = await Promise.all(
          validFiles.map(async (file) => {
            const reader = new FileReader();
            const dataURL = await new Promise<string>((resolve) => {
              reader.onload = (e) => resolve(e.target?.result as string);
              reader.readAsDataURL(file);
            });

            return {
              file,
              dataURL,
              uploading: true,
              error: null,
            };
          })
        );

        setImages((prevImages) => [...prevImages, ...newImages]);

        try {
          const uploadedImages = await uploadFiles(validFiles);
          setImages((prevImages) =>
            prevImages.map((img) => {
              const uploadedImg = uploadedImages.find(
                (u) => u?.file.name === img.file.name
              );
              if (uploadedImg) {
                return { ...img, ...uploadedImg, uploading: false };
              }
              return img;
            })
          );
        } catch (error) {
          console.error('Error uploading files:', error);
          setImages((prevImages) =>
            prevImages.map((img) => ({
              ...img,
              uploading: false,
              error: 'Upload failed',
            }))
          );
          toast((t) => (
            <ToastMessage
              id={t.id}
              title="Error uploading files. Please try again"
            />
          ));
        }
      }
    },
    [images, maxFiles, maxFileSize, allowedFileTypes]
  );

  const uploadFiles = async (
    files: File[]
  ): Promise<(ImageUploadState | null)[]> => {
    const cloudName = process.env.REACT_APP_CLOUDINARY_CLOUD_NAME;
    const apiKey = process.env.REACT_APP_CLOUDINARY_API_KEY;

    if (!cloudName || !apiKey) {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title="Oohps! did you forget your Cloudinary keys?"
        />
      ));
      throw new Error('Cloudinary environment variables not set');
    }

    const uploadPromises = files.map(async (file) => {
      try {
        const { data } = await generateUploadSignature();

        if (!data || !data.generateUploadSignature) {
          throw new Error('Failed to generate upload signature');
        }

        const { signature, timestamp } = data.generateUploadSignature;

        const formData = new FormData();
        formData.append('file', file);
        formData.append('api_key', apiKey as string);
        formData.append('timestamp', timestamp);
        formData.append('signature', signature);

        const response = await fetch(
          `https://api.cloudinary.com/v1_1/${cloudName}/image/upload`,
          {
            method: 'POST',
            body: formData,
          }
        );

        if (!response.ok) {
          throw new Error('Failed to upload image to Cloudinary');
        }

        const responseData = await response.json();
        return {
          file,
          dataURL: responseData.url,
          uploading: false,
          error: null,
        };
      } catch (error) {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="Error uploading file. Please try again"
          />
        ));
        console.error('Error uploading file:', error);
        return null;
      }
    });

    return Promise.all(uploadPromises);
  };

  const removeImage = useCallback((index: number) => {
    setImages((prevImages) => prevImages.filter((_, i) => i !== index));
  }, []);

  return {
    images,
    setImages,
    handleFileChange,
    removeImage,
  };
};
