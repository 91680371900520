import { motion } from 'framer-motion';
import { useCallback, useEffect, useState } from 'react';
import { ILogoProps } from 'src/types/common';

const LogoAnimated = (props: ILogoProps) => {
  const pathVariants = {
    active: {
      fill: '#8178EF',
      transition: { duration: 0.2 },
    },
    inactive: {
      fill: '#E1DFFB',
      transition: { duration: 0.2 },
    },
  };

  const paths = [
    'M29.6429 20.5923C32.2966 18.9785 32.2796 13.7146 29.6049 8.83502C26.9303 3.95544 22.6107 1.30799 19.957 2.92176C17.3033 4.53553 17.3203 9.79943 19.995 14.679C22.6697 19.5586 26.9892 22.206 29.6429 20.5923Z',
    'M13.9365 31.1832C18.5691 28.3659 21.0826 23.8162 19.5505 21.0211C18.0184 18.2259 13.0209 18.2438 8.38819 21.0611C3.75552 23.8783 1.24202 28.428 2.77414 31.2231C4.30625 34.0183 9.3038 34.0004 13.9365 31.1832Z',
    'M29.6411 49.3179C32.2948 47.7041 32.2778 42.4402 29.6031 37.5606C26.9284 32.681 22.6089 30.0336 19.9552 31.6473C17.3015 33.2611 17.3185 38.525 19.9932 43.4046C22.6679 48.2842 26.9874 50.9316 29.6411 49.3179Z',
    'M41.2118 31.1831C45.8445 28.3659 48.358 23.8162 46.8258 21.0211C45.2937 18.2259 40.2962 18.2438 35.6635 21.0611C31.0309 23.8783 28.5174 28.428 30.0495 31.2231C31.5816 34.0183 36.5791 34.0004 41.2118 31.1831Z',
  ];

  const [activeIndex, setActiveIndex] = useState(paths.length - 1);

  const nextIndex = useCallback(() => {
    setActiveIndex(
      (prevIndex) => (prevIndex - 1 + paths.length) % paths.length
    );
  }, [paths.length]);

  useEffect(() => {
    const interval = setInterval(nextIndex, 800);
    return () => clearInterval(interval);
  }, [nextIndex]);

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="50"
      height="53"
      viewBox="0 0 50 53"
      fill="none"
      className={props.className}
    >
      {paths.map((path, index) => (
        <motion.path
          layoutId={`logo-path-${index}`}
          key={index}
          d={path}
          variants={pathVariants}
          initial="inactive"
          animate={activeIndex === index ? 'active' : 'inactive'}
        />
      ))}
    </svg>
  );
};

export default LogoAnimated;
