import * as React from 'react';
import { DayPicker } from 'react-day-picker';
import { IconChevronLeft, IconChevronRight } from 'src/assets/icons';
import { cn } from 'src/lib/utils';

export type CalendarProps = React.ComponentProps<typeof DayPicker>;

function DatePicker({
  className,
  classNames,
  showOutsideDays = true,
  ...props
}: CalendarProps) {
  return (
    <DayPicker
      showOutsideDays={showOutsideDays}
      className={cn('p-3 w-full', className)}
      classNames={{
        months:
          'flex flex-col sm:flex-row space-y-4 sm:space-x-4 sm:space-y-0 w-full',
        month: 'space-y-4 w-full',
        caption: 'flex justify-center pt-1 relative items-center w-full',
        caption_label: 'text-sm font-medium text-[var(--text-default)]',
        nav: 'space-x-1 flex items-center justify-center',
        nav_button: 'h-6 w-6 bg-transparent p-0 hover:opacity-80 !rounded',
        nav_button_previous: 'absolute left-0',
        nav_button_next: 'absolute right-0',
        table: 'w-full border-collapse space-y-1',
        head_row: 'flex space-x-[25px]',
        head_cell:
          '!rounded !w-6 !h-6 font-normal text-body-small !font-[500] !p-0 !text-[var(--text-disabled)]',
        row: 'flex w-full mt-2 space-x-[25px]',
        cell: 'h-6 w-6 text-center !rounded text-sm p-0 relative [&:has([aria-selected].day-range-end)]:!rounded-r [&:has([aria-selected].day-outside)]:bg-accent/50 [&:has([aria-selected])]:bg-accent first:[&:has([aria-selected])]:!rounded-l last:[&:has([aria-selected])]:!rounded-r focus-within:relative focus-within:z-20',
        day: '!h-6 !w-6 !p-0 font-normal aria-selected:opacity-100 !rounded text-body-small !font-[500] !text-[var(--text-neutral)] hover:bg-[var(--backgrounds-hover-clicked)] hover:!text-[var(--text-default)]',
        day_range_end: 'day-range-end',
        day_selected:
          '!text-[var(--text-default)] !bg-[var(--backgrounds-hover-clicked)]',
        day_today: '!bg-[var(--button-primary)] !text-[var(--text-on-primary)]',
        day_outside:
          'day-outside !text-[var(--text-disabled)] aria-selected:bg-accent/50 aria-selected:text-muted-foreground aria-selected:opacity-30',
        day_disabled: '!text-[var(--text-disabled)] hover:opacity-50',
        day_range_middle:
          'aria-selected:bg-accent aria-selected:text-accent-foreground',
        day_hidden: 'invisible',
        ...classNames,
      }}
      components={{
        IconLeft: ({ ...props }) => <IconChevronLeft className="h-3 w-3" />,
        IconRight: ({ ...props }) => <IconChevronRight className="h-3 w-3" />,
      }}
      {...props}
    />
  );
}
DatePicker.displayName = 'DatePicker';

export { DatePicker };
