import { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { Plus } from 'src/assets/icons';
import SectionWrap from 'src/components/common/SectionWrap';
import ProjectModal from 'src/components/projects/ProjectModal';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import SideNavMobile from 'src/components/sideNav/SideNavMobile';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import MobileWorkSpaceMenu from 'src/components/ui/MobileWorkSpaceMenu';
import { useTheme } from 'src/context/themeContext';
import { useUser } from 'src/context/userContext';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import useMobile from 'src/hooks/useMobile';
import { colorStyles } from 'src/lib/colorPreset';
import { getColorTag, getCurrentTheme } from 'src/lib/helpers';
import { IProject } from 'src/types';

function WorkspacePage() {
  const isMobile = useMobile();
  const navigate = useNavigate();
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();
  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  const { theme } = useTheme();
  const currentTheme = getCurrentTheme(theme);
  const { userSetupComplete } = useUser();
  const { workspaceProjects } = useWorkspaceContext();

  const [createProject, setCreateProject] = useState(false);
  const [illustrationSrc, setIllustrationSrc] = useState<string>(
    currentTheme === 'dark'
      ? '/images/Illustration2.svg'
      : '/images/Illustration.svg'
  );

  useEffect(() => {
    setIllustrationSrc(
      currentTheme === 'dark'
        ? '/images/Illustration2.svg'
        : '/images/Illustration.svg'
    );
  }, [currentTheme]);

  useEffect(() => {
    if (!workspaceSlug) return;

    const baseUrl = `/workspace/${workspaceSlug}/project`;

    if (!userSetupComplete) {
      navigate(`/workspace/${workspaceSlug}/get-started`, {
        replace: true,
      });
      return;
    }

    if (workspaceProjects && workspaceProjects?.length > 0 && !isMobile) {
      navigate(`${baseUrl}/${workspaceProjects[0].id}`);
    } else {
      navigate(baseUrl, {
        replace: true,
      });
    }
  }, [workspaceSlug, workspaceProjects, isMobile, navigate, userSetupComplete]);

  return (
    <Fragment>
      <SectionWrap className="flex items-center justify-center">
        <div className="page-title z-10 flex items-center justify-between md:hidden h-14">
          <MobileWorkSpaceMenu />
          <p className="text-body-small bold text-[var(--text-neutral)]">
            Projects
          </p>
          <IconButton
            icon={<Plus />}
            size={'small'}
            type={'ghost'}
            onClick={() => setCreateProject(true)}
          />
        </div>
        {Boolean(isMobile && workspaceProjects?.length) ? (
          <div className="h-full flex flex-col gap-2 w-full pt-24 px-4">
            {workspaceProjects?.map((project: IProject) => (
              <Link key={project.id} to={`${WORKSPACE_URL_BASE}/${project.id}`}>
                <SideNavMenuBtn
                  avatarColor={colorStyles[getColorTag(project.name)]}
                  sideAvatar={project.name}
                  type="home"
                  label={`${project.name}`}
                  link={`${WORKSPACE_URL_BASE}/${project.id}`}
                  showBadge={false}
                />
              </Link>
            ))}
          </div>
        ) : (
          <div className="flex flex-col items-start gap-6 max-w-[410px] px-4">
            <img
              src={illustrationSrc}
              className="w-full max-w-[73px] md:max-w-[145px] h-auto"
              alt="overlap Projects page"
            />
            <div className="w-full">
              <h2 className="text-[var(--text-default)] text-heading-6 medium md:text-heading-5">
                Projects
              </h2>
              <p className="text-[var(--text-neutral)] mb-6 text-body-mini regular md:text-body-small">
                Projects are channels focused on ongoing work within your team.
                You can add links to resources like docs and design files, and
                invite folks on your team to collaborate and share posts. Posts
                can be organised into work-streams and types to make
                conversations easier to follow.
              </p>
              <Button
                onClick={() => setCreateProject(true)}
                value={'Create a project'}
                size={'medium'}
                btnType={'primary'}
                icon="left"
                className="w-auto"
              >
                <Plus />
              </Button>
            </div>
          </div>
        )}
      </SectionWrap>

      <ProjectModal
        show={createProject}
        handleClose={() => setCreateProject(false)}
      />
      <SideNavMobile />
    </Fragment>
  );
}

export default WorkspacePage;
