import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocalStorage } from '@uidotdev/usehooks';
import BalloonEditor from 'ckeditor5-custom-build';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { usePosts } from 'src/api/post/post.service';
import { useProject } from 'src/api/project/project.service';
import { ArrowBack, ArrowUp, MegaPhone, WorkStream } from 'src/assets/icons';
import SectionWrap from 'src/components/common/SectionWrap';
import PostCommentItem from 'src/components/projects/comments/posts/PostCommentItem';
import PostCommentMenu from 'src/components/projects/comments/posts/PostCommentMenu';
import PostComments from 'src/components/projects/comments/posts/PostComments';
import DeletePostModal from 'src/components/projects/posts/DeletePostModal';
import PostModal from 'src/components/projects/posts/PostModal';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import IconButton from 'src/components/ui/Button/IconButton';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import ImageViewer from 'src/components/ui/ImageViewer';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import ColorTag from 'src/components/ui/tags/ColorTags';
import StatusTag from 'src/components/ui/tags/StatusTags';
import Timestamp from 'src/components/ui/TimeStamp';
import { useUser } from 'src/context/userContext';
import { useComment } from 'src/hooks/project/post/useCommentAction';
// import { useWorkspaceContext } from 'src/context/workspaceContext';
import { transformToTitleCase } from 'src/lib/helpers';
import { IPost } from 'src/types';

interface IState {
  editPost?: {
    show: boolean;
    post: IPost | undefined;
  };
  deletePost?: {
    show: boolean;
    post: IPost | undefined;
  };
}

export default function CommentPage() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const { workspaceSlug, projectId, postId } = useParams<{
    projectId: string;
    postId: string;
    workspaceSlug: string;
  }>();

  const [userId] = useLocalStorage('userId', undefined);
  const { user } = useUser();
  const ROOT_URL = `/workspace/${workspaceSlug}/project`;
  // const { currentWorkspaceProject } = useWorkspaceContext();

  const { loading, data } = usePosts({
    variables: {
      where: {
        id: { equals: postId },
      },
      orderBy: { createdAt: 'desc' },
    },
    onError: (error: any) => {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={`Post not found. Redirecting to project page...`}
        />
      ));
      navigate(`${ROOT_URL}/${projectId}`);
    },
  });

  const { loading: loadingProject, data: projectData } = useProject({
    variables: {
      where: {
        id: { equals: projectId },
        workspace: {
          slug: { equals: workspaceSlug },
        },
      },
    },
  });

  const [state, setState] = useState<IState>({
    editPost: {
      show: false,
      post: undefined,
    },
    deletePost: undefined,
  });

  const post = data?.posts[0];
  const isPostOwner = userId === post?.author?.id;

  const handleCopyPost = (post: IPost) => () => {
    const origin = window.location.origin;
    const project = post.project;
    const workspace = project.workspace;
    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/post/${post.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to post is copied to clipboard'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying text to clipboard'} />
        ));
      });
  };

  const commentId = searchParams.get('commentId');
  const componentRef = useRef<HTMLDivElement | null>(null);
  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);

  // const [isExpanded, setIsExpanded] = useState(false);
  // const [isTruncated, setIsTruncated] = useState(false);
  // const [isHovered, setIsHovered] = useState(false);
  const contentRef2 = useRef<HTMLDivElement>(null);

  // const toggleExpand = () => {
  //   setIsExpanded(!isExpanded);
  //   setIsHovered(!isHovered);
  // };

  const editorWrapperRef = useRef<HTMLDivElement>(null);
  const [editorWrapperHeight, setEditorWrapperHeight] = useState(46);

  const {
    control,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment,
    editorConfig,
  } = useComment({
    postId: postId as string,
    collaborators: projectData?.project?.collaborators,
    editorPlaceholder: 'Leave a comment',
  });

  useEffect(() => {
    const updateEditorHeight = () => {
      if (editorWrapperRef.current) {
        const newHeight = editorWrapperRef.current.offsetHeight;
        if (newHeight !== editorWrapperHeight) {
          setEditorWrapperHeight(newHeight);
        }
      }
    };

    updateEditorHeight();

    window.addEventListener('resize', updateEditorHeight);

    return () => {
      window.removeEventListener('resize', updateEditorHeight);
    };
  }, [editorWrapperHeight]);

  // not sure of why we need this tho, investigating
  // useEffect(() => {
  //   if (contentRef2.current) {
  //     // Measure the height of 6 lines
  //     const lineHeight = parseFloat(
  //       getComputedStyle(contentRef2.current).lineHeight
  //     );
  //     // console.log(parseFloat(getComputedStyle(contentRef2.current).lineHeight), 'parseFloat(getComputedStyle(contentRef2.current).lineHeight)')
  //     const maxHeight = lineHeight * 6;

  //     // Check if content height exceeds the height of 6 lines
  //     // console.log(contentRef2.current.scrollHeight, 'contentRef2.current.scrollHeight')
  //     if (contentRef2.current.scrollHeight > maxHeight) {
  //       setIsTruncated(false);
  //       // setIsTruncated(true);
  //     }
  //   }
  // }, [post]);

  useEffect(() => {
    if (componentRef.current instanceof HTMLElement) {
      const element = componentRef.current;
      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      if (!commentId) {
        element.classList.add('bg-warning-50');
        setTimeout(() => {
          element.classList.remove('bg-warning-50');
        }, 2000);
      }
    }
  }, [commentId]);

  if (loading || loadingProject)
    return <PageLoader snippet="Loading posts details and comments" />;

  return (
    <SectionWrap className="w-full md:max-w-[70%] overflow-auto">
      <div className="flex items-start gap-4 px-6 py-4 sticky top-0 z-10 bg-[inherit] border-b border-[var(--border-divider)]">
        <IconButton
          icon={<ArrowBack />}
          size={'standard'}
          type={'ghost'}
          onClick={() => navigate(-1)}
        />
        <HeaderText
          subTitle="Comments"
          snippet={`${post?.comments?.length} comments`}
        />
      </div>

      <div className="p-6 flex flex-col items-start gap-6 w-full">
        <div className="flex items-center space-x-6 w-full">
          <div className="flex-1 flex space-x-2 items-center">
            <Avatar
              name={post?.author.detail?.fullName ?? post?.author.email}
              src={post?.author.detail.avatar?.url}
              loading={loading}
              alt={`${post?.author.detail?.fullName} pfp`}
              size={28}
            />
            <div className="">
              <div className="text-body-small medium text-[var(--text-default)] capitalize">
                {post?.author.detail?.fullName}
              </div>
              <Timestamp
                className="text-[var(--text-neutral)] text-body-micro regular"
                createdAt={post?.createdAt}
              />
            </div>
          </div>

          <div className="flex gap-6 items-center">
            <ul className="flex gap-2 items-start">
              {post?.topic && (
                <li>
                  <ColorTag
                    value={post?.topic.name}
                    size="medium"
                    type="filled"
                    leftIcon={<WorkStream />}
                    useDefaultColor={post?.topic.name === 'All'}
                  />
                </li>
              )}
              {post?.type && (
                <li>
                  <StatusTag
                    value={transformToTitleCase(post.type)}
                    size="medium"
                    type="filled"
                    color="default"
                    leftIcon={<MegaPhone className="w-3 h-3" />}
                  />
                </li>
              )}
            </ul>

            <PostCommentMenu
              canEdit={isPostOwner}
              canDelete={isPostOwner}
              handleCopy={handleCopyPost(post)}
              handleEdit={() =>
                setState({
                  ...state,
                  editPost: { show: true, post },
                })
              }
              handleDelete={() =>
                setState({
                  ...state,
                  deletePost: { show: true, post },
                })
              }
            />
          </div>
        </div>

        <div className="space-y-2 w-full">
          <h2 className="text-heading-6 medium text-[var(--text-default)]">
            {post?.title}
          </h2>

          <div className="w-full relative">
            <article
              ref={contentRef2}
              className={`ck ck-content text-[var(--text-default)]`}
            >
              {parse(post?.comment ?? '')}
            </article>
            {/* {isTruncated && (
              <div
                className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
                style={{
                  background:
                    !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
                }}
                onMouseEnter={() => setIsHovered(true)}
                onMouseLeave={() => setIsHovered(false)}
              >
                <AnimatePresence>
                  {isExpanded && isHovered && (
                    <motion.div
                      initial={{ y: 10, opacity: 0 }}
                      animate={{ y: 0, opacity: 1 }}
                      exit={{ y: 10, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                    >
                      <IconButton
                        icon={<ChevronDown className="rotate-180" />}
                        size={'standard'}
                        type={'neutral'}
                        rounded="full"
                        onClick={toggleExpand}
                      />
                    </motion.div>
                  )}

                  {!isExpanded && (
                    <IconButton
                      icon={<ChevronDown />}
                      size={'standard'}
                      type={'neutral'}
                      onClick={toggleExpand}
                    />
                  )}
                </AnimatePresence>
              </div>
            )} */}
          </div>
        </div>

        {post?.attachments.length > 0 && (
          <ul className="flex space-x-2 w-full">
            {post?.attachments.map(
              (
                media: { url: any; filename: string | undefined },
                key: number
              ) => (
                <li
                  key={key}
                  className="aspect-square flex-1 max-h-[300px] overflow-hidden"
                >
                  <img
                    onClick={() => {
                      setShowImageModal(true);
                      setCurrentImage(key);
                    }}
                    className="aspect-square bg-grey-100 rounded-lg object-cover"
                    src={media.url}
                    alt={media.filename}
                  />
                </li>
              )
            )}
          </ul>
        )}

        {showImageModal && (
          <ImageViewer
            images={post?.attachments}
            currentImage={currentImage}
            setCurrentImage={(e: number) => setCurrentImage(e)}
            setShowImageModal={() => setShowImageModal(false)}
          />
        )}

        <div className="space-y-2 w-full">
          <div className="flex items-end relative w-full">
            <div className="absolute left-2 pb-2.5">
              <Avatar
                name={user?.detail?.fullName ?? user?.email ?? 'user'}
                src={user?.detail.avatar?.url || ''}
                loading={loading}
                alt={`${user?.detail?.fullName || 'user'} pfp`}
                size={24}
              />
            </div>

            <div
              ref={editorWrapperRef}
              className={`!w-full !bg-[var(--backgrounds-default)] shadow-none !pl-11 !pr-2 min-h-11
              ${
                editorWrapperHeight > 48 ? '!rounded-lg' : '!rounded-full'
              } transition-all duration-300
            `}
            >
              <div className="placeholder:!text-base placeholder:!font-medium placeholder:!text-[var(--text-disabled)] !w-full">
                <Controller
                  name="comment"
                  control={control}
                  render={({ field }) => (
                    <CKEditor
                      editor={BalloonEditor}
                      data={field.value}
                      config={editorConfig as any}
                      onChange={(event, editor) => {
                        field.onChange(editor.getData());
                        handleEditorChange(editor);
                        setTimeout(() => {
                          if (editorWrapperRef.current) {
                            setEditorWrapperHeight(
                              editorWrapperRef.current.offsetHeight
                            );
                          }
                        }, 0);
                      }}
                    />
                  )}
                />
              </div>
            </div>

            <div className="absolute right-2 pb-1.5">
              <IconButton
                type="primary"
                size="standard"
                rounded="full"
                icon={<ArrowUp />}
                onClick={handleSubmit((data) => handleCreateComment(data))}
                loading={isCreatingComment}
              />
            </div>
          </div>
        </div>
      </div>
      <HorizontalDivider />

      <PostComments
        comments={post?.comments}
        renderComment={(comment) => (
          <PostCommentItem
            myData={user}
            comment={comment}
            collaborators={projectData?.project?.collaborators}
            postId={postId as string}
          />
        )}
      />

      <PostModal
        initialPost={state.editPost?.post}
        show={Boolean(state.editPost?.show)}
        handleClose={() =>
          setState({
            ...state,
            editPost: {
              show: false,
              post: undefined,
            },
          })
        }
        collaborators={projectData?.project?.collaborators}
      />

      <DeletePostModal
        post={state.deletePost?.post}
        show={Boolean(state.deletePost?.show)}
        handleClose={() =>
          setState({
            ...state,
            deletePost: {
              show: false,
              post: undefined,
            },
          })
        }
      />
    </SectionWrap>
  );
}
