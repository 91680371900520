import { clsx, type ClassValue } from 'clsx';
import {
  format,
  isThisYear,
  parseISO,
  startOfDay,
  startOfToday,
  subDays,
} from 'date-fns';
import { IProjectLink } from 'src/lib/types';
import { twMerge } from 'tailwind-merge';

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}

function convertCamelToText(name: string): string {
  return name
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

function validateEmail(email: string) {
  const regex =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return regex.test(String(email).toLowerCase());
}

function validatePassword(password: string) {
  // const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;
  const regex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z]).{4,}$/;
  return regex.test(password);
}

function fixUrl(urlObj: IProjectLink) {
  const { label, url } = urlObj;
  const countHttps = (url.match(/https:/g) || []).length;

  if (countHttps > 1) {
    const fixedUrl = url.replace(/https:\/\/https\/\//, 'https://');
    return { label, url: fixedUrl };
  } else if (countHttps === 0) {
    const fixedUrl = `https://${url}`;
    return { label, url: fixedUrl };
  } else {
    return { label, url };
  }
}

function formatRelativeTime(createdAt: string): string {
  const date: Date = parseISO(createdAt);
  const now: Date = new Date();
  const diffInSeconds: number = (now.getTime() - date.getTime()) / 1000;

  if (diffInSeconds < 60) {
    return diffInSeconds < 30 ? 'Just now' : '1 min ago';
  } else if (diffInSeconds < 3600) {
    const mins = Math.floor(diffInSeconds / 60);
    return `${mins} min${mins > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  const today = startOfToday();
  const yesterday = subDays(today, 1);
  const dateMidnight = startOfDay(date);

  if (dateMidnight.getTime() === yesterday.getTime()) {
    return `Yesterday, ${format(date, 'hh:mm aa')}`;
  } else if (dateMidnight.getTime() === today.getTime()) {
    return `Today, ${format(date, 'hh:mm aa')}`;
  } else {
    // Format based on whether the date is in the current year
    if (isThisYear(date)) {
      return format(date, 'MMM d');
    } else {
      return format(date, 'MMM d, yyyy');
    }
  }
}

export {
  convertCamelToText,
  fixUrl,
  formatRelativeTime,
  validateEmail,
  validatePassword,
};
