import { CKEditor } from '@ckeditor/ckeditor5-react';
import { yupResolver } from '@hookform/resolvers/yup';
import BalloonEditor from 'ckeditor5-custom-build';
import { format } from 'date-fns';
import { AnimatePresence, motion } from 'framer-motion';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useCreateOneTask } from 'src/api/task/task.service';
import {
  ChevronDown,
  Close,
  IconCalendar,
  UserCircle,
  WorkStream,
} from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import { transformToTitleCase } from 'src/lib/helpers';
import { IUser } from 'src/types';
import { TaskPriority, TaskStatus } from 'src/types/entities';
import * as yup from 'yup';
import ToastMessage from '../../ToastMessage';
import Button from '../../ui/Button/Button';
import IconButton from '../../ui/Button/IconButton';
import HeaderText from '../../ui/HeaderTexts';
import StatusTag from '../../ui/tags/StatusTags';
import CustomSelect from '../CustomSelect';
import DatePickerPopover from '../DatePickerPopover';

const createTaskSchema = yup.object().shape({
  title: yup.string().required('title is required'),
  description: yup.string().optional().default(''),
  assignee: yup.string().optional().default(''),
  workstream: yup.string().optional().default(''),
  dueDate: yup.string().optional().default(new Date().toISOString()),
});

function CreateTaskModal({
  show,
  handleClose,
  workspaceSlug,
  collaborators = [],
  workStreams = [],
}: {
  show: boolean;
  handleClose: () => void;
  workspaceSlug: string;
  collaborators?: IUser[];
  workStreams?: any;
}) {
  const { projectId } = useParams<{ projectId: string }>();

  const [createOneTask, { loading: isCreatingOneTask }] = useCreateOneTask();

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm({
    defaultValues: {
      title: '',
      description: '',
      assignee: '',
      workstream: workStreams?.[0]?.id || '',
      dueDate: new Date().toISOString(),
    },
    resolver: yupResolver(createTaskSchema),
  });

  const onSubmit = async (data: any) => {
    createOneTask({
      variables: {
        data: {
          title: data.title,
          description: data.description,
          workStream: {
            connect: { id: data.workstream },
          },
          project: {
            connect: { id: projectId },
          },
          assignee: data.assignee
            ? {
                connect: { id: data.assignee },
              }
            : undefined,
          dueDate: data.dueDate,
          priority: TaskPriority.LOW,
          status: TaskStatus.NOT_STARTED,
        },
      },
      onCompleted: () => {
        handleClose();
        reset();
        toast((t) => (
          <ToastMessage id={t.id} title="Task created successfully" />
        ));
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage id={t.id} title="Error creating task">
            {error.message}
          </ToastMessage>
        ));
      },
    });
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="fixed z-20 inset-0 bg-black/30"
        >
          <div className="fixed inset-0 flex w-screen items-start mt-16 justify-center p-4">
            <motion.div
              initial={{ opacity: 0, scale: 0.95 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.95 }}
              className="group-container border rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[600px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]"
            >
              <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                <div className="flex items-center justify-between w-full p-5 border-b border-[var(--border-divider)]">
                  <HeaderText subTitle={'Create new task'} />
                  <IconButton
                    icon={<Close />}
                    size={'micro'}
                    type={'ghost'}
                    onClick={handleClose}
                  />
                </div>
                <div className="p-5 max-h-[500px] overflow-y-auto">
                  <div className="w-full flex flex-col items-start">
                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          disabled={isCreatingOneTask}
                          autoFocus
                          placeholder="What's the task?"
                          {...field}
                          value={field.value}
                          className="input-clear bg-transparent text-heading-4 medium text-[var(--text-default)] !border-[var(--backgrounds-dropdowns)] !outline-[var(--backgrounds-dropdowns)] !focus-visible:outline-none !outline-none w-full text-2xl placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 placeholder:medium"
                        />
                      )}
                    />
                  </div>

                  <Controller
                    name="description"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                      <CKEditor
                        editor={BalloonEditor as any}
                        data={value}
                        onChange={(event, editor) => {
                          const data = editor.getData();
                          onChange(data);
                        }}
                        config={{
                          placeholder: 'Type a description',
                        }}
                      />
                    )}
                  />
                </div>

                <div className="p-5 border-t border-[var(--border-divider)]">
                  <div className="flex gap-4 flex-col md:flex-row items-start md:justify-between md:items-center">
                    <ul className="flex items-start w-full md:w-auto md:items-center flex-col md:flex-row flex-wrap gap-3">
                      <li className="w-full md:w-fit">
                        <Controller
                          name="assignee"
                          control={control}
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="Assignee"
                              triggerIcon={<UserCircle className="w-3 h-3" />}
                              triggerButtonSize={'large'}
                              contentClassName="w-[250px]"
                              customValueItem={(value) => (
                                <StatusTag
                                  leftIcon={<UserCircle className="w-3 h-3" />}
                                  rightIcon={
                                    <ChevronDown className="w-3 h-3" />
                                  }
                                  color="default"
                                  size="large"
                                  value={
                                    <Avatar
                                      name={(value?.label as string) || ''}
                                      size={16}
                                      src={
                                        value?.others?.detail?.avatar?.url || ''
                                      }
                                    />
                                  }
                                />
                              )}
                              searchPlaceholder="Select assignee"
                              options={
                                collaborators?.map((collaborator) => ({
                                  label:
                                    collaborator?.detail?.fullName ??
                                    collaborator?.email,
                                  value: collaborator?.id,
                                  others: collaborator,
                                })) || []
                              }
                              customOptionItem={(option) => (
                                <div className="flex items-center gap-x-2 py-[2.5px]">
                                  <Avatar
                                    name={option?.label as string}
                                    size={28}
                                  />
                                  <div className="flex-1">
                                    <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
                                      {option?.label}
                                    </div>
                                    <div className="text-body-micro regular text-[var(--text-neutral)]">
                                      {transformToTitleCase(
                                        option.others?.detail?.position
                                      )}
                                    </div>
                                  </div>
                                </div>
                              )}
                            />
                          )}
                        />
                      </li>
                      <li className="w-full md:w-fit">
                        <Controller
                          name="workstream"
                          control={control}
                          render={({ field }) => (
                            <CustomSelect
                              {...field}
                              value={field.value}
                              onChange={field.onChange}
                              placeholder="Select workstream"
                              triggerIcon={<WorkStream className="w-3 h-3" />}
                              triggerButtonSize={'large'}
                              contentClassName="w-[200px]"
                              options={
                                workStreams?.map((workstream: any) => ({
                                  label: workstream.name,
                                  value: workstream.id,
                                })) || []
                              }
                              searchPlaceholder="Add workstream"
                            />
                          )}
                        />
                      </li>
                      <li className="w-full md:w-fit">
                        <Controller
                          name="dueDate"
                          control={control}
                          render={({ field }) => (
                            <DatePickerPopover
                              onChange={field.onChange}
                              value={field.value}
                            >
                              <StatusTag
                                leftIcon={<IconCalendar className="w-3 h-3" />}
                                rightIcon={<ChevronDown className="w-3 h-3" />}
                                color={'default'}
                                size={'large'}
                                value={format(
                                  new Date(field.value),
                                  'MMM dd, yyyy'
                                )}
                              />
                            </DatePickerPopover>
                          )}
                        />
                      </li>
                    </ul>
                    <div className="ml-auto gap-3 items-center flex">
                      <Button
                        type="submit"
                        loading={isCreatingOneTask}
                        disabled={isCreatingOneTask || !isValid}
                        value={'Create'}
                        size={'medium'}
                        btnType={'primary'}
                      />
                    </div>
                  </div>
                </div>
              </form>
            </motion.div>
          </div>
        </motion.div>
      )}
    </AnimatePresence>
  );
}

export default CreateTaskModal;
