import { useLocalStorage } from '@uidotdev/usehooks';
import React, { FormEvent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'src/components/ui/Button/Button';
import Input from 'src/components/ui/inputs/Default';
import Validator from 'validatorjs';
import { useUpdateOneUserPassword } from '../api/user/user.service';

type FormData = {
  password: string;
  password_confirmation: string;
};

// refactor this (use RHF and check the diff fields) #reminder

export default function NewPasswordPage() {
  const navigate = useNavigate();
  const [userId] = useLocalStorage('userId', undefined);

  const [formData, setFormData] = useState<FormData>({
    password: '',
    password_confirmation: '',
  });
  const [formErrors, setFormErrors] =
    useState<Validator.ValidationErrors | null>(null);
  const [updateOneUserPassword, { loading, error }] =
    useUpdateOneUserPassword();
  const errorMessage = error && `${error.message}`;
  const disabled = loading;

  const handleInputChange = ({
    field,
    e,
  }: {
    field: string;
    e: React.ChangeEvent<HTMLInputElement>;
  }) => {
    let value;
    try {
      value = e.target.value;
    } catch (error) {
      value = e;
    }

    setFormData({ ...formData, [field]: value });
    setFormErrors(null);
  };

  const handleContinue = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const validation = new Validator(formData, {
      password: 'required|confirmed',
    });

    if (validation.passes()) {
      setFormErrors(null);
      updateOneUserPassword({
        variables: {
          data: {
            password: { set: formData.password },
          },
          where: { id: userId },
        },
        onCompleted: (data) => navigate('/'),
      });
    } else {
      setFormErrors(validation.errors.all());
    }
  };

  return (
    <>
      <div className="w-full flex flex-col gap-4 items-start">
        <div className="flex flex-col gap-2 items-start">
          <h3 className="text-heading-3 bold text-[var(--text-default)]">
            Create new password
          </h3>
          <p className="text-body-small regular text-[var(--text-neutral)]">
            Your new password must be different from previous passwords you’ve
            used for your account.
          </p>
        </div>
      </div>
      <form onSubmit={handleContinue} className="w-full flex flex-col gap-6">
        <Input
          autoFocus
          type="password"
          value={formData.password}
          feedback={''}
          feedbackType={'success'}
          label="Enter new  password"
          placeholder=""
          onChange={(e) => {
            handleInputChange({ field: 'password', e });
          }}
        />
        <Input
          type="password"
          value={formData.password_confirmation}
          feedback={''}
          feedbackType={'success'}
          label="Confirm new password"
          placeholder=""
          // onChange={(e) => setEmail(e)}
          onChange={(e) => {
            handleInputChange({ field: 'password_confirmation', e });
          }}
        />
        <Button
          type="submit"
          btnType="primary"
          size="large"
          value="Continue"
          loading={loading}
          disabled={loading}
          className="w-full"
        />
      </form>
    </>
  );
}
