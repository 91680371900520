import Spinner from '../spinner/bar-spinner/Spinner';
import '../ui.css';
import DefaultAvatar from './DefaultAvatar';

interface AvatarProps {
  name: string;
  loading?: boolean;
  src?: string;
  alt?: string;
  className?: string;
  size: number;
  onClick?: () => void;
}

const AvatarSizes: Record<number, { fontSize: number; fontWeight: number }> = {
  12: { fontSize: 8, fontWeight: 600 },
  16: { fontSize: 10, fontWeight: 600 },
  20: { fontSize: 12, fontWeight: 600 },
  24: { fontSize: 14, fontWeight: 600 },
  28: { fontSize: 16, fontWeight: 600 },
  32: { fontSize: 20, fontWeight: 600 },
  40: { fontSize: 24, fontWeight: 600 },
  44: { fontSize: 34, fontWeight: 600 },
  128: { fontSize: 73, fontWeight: 600 },
};

const Avatar: React.FC<AvatarProps> = ({
  name = 'user',
  loading = false,
  src,
  size,
  alt = 'user avatar',
  className,
  onClick,
}) => {
  return (
    <div
      className={`avatar_wrap flex items-center justify-center relative aspect-square ${
        className ? className : ''
      }`}
      style={{ '--size': `${size}px` } as React.CSSProperties}
      onClick={onClick}
    >
      {loading && (
        <div className="absolute flex items-center justify-center w-full h-full rounded-full bg-[#000000c7]">
          <Spinner spinnnerSize={'large'} />
        </div>
      )}
      {src?.length && !loading ? (
        <img
          className="w-full h-full object-cover"
          src={src}
          alt={alt}
          style={{ cursor: 'pointer' }}
        />
      ) : (
        <DefaultAvatar
          name={name}
          size={size}
          fontSize={AvatarSizes[size].fontSize}
          fontWeight={600}
        />
      )}
    </div>
  );
};

export default Avatar;
