import { ChatBubble } from 'src/assets/icons';
import { cn } from 'src/lib/utils';
import { IComment } from 'src/types/entities';

function PostComments({
  comments,
  renderComment,
}: {
  comments: IComment[];
  renderComment: (comment: IComment) => JSX.Element;
}) {
  if (!!comments.length) {
    return (
      <ul className="p-5 flex flex-col gap-y-5 w-full">
        {comments.map((comment, index) => (
          <li
            key={comment.id}
            className={cn(
              'flex flex-col gap-2.5 w-full pb-5',
              index < comments?.length - 1 &&
                'border-b border-[var(--border-default)]'
            )}
          >
            {renderComment(comment)}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 mt-12 w-full">
      <ChatBubble className="h-8 w-8" />
      <h6 className="text-heading-6 medium text-[var(--text-default)]">
        No comments yet
      </h6>
      <p className="text-body-small regular text-[var(--text-neutral)]">
        Add the first comment
      </p>
    </div>
  );
}

export default PostComments;
