import { useQuery } from '@apollo/client';
import { WORKSPACE_PLAN, WORKSPACE_PLANS } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useWorkspacePlans(args: object) {
  const options = useApolloOptions();
  return useQuery(WORKSPACE_PLANS, { ...options, ...args });
}

export function useWorkspacePlan(args: object) {
  const options = useApolloOptions();
  return useQuery(WORKSPACE_PLAN, { ...options, ...args });
}
