import { AnimatePresence, motion } from 'framer-motion';
import { Archive, Trash } from 'src/assets/icons';
import Button from '../ui/Button/Button';
import Modal from '../ui/Modals/Modal';

export default function DeleteProjectModal(props: {
  show: boolean;
  handleClose: Function;
  handlePreArchive: Function;
  handlePreDelete: Function;
  archivingProject: boolean;
  deletingProject: boolean;
}) {
  const {
    show,
    handleClose,
    handlePreArchive,
    handlePreDelete,
    archivingProject,
    deletingProject,
  } = props;

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this project',
              snippet:
                'If you change your mind, you can restore it within the next 30 days. After that it will be permanently deleted from your workspace.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            showCTA={false}
          >
            <div className="flex items-center justify-between">
              <Button
                className={'w-auto'}
                btnType="neutral"
                size="medium"
                icon="left"
                value={'Archive instead'}
                loading={archivingProject}
                onClick={() => {
                  handlePreArchive();
                }}
              >
                <Archive />
              </Button>
              <div className="flex justify-end gap-3 items-center">
                <Button
                  className={'w-auto'}
                  btnType="ghost"
                  size="medium"
                  value={'Cancel'}
                  onClick={() => handleClose()}
                />
                <Button
                  className={'w-auto'}
                  btnType="destructive"
                  size="medium"
                  icon="left"
                  value={'Delete'}
                  loading={deletingProject}
                  onClick={() => {
                    handlePreDelete();
                  }}
                >
                  <Trash />
                </Button>
              </div>
            </div>
          </Modal>
        </motion.div>
      )}
    </AnimatePresence>
  );
}
