import Avatar from 'src/components/ui/Avatar/Avatar';
import { transformToTitleCase } from 'src/lib/helpers';

interface UserDetailItemProps {
  userName: string;
  userPosition: string;
  avatarUrl?: string;
  avatarSize?: number;
}

const UserDetailItem = ({
  userName,
  userPosition,
  avatarUrl,
  avatarSize = 28,
  ...rest
}: UserDetailItemProps) => {
  return (
    <div
      className="w-full flex items-center justify-between gap-x-2 px-2 min-h-[32px] hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer rounded"
      {...rest}
    >
      <div className="flex items-center gap-x-2 py-[2.5px]">
        <Avatar
          name={userName}
          src={avatarUrl}
          alt={userName}
          size={avatarSize}
        />
        <div className="flex-1">
          <div className="text-body-small medium text-[var(--text-default)] max-w-[150px] truncate">
            {userName}
          </div>
          <div className="text-body-micro regular text-[var(--text-neutral)]">
            {transformToTitleCase(userPosition)}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UserDetailItem;
