import React from 'react';
import { AvatarColorPreset, colorStyles } from 'src/lib/colorPreset';
import { getColorTag, getInitials } from 'src/lib/helpers';
import Spinner from './ui/spinner/bar-spinner/Spinner';

interface MediaProps {
  src?: string;
  alt: string;
  width: number;
  height: number;
  fontSize: number;
  loading?: boolean;
}

const Media: React.FC<MediaProps> = ({
  src,
  loading,
  alt,
  width,
  height,
  fontSize,
}) => {
  const getColorPreset = (alt: string) => {
    if (alt.length < AvatarColorPreset.length - 1) {
      return AvatarColorPreset[alt.length];
    } else {
      const presetIndex = alt.length % AvatarColorPreset.length;
      return AvatarColorPreset[presetIndex];
    }
  };

  if (src?.length && !loading) {
    return (
      <img
        src={src}
        alt={alt}
        style={{ height: `${height}px`, width: `${width}px` }}
        className="aspect-square border border-[var(--border-on-bg)] rounded object-cover"
      />
    );
  }

  const colorPreset = getColorPreset(alt);

  return (
    <div
      className={`relative rounded grid uppercase place-content-center font-bold ${colorPreset}`}
      style={{
        width: `${width}px`,
        height: `${height}px`,
        fontSize: `${fontSize}px`,
        ...colorStyles[getColorTag(alt)],
      }}
    >
      {loading && (
        <div className="absolute flex items-center justify-center w-full h-full rounded-full bg-[#000000c7]">
          <Spinner spinnnerSize={'large'} />
        </div>
      )}

      {getInitials(alt)}
    </div>
  );
};

export default Media;
