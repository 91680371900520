import { IPricingPlan } from 'src/types';
import { ReactComponent as Check } from '../../assets/svgs/ImgCheck.svg';
import Button from '../ui/Button/Button';

export default function PricingCard({
  plan,
  activePlanType,
  className,
  selectedCycle,
  onClick,
  isLoading,
}: {
  plan: IPricingPlan;
  activePlanType: string | undefined;
  className?: string;
  selectedCycle: 'MONTHLY' | 'ANNUALLY';
  onClick: () => void;
  isLoading: boolean;
}) {
  const getButtonText = () => {
    if (activePlanType === plan.type) {
      return 'Current plan';
    }
    if (activePlanType === 'PRO' && plan.type === 'BASIC') {
      return 'Switch to Basic';
    }
    return plan.button.label;
  };

  const getButtonType = () => {
    if (plan.type === 'PRO') return 'primary';
    if (activePlanType === 'PRO' && plan.type === 'ENTERPRISE')
      return 'primary';
    return 'neutral';
  };

  return (
    <div
      className={`w-full min-h-[694px] flex flex-wrap flex-col items-start gap-8 rounded-xl overflow-hidden p-6 border border-[var(--border-default)] ${className}`}
      style={{
        background:
          plan.type === 'PRO'
            ? 'var(--plan-card-bg)'
            : 'var(--backgrounds-on-canvas)',
      }}
    >
      <div>
        <div className="flex items-center gap-2 mb-1">
          <div>{plan.icon}</div>
          <h2 className="text-heading-6 medium text-[var(--text-default)]">
            {plan.planName}
          </h2>
        </div>
        <p className="text-body-small regular text-[var(--text-neutral)]">
          {plan.description}
        </p>
      </div>

      <div className="w-full flex flex-col gap-y-8">
        <div className="flex flex-col justify-start gap-0.5 h-[67px]">
          {plan.type !== 'ENTERPRISE' && (
            <div
              className="text-heading-2 text-[34px] font-bold text-[var(--text-default)]"
              style={{ lineHeight: '44px' }}
            >
              {plan.currency}
              {selectedCycle === 'MONTHLY'
                ? Math.ceil(plan.price * 1.2)
                : plan.price}
            </div>
          )}

          {!['FREE', 'ENTERPRISE'].includes(plan.type) && (
            <p className="text-body-small regular text-[var(--text-neutral)]">
              per member/month
            </p>
          )}
        </div>

        <Button
          size="medium"
          btnType={getButtonType()}
          value={getButtonText()}
          disabled={activePlanType === plan.type}
          onClick={onClick}
          loading={isLoading}
        />
      </div>

      <ul className="flex flex-col gap-2 items-start w-full">
        {plan.features.map((feature, index) => (
          <li
            key={index}
            className="text-[var(--text-default)] list-none flex gap-2 items-center"
          >
            <Check />
            <p className="text-body-small medium">{feature.name}</p>
            {feature.comingSoon && (
              <span className="text-xs h-5 flex items-center gap-1 px-1.5 rounded-md bg-[var(--backgrounds-default)]">
                Coming Soon
              </span>
            )}
          </li>
        ))}
      </ul>
    </div>
  );
}
