import { useEffect, useRef, useState } from 'react';

import { useLocalStorage } from '@uidotdev/usehooks';
// import { AnimatePresence, motion } from 'framer-motion';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  ChatBubble,
  ChevronDown,
  MegaPhone,
  WorkStream,
} from 'src/assets/icons';
import { transformToTitleCase } from 'src/lib/helpers';
import { IPost } from 'src/types';
import Avatar from '../../ui/Avatar/Avatar';
import ImageViewer from '../../ui/ImageViewer';
import ColorTag from '../../ui/tags/ColorTags';
import StatusTag from '../../ui/tags/StatusTags';
import Timestamp from '../../ui/TimeStamp';
import PostCommentMenu from '../comments/posts/PostCommentMenu';

function PostItem(props: {
  post: IPost;
  handleCopy: Function;
  handleEdit: Function;
  handleDelete: Function;
}) {
  const { post, handleCopy, handleDelete, handleEdit } = props;
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const { projectId, workspaceSlug } = useParams();

  const [showImageModal, setShowImageModal] = useState(false);
  const [currentImage, setCurrentImage] = useState(0);
  const [userId] = useLocalStorage('userId', undefined);

  const isPostOwner = userId === post.author.id;
  const postId = searchParams.get('postId');
  const isFocusedPost = postId === post.id;

  const postRef = useRef<HTMLDivElement | null>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const gotoCommentPage = (postId: string) => {
    navigate(
      `/workspace/${workspaceSlug}/project/${projectId}/posts/${postId}`
    );
  };

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    if (isFocusedPost && postRef.current instanceof HTMLElement) {
      const element = postRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.classList.add('bg-[var(--backgrounds-hover-clicked)]');
      setTimeout(() => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      }, 2000);

      return () => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      };
    }
  }, [isFocusedPost]);

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );

      const maxHeight = lineHeight * 6;

      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(true);
      }
    }
  }, [post.comment]);

  return (
    <div ref={postRef} className="transition-all duration-1000 p-6 space-y-6">
      <div className="flex items-start gap-4 flex-col md:flex-row md:items-center">
        <div className="flex items-center w-full">
          <div className="flex-1 flex gap-2 items-center">
            <Avatar
              name={post.author.detail?.fullName ?? post.author.email}
              src={post.author.detail.avatar?.url}
              alt={`${post.author.detail?.fullName} pfp`}
              size={32}
            />
            <div>
              <div className="text-body-small medium text-[var(--text-default)] capitalize max-w-[200px] truncate">
                {post.author.detail?.fullName ?? post.author.email}
              </div>

              <Timestamp
                className="text-[var(--text-neutral)] text-body-micro regular"
                createdAt={post.createdAt}
              />
            </div>
          </div>
          <span className="block md:hidden">
            <PostCommentMenu
              canEdit={isPostOwner}
              canDelete={isPostOwner}
              handleCopy={handleCopy}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </span>
        </div>

        <div className="flex gap-4 items-center">
          <ul className="flex gap-2 items-start">
            {post.topic && (
              <li>
                <ColorTag
                  value={post.topic.name}
                  size="medium"
                  type="filled"
                  leftIcon={<WorkStream />}
                  useDefaultColor={post?.topic.name === 'All'}
                />
              </li>
            )}
            {post.type && (
              <li>
                <StatusTag
                  value={transformToTitleCase(post.type)}
                  size="medium"
                  type="filled"
                  color="default"
                  leftIcon={<MegaPhone className="w-3 h-3" />}
                />
              </li>
            )}
          </ul>

          <span className="hidden md:block">
            <PostCommentMenu
              canEdit={isPostOwner}
              canDelete={isPostOwner}
              handleCopy={handleCopy}
              handleEdit={handleEdit}
              handleDelete={handleDelete}
            />
          </span>
        </div>
      </div>

      <div className="space-y-2 cursor-pointer">
        <h6 className="text-heading-6 medium text-[var(--text-default)] tracking-[0.2px]">
          {post.title}
        </h6>

        <div className="w-full relative">
          <article
            ref={contentRef}
            onClick={() => gotoCommentPage(post?.id)}
            dangerouslySetInnerHTML={{ __html: post.comment }}
            className={`ck ck-content text-[var(--text-default)] ${
              isExpanded ? '' : 'article truncate-lines'
            }`}
          ></article>

          {/* {isTruncated && ( hidden for now )
            <div
              className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
              style={{
                background:
                  !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <AnimatePresence>
                {isExpanded && isHovered && (
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <IconButton
                      icon={<ChevronDown className="rotate-180" />}
                      size={'standard'}
                      type={'neutral'}
                      rounded="full"
                      onClick={toggleExpand}
                    />
                  </motion.div>
                )}

                {!isExpanded && (
                  <IconButton
                    icon={<ChevronDown />}
                    size={'standard'}
                    type={'neutral'}
                    onClick={toggleExpand}
                  />
                )}
              </AnimatePresence>
            </div>
          )} */}
        </div>
      </div>

      {post.attachments.length > 0 && (
        <ul className="flex space-x-2">
          {post.attachments.map((media, key) => (
            <li
              key={key}
              className="aspect-square flex-1 max-h-[300px] overflow-hidden"
            >
              <img
                onClick={() => {
                  setShowImageModal(true);
                  setCurrentImage(key);
                }}
                className="aspect-square !bg-[var(--backgrounds-default)] rounded-lg object-cover"
                src={media.url}
                alt={media.filename}
              />
            </li>
          ))}
        </ul>
      )}

      {showImageModal && (
        <ImageViewer
          images={post.attachments}
          currentImage={currentImage}
          setCurrentImage={(e: number) => setCurrentImage(e)}
          showImageModal={showImageModal}
          setShowImageModal={() => setShowImageModal(false)}
        />
      )}

      <div
        className="flex items-center gap-1 relative group cursor-pointer"
        onClick={() => gotoCommentPage(post?.id)}
      >
        <div className="avatars-group flex items-center -space-x-1">
          {post?.comments.slice(0, 3).map((comment, index) => (
            <div key={index}>
              <Avatar
                name={
                  comment?.author?.detail?.fullName ?? comment?.author?.email
                }
                src={comment?.author?.detail?.avatar?.url}
                alt={`${comment?.author?.detail?.fullName} pfp`}
                size={20}
                className="border border-[var(--backgrounds-on-canvas)]"
              />
            </div>
          ))}
        </div>
        <div className="comments">
          <StatusTag
            color={'default'}
            size={'medium'}
            value={post.comments.length}
            leftIcon={<ChatBubble className="w-3 h-3" />}
          />
        </div>
        <div className="opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <ChevronDown className="-rotate-90" />
        </div>
      </div>
    </div>
  );
}

export default PostItem;
