import { AnimatePresence, motion } from 'framer-motion';
import { useEffect, useRef, useState } from 'react';
import { Check, ThreeDots } from 'src/assets/icons';
import SideNavMobile from 'src/components/sideNav/SideNavMobile';
import IconButton from 'src/components/ui/Button/IconButton';
import DropGroup from 'src/components/ui/DropGroup';
import MobileWorkSpaceMenu from 'src/components/ui/MobileWorkSpaceMenu';
import Tabs from 'src/components/ui/tables/Tab';
import useMobile from 'src/hooks/useMobile';

type Tab = {
  id: string;
  value: string;
};

const tabs: Tab[] = [
  { id: 'All', value: 'All' },
  { id: 'Projects', value: 'Projects' },
  { id: 'Mentions', value: 'Mentions' },
];

type DataItem = {
  name: string;
  value: 'readAll';
  icon: JSX.Element;
};

const readAllData: DataItem[] = [
  {
    name: 'Mark all as read',
    value: 'readAll',
    icon: <Check />,
  },
];

export default function InboxPage() {
  const isMobile = useMobile();
  const [currentTab, setCurrentTab] = useState<string>(tabs[0].value);
  const [showReadAll, setShowReadAll] = useState<boolean>(false);
  const modalRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!showReadAll) {
      document.addEventListener('mousedown', () => setShowReadAll(false));
    } else {
      document.removeEventListener('mousedown', () => setShowReadAll(false));
    }

    return () => {
      document.removeEventListener('mousedown', () => setShowReadAll(false));
    };
  }, [showReadAll]);

  return (
    <motion.div className="w-full md:w-auto">
      <div className="page-title z-1 flex items-center justify-between md:hidden">
        <MobileWorkSpaceMenu />

        <p className="tet-body-small bold text-[var(--text-neutral)]">Inbox</p>
        <div className="relative">
          <IconButton
            icon={<ThreeDots />}
            size={'micro'}
            type={'ghost'}
            onClick={() => setShowReadAll(true)}
          />
          <AnimatePresence>
            <div className="absolute top-8 right-0">
              <DropGroup
                open={showReadAll}
                data={readAllData}
                style={{ width: '200px' }}
                clickOutside={() => {
                  setShowReadAll(false);
                }}
              />
            </div>
          </AnimatePresence>
        </div>
      </div>
      <div className=" mt-[65px]">
        <motion.div
          initial={{ opacity: 0, scale: 0.95 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0, scale: 0.95 }}
          ref={modalRef}
        >
          <div className="flex flex-col p-5 gap-2.5">
            <Tabs
              tabs={tabs}
              onTabChange={(tab) => console.log(tab)}
              initialSelectedTab={currentTab}
            />
          </div>
          {/* <HorizontalDivider /> */}
          <ul className="w-full"></ul>
        </motion.div>
      </div>
      {isMobile && <SideNavMobile />}
    </motion.div>
  );
}
