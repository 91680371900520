import * as Tabs from '@radix-ui/react-tabs';
import { Fragment } from 'react';
import { toast } from 'react-hot-toast';
import {
  IconBoard,
  IconPen,
  IconViewList,
  Plus,
  UserCircle,
  WorkStream,
} from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import { useTaskContext } from 'src/context/taskContext';
import useMobile from 'src/hooks/useMobile';

import { useLocalStorage } from '@uidotdev/usehooks';
import { AnimatePresence } from 'framer-motion';
import useProjectFilters from 'src/hooks/project/useProjectFilters';
import { transformToTitleCase, userExistAsCollaborator } from 'src/lib/helpers';
import { IProjectTopic } from 'src/lib/types';
import { IProject, ITask, IUser } from 'src/types';
import SectionWrap from '../../common/SectionWrap';
import Button from '../../ui/Button/Button';
import ProjectFilterDropdown from '../filter/ProjectFilterDropdown';
import TaskDetailFlyout from '../task-detail/TaskDetailFlyout';
import DeleteTaskModal from './DeleteTaskModal';
import TaskCard from './TaskCard';
import TaskListView from './TaskListView';

function TasksArena({
  handleCreateTask,
  heightHeadingContainerRef,
  collaborators = [],
  workStreams = [],
  projectData,
}: {
  handleCreateTask: () => void;
  heightHeadingContainerRef: number;
  collaborators: IUser[];
  workStreams?: IProjectTopic[] | null;
  projectData: IProject;
}) {
  const [userId] = useLocalStorage('userId', undefined);
  const userCollaborates = userExistAsCollaborator(userId, projectData);

  const isMobile = useMobile();
  const {
    tasks,
    taskModal,
    closeTaskDetails,
    confirmDeleteTask,
    deleteConfirmation,
    closeDeleteConfirmation,
    isDeleting,
  } = useTaskContext();

  const {
    filteredItems: filteredTasks,
    filterCriteria,
    setFilterCriteria,
  } = useProjectFilters<ITask>(tasks || [], { itemType: 'task' as const });

  if (!!tasks?.length) {
    return (
      <Fragment>
        <SectionWrap className="flex flex-col md:max-h-full !rounded-[0_0_8px_8px] h-[100%]">
          <Tabs.Root className="w-full h-full" defaultValue="listView">
            <div className="w-full flex justify-between mt-6 pb-2 items-start px-6">
              <div className="w-max flex items-start gap-x-2">
                <ProjectFilterDropdown
                  value={filterCriteria.filteredWorkstreams}
                  onChange={(newWorkstreams: string[]) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      filteredWorkstreams: newWorkstreams,
                    })
                  }
                  icon={<WorkStream className="w-3 h-3" />}
                  label="Workstreams"
                  options={
                    workStreams?.map((workStream: any) => ({
                      label: workStream.name,
                      value: workStream.id,
                    })) || []
                  }
                />

                <ProjectFilterDropdown
                  value={filterCriteria.filteredMembers}
                  onChange={(newWorkstreams: string[]) =>
                    setFilterCriteria({
                      ...filterCriteria,
                      filteredMembers: newWorkstreams,
                    })
                  }
                  className="min-w-[250px]"
                  icon={<UserCircle className="w-3 h-3" />}
                  label="Members"
                  options={
                    collaborators?.map((collaborator) => ({
                      label:
                        collaborator.detail?.fullName ?? collaborator?.email,
                      value: collaborator.id,
                      other: collaborator,
                    })) || []
                  }
                  customOptionItem={(option) => (
                    <div className="flex items-center gap-x-2 py-[2.5px]">
                      <Avatar
                        name={option.label as string}
                        src={option.other.detail.avatar?.url}
                        size={28}
                      />
                      <div className="flex-1">
                        <div className="text-body-small medium text-[var(--text-default)]">
                          {option.label}
                        </div>
                        <div className="text-body-micro regular text-[var(--text-neutral)]">
                          {transformToTitleCase(option.other.detail?.position)}
                        </div>
                      </div>
                    </div>
                  )}
                />
              </div>

              <Tabs.List
                className="flex items-center gap-x-[6px] justify-center min-w-[57px] p-[1.5px] min-h-[27px] bg-[var(--backgrounds-default)] rounded-md"
                aria-label="Task page tabs"
              >
                <Tabs.Trigger
                  className="w-[24px] h-[24px] flex items-center justify-center text-[var(--icon-primary)] select-none outline-none cursor-pointer data-[state=active]:cursor-default data-[state=active]:bg-[var(--button-neutral-hover-clicked)] data-[state=active]:text-[var(--icon-hover)] rounded-md"
                  value={'listView'}
                >
                  <IconViewList className="h-3 w-3" />
                </Tabs.Trigger>
                <Tabs.Trigger
                  className="w-[24px] h-[24px] flex items-center justify-center text-[var(--icon-primary)] select-none outline-none cursor-pointer data-[state=active]:cursor-default data-[state=active]:bg-[var(--button-neutral-hover-clicked)] data-[state=active]:text-[var(--icon-hover)] rounded-md disabled:cursor-[not-allowed]"
                  value={'kanbanView'}
                  disabled
                >
                  <IconBoard className="h-3 w-3" />
                </Tabs.Trigger>
              </Tabs.List>
            </div>

            <div
              className="w-full pt-6 overflow-y-auto px-6"
              style={{
                height: !isMobile
                  ? `calc(100vh - 92px - ${heightHeadingContainerRef}px)`
                  : '100%',
              }}
            >
              <Tabs.Content className="grow h-[100%]" value={'listView'}>
                <TaskListView
                  tasks={filteredTasks as ITask[]}
                  onCreateTask={() => {
                    if (!userCollaborates) {
                      toast((t) => (
                        <ToastMessage
                          id={t.id}
                          title={'Only collaborators can add tasks'}
                        />
                      ));
                      return;
                    }
                    handleCreateTask();
                  }}
                />
              </Tabs.Content>

              <Tabs.Content className="grow h-[100%]" value={'kanbanView'}>
                {/* WIP kanban view */}
                <div className="w-full flex flex-col gap-y-6 pb-8">
                  {Array.from({ length: 3 }).map((_, index) => (
                    // view board
                    <div
                      key={index}
                      className="w-full h-full bg-[var(--backgrounds-canvas)] rounded-[8px] p-2 gap-y-3 flex flex-col items-center"
                    >
                      {/* view board top */}
                      <div className="w-full h-[40px] flex justify-between items-center">
                        <div className="flex justify-start items-center gap-x-2 w-max pl-3">
                          <div className="h-[6px] w-[6px] bg-[var(--icon-primary)] rounded-full" />
                          <h3 className="whitespace-nowrap text-body-micro medium text-[var(--text-default)] font-bold uppercase">
                            Not started
                          </h3>
                          <div className="flex items-center justify-center w-[20px] h-[20px] bg-[var(--backgrounds-default)] rounded-[6px] text-body-micro text-[var(--text-on-bg)]">
                            2
                          </div>
                        </div>
                        <button className="w-[28px] h-[28px] rounded-1 text-body-small regular text-[var(--text-disabled)]">
                          <Plus className="h-4 w-4" />
                        </button>
                      </div>
                      {Array.from({ length: 3 }).map((_, index) => (
                        <TaskCard key={index} cardType={'kanbanView'} />
                      ))}
                    </div>
                  ))}
                </div>
              </Tabs.Content>
            </div>
          </Tabs.Root>
        </SectionWrap>

        <AnimatePresence mode="wait">
          {taskModal.showModal && (
            <TaskDetailFlyout
              taskDetails={taskModal.details}
              openTaskModal={taskModal.showModal}
              closeTaskModal={closeTaskDetails}
              collaborators={collaborators}
              workStreams={workStreams}
            />
          )}
        </AnimatePresence>

        <DeleteTaskModal
          showModal={deleteConfirmation.showModal}
          closeModal={closeDeleteConfirmation}
          confirmDelete={confirmDeleteTask}
          isDeleting={isDeleting}
        />
      </Fragment>
    );
  }

  return (
    <SectionWrap className="h-full flex flex-col gap-4 !rounded-[0_0_8px_8px]">
      <div className="text-center flex justify-center flex-col items-center space-y-6 h-full pb-[92px]">
        <IconPen className="w-8 h-8" />
        <div className="space-y-2 flex flex-col">
          <div className="text-heading-6 medium text-[var(--text-default)]">
            No tasks yet
          </div>
          <p className="max-w-64 text-body-small regular text-[var(--text-neutral)]">
            Create and assign tasks to yourself or your teammates.
          </p>
        </div>
        <Button
          size="medium"
          btnType="primary"
          icon="left"
          value={'Create a task'}
          className={'w-auto'}
          onClick={() => {
            if (!userCollaborates) {
              toast((t) => (
                <ToastMessage
                  id={t.id}
                  title={'You are not a collaborator of this project'}
                />
              ));
              return;
            }
            handleCreateTask();
          }}
        >
          <Plus />
        </Button>
      </div>
    </SectionWrap>
  );
}

export default TasksArena;
