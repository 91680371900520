import { useMemo } from 'react';
import { ITask, TaskStatus, TTaskStatus } from 'src/types/entities';
import TaskBoard from './TaskBoard';

interface ITaskViewProps {
  tasks: ITask[] | undefined;
  onCreateTask: () => void;
}

const TaskListView = (props: ITaskViewProps) => {
  const { tasks, onCreateTask } = props;

  const tasksByStatus = useMemo(() => {
    if (!tasks) return {};
    return tasks.reduce((acc: { [x: string]: ITask[] }, task: ITask) => {
      if (!acc[task.status]) {
        acc[task.status] = [];
      }
      acc[task.status].push(task);
      return acc;
    }, {});
  }, [tasks]);

  const renderTaskBoard = (status: TTaskStatus) => (
    <TaskBoard
      key={status}
      taskStatus={status}
      tasks={tasksByStatus[status] || []}
      onCreateTask={onCreateTask}
    />
  );

  return (
    <div className="w-full flex flex-col gap-y-6 pb-8">
      {renderTaskBoard(TaskStatus.NOT_STARTED)}
      {renderTaskBoard(TaskStatus.IN_PROGRESS)}
      {renderTaskBoard(TaskStatus.COMPLETED)}
    </div>
  );
};

export default TaskListView;
