import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import PageLoader from 'src/components/ui/Loader/PageLoader';
import { useUser } from 'src/context/userContext';

function HomePage() {
  const navigate = useNavigate();
  const { user, loading, userSetupComplete } = useUser();

  useEffect(() => {
    if (loading) return;

    if (!user) {
      navigate('/auth/login');
      return;
    }

    const userDetails = user.detail;
    const suggestedWorkspaces = user.suggestedWorkspaces;
    const userWorkspaces = user.userWorkspaces;

    if (!userDetails || !userDetails.fullName || !userDetails.position) {
      navigate('/profile-setup');
      return;
    }

    if (userDetails.resettingPassword) {
      navigate('/new-password');
      return;
    }

    if (suggestedWorkspaces?.length && !userWorkspaces?.length) {
      navigate('/join-workspace');
      return;
    }

    if (!userWorkspaces?.length) {
      navigate('/create-workspace');
      return;
    }

    const activeWorkspace = userWorkspaces[0]?.workspace;

    if (!userSetupComplete) {
      navigate(`/workspace/${activeWorkspace.slug}/get-started`);
      return;
    }

    navigate(`/workspace/${activeWorkspace.slug}/project`);
  }, [user, loading, userSetupComplete, navigate]);

  if (loading) return <PageLoader />;

  return null;
}

export default HomePage;
