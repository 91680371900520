import { CKEditor } from '@ckeditor/ckeditor5-react';
import { yupResolver } from '@hookform/resolvers/yup';
import BalloonEditor from 'ckeditor5-custom-build';
import { AnimatePresence, motion } from 'framer-motion';
import { Fragment, useEffect, useRef, useState } from 'react';
import { XCloseDelete } from 'react-basicons';
import { Controller, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useCreateOnePost, useUpdateOnePost } from 'src/api/post/post.service';
import {
  Attachment,
  Close,
  IconProjects,
  MegaPhone,
  Plus,
  WorkStream,
} from 'src/assets/icons';
import CustomMentionItem, {
  type IMentionFeed,
} from 'src/components/common/editor/CustomMentionItem';
import Spinner from 'src/components/ui/spinner/bar-spinner/Spinner';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import { useImageUploader } from 'src/hooks/useImageUploader';
import usePrevious from 'src/hooks/usePrevious';
import { colorStyles } from 'src/lib/colorPreset';
import {
  deleteTypeNameKey,
  getColorTag,
  theme,
  transformToTitleCase,
} from 'src/lib/helpers';
import { IMedia, PostType } from 'src/lib/types';
import { IPost, IProject, IUser } from 'src/types';
import * as yup from 'yup';
import ToastMessage from '../../ToastMessage';
import Button from '../../ui/Button/Button';
import IconButton from '../../ui/Button/IconButton';
import HeaderText from '../../ui/HeaderTexts';
import CustomSelect from '../CustomSelect';

interface PostModalProps {
  show: boolean;
  handleClose: () => void;
  initialPost?: IPost;
  collaborators?: IUser[];
}

const postMutationSchema = yup.object().shape({
  projectId: yup.string().optional().default(''), //temporary (should be required)
  title: yup.string().optional().default(''),
  description: yup.string().required('Post description is required'),
  workstreamId: yup.string().required('Workstream is required'),
  postType: yup
    .string()
    .oneOf(Object.values(PostType))
    .required('Post type is required'),
});

type IPostInput = yup.InferType<typeof postMutationSchema>;

const PostModal: React.FC<PostModalProps> = ({
  show,
  handleClose,
  initialPost,
  collaborators = [],
}) => {
  const {
    workspaceProjects,
    workspaceWorkstreams,
    handleCreateWorkstream,
    isCreatingWorkstream,
  } = useWorkspaceContext();

  const { projectId } = useParams<{ projectId: string }>();
  const fileInputRef = useRef<HTMLInputElement | null>(null);

  const [mentionedUserIDs, setMentionedUserIDs] = useState<any[]>([]);
  const [attachments, setAttachments] = useState<Array<IMedia>>([]);

  const [createOnePost, { loading: isCreatingPost }] = useCreateOnePost();
  const [updateOnePost, { loading: isUpdatingPost }] = useUpdateOnePost();

  const { images, setImages, handleFileChange, removeImage } =
    useImageUploader();

  const loading = isCreatingPost || isUpdatingPost;
  const prevAttachment = usePrevious(attachments);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isValid, isDirty },
  } = useForm({
    defaultValues: {
      projectId: projectId ?? workspaceProjects?.[0]?.id ?? '',
      title: initialPost?.title ?? '',
      description: initialPost?.comment ?? '',
      workstreamId:
        initialPost?.topic?.id ?? workspaceWorkstreams?.[0]?.id ?? '',
      postType: initialPost?.type ?? PostType.GENERAL,
    },
    resolver: yupResolver(postMutationSchema),
  });

  const handleFileInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(e.target.files);
    if (e.target) e.target.value = '';
  };

  const isUploadingImages = images.some((image) => image.uploading);

  const onSubmit = async (data: IPostInput) => {
    const uploadedImages = images
      .filter((image) => !image.uploading && !image.error)
      .map((image) => ({
        url: image.dataURL,
        filename: image.file.name,
        mimetype: image.file.type,
      }));

    if (initialPost) {
      updateOnePost({
        variables: {
          data: {
            title: { set: data.title },
            comment: { set: data.description },
            topic: data.workstreamId
              ? {
                  connect: { id: data.workstreamId },
                }
              : undefined,
            type: { set: data.postType },
            attachments: [...attachments, ...uploadedImages],
            mentionedUsers: {
              set: mentionedUserIDs.map((id) => ({ id })),
            },
          },
          where: { id: initialPost.id },
        },
        onCompleted: () => {
          toast((t) => (
            <ToastMessage id={t.id} title="Post edited successfully" />
          ));
          handleClose();
        },
        onError: (error: any) => {
          toast((t) => <ToastMessage id={t.id} title="Error editing post" />);
        },
      });
    } else {
      createOnePost({
        variables: {
          data: {
            title: data.title,
            comment: data.description,
            topic: data.workstreamId
              ? {
                  connect: { id: data.workstreamId },
                }
              : undefined,
            type: data.postType,
            project: {
              connect: data.projectId
                ? { id: data.projectId }
                : { id: projectId },
            },
            attachments: uploadedImages,
            mentionedUsers: {
              connect: mentionedUserIDs.map((id) => ({ id })),
            },
          },
        },
        onCompleted: () => {
          handleClose();
          toast((t) => (
            <ToastMessage id={t.id} title="Post created successfully" />
          ));
          reset();
        },
        onError: (error: any) => {
          toast((t) => (
            <ToastMessage id={t.id} title="Error creating post">
              {error.message}
            </ToastMessage>
          ));
        },
      });
    }
  };

  const handleRemoveAttachment = (index: number) => {
    setAttachments((prev) => prev.filter((_, i) => i !== index));
  };

  const mentionFeed: IMentionFeed[] = collaborators?.map((collaborator) => ({
    id: `@${collaborator.detail?.fullName ?? collaborator.email}`,
    userId: collaborator.id,
    name: collaborator.detail?.fullName,
    position: transformToTitleCase(collaborator.detail?.position),
    avatar: collaborator.detail.avatar,
  }));

  const editorConfig: any = {
    placeholder: 'Type your post here',
    mention: {
      feeds: [
        {
          marker: '@',
          feed: mentionFeed,
          itemRenderer: CustomMentionItem,
          minimumCharacters: 0,
        },
      ],
    },
  };

  const handleEditorChange = (editor: any) => {
    const mentions: string[] = [];
    const model = editor.model;
    const documentRoot = model.document.getRoot();

    if (documentRoot) {
      const range = model.createRangeIn(documentRoot);
      for (const item of range.getItems()) {
        if (item.hasAttribute('mention')) {
          const mention = item.getAttribute('mention');
          if (mention && mention.userId) {
            mentions.push(mention.userId);
          }
        }
      }
    }

    setMentionedUserIDs(mentions);
  };

  useEffect(() => {
    if (initialPost) {
      reset({
        projectId: initialPost.project.id,
        title: initialPost.title,
        description: initialPost.comment,
        workstreamId: initialPost.topic.id,
        postType: initialPost.type,
      });
    }
    setAttachments(initialPost?.attachments?.map(deleteTypeNameKey) || []);
    setMentionedUserIDs(initialPost?.mentionedUserIDs || []);
  }, [initialPost, reset]);

  const title = initialPost ? 'Edit Post' : 'What would you like to share?';
  const button = initialPost ? 'Save' : 'Post';

  return (
    <Fragment>
      <AnimatePresence>
        {show && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="fixed z-20 inset-0 bg-black/30"
          >
            <div className="fixed inset-0 flex w-screen items-start mt-16 justify-center p-4">
              <motion.div
                initial={{ opacity: 0, scale: 0.95 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0, scale: 0.95 }}
                className="group-container border rounded-xl border-[var(--border-on-bg)] w-full bg-[var(--backgrounds-dropdowns)] max-w-[528px] shadow-[0px_20px_25px_0px_rgba(42,_42,_42,_0.20)]"
              >
                <form onSubmit={handleSubmit(onSubmit)} className="w-full">
                  <div className="flex items-center justify-between w-full p-5 border-b border-[var(--border-divider)]">
                    <HeaderText subTitle={title} />
                    <IconButton
                      icon={<Close />}
                      size={'micro'}
                      type={'ghost'}
                      onClick={() => {
                        handleClose();
                        if (!initialPost) {
                          reset();
                          setImages([]);
                        }
                      }}
                    />
                  </div>
                  <div className="p-5 max-h-[500px] overflow-y-auto">
                    <div className={`w-fit mb-6`}>
                      <Controller
                        name="projectId"
                        control={control}
                        disabled={!!initialPost}
                        render={({ field }) => (
                          <CustomSelect
                            {...field}
                            value={field.value}
                            onChange={field.onChange}
                            placeholder="Select project"
                            triggerIcon={<IconProjects className="w-4 h-4" />}
                            triggerButtonSize="large"
                            contentClassName="w-[200px]"
                            options={
                              workspaceProjects?.map((project: IProject) => ({
                                label: project.name,
                                value: project.id,
                              })) || []
                            }
                            customOptionItem={(option) => (
                              <div className="flex items-center gap-x-2">
                                <div
                                  className={
                                    'flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold'
                                  }
                                  style={
                                    colorStyles[getColorTag(option?.label)]
                                  }
                                >
                                  {option?.label[0]}
                                </div>
                                <div className="text-body-small medium text-[var(--text-neutral)] max-w-[130px] truncate">
                                  {option?.label}
                                </div>
                              </div>
                            )}
                            searchPlaceholder="Search projects"
                          />
                        )}
                      />
                    </div>

                    <Controller
                      name="title"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          disabled={loading}
                          autoFocus
                          placeholder="Add a title"
                          {...field}
                          value={field.value}
                          className="input-clear bg-transparent text-heading-4 medium text-[var(--text-default)] !border-[var(--backgrounds-dropdowns)] !outline-[var(--backgrounds-dropdowns)] !focus-visible:outline-none !outline-none w-full text-2xl placeholder:text-[var(--text-disabled)] placeholder:text-heading-4 placeholder:medium"
                        />
                      )}
                    />

                    <div className="w-full">
                      <Controller
                        name="description"
                        control={control}
                        render={({ field }) => (
                          <CKEditor
                            editor={BalloonEditor}
                            data={field.value}
                            config={editorConfig}
                            onChange={(event, editor) => {
                              field.onChange(editor.getData());
                              handleEditorChange(editor);
                            }}
                          />
                        )}
                      />
                      <div className="text-xs text-red-500/75 font-medium mt-1">
                        {errors.description?.message}
                      </div>
                    </div>

                    {(images.length > 0 || attachments.length > 0) && (
                      <ul className="flex items-center space-x-2 mt-6">
                        {attachments.map((media, index) => (
                          <li
                            key={index}
                            className="aspect-square relative flex-1 max-h-[300px] overflow-hidden"
                          >
                            <img
                              className="aspect-square bg-grey-100 rounded-lg object-cover"
                              src={media.url}
                              alt={media.filename}
                            />
                            <button
                              type="button"
                              disabled={loading}
                              className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                              onClick={() => handleRemoveAttachment(index)}
                            >
                              <XCloseDelete
                                size={8}
                                color={theme.colors.white}
                                weight={3}
                              />
                            </button>
                          </li>
                        ))}

                        {images.map((image, index) => (
                          <li
                            key={index}
                            className="aspect-square relative flex-1 max-h-[300px] overflow-hidden"
                          >
                            <img
                              src={image.dataURL}
                              alt={`uploaded img-${index}`}
                              className="aspect-square bg-grey-100 rounded-lg object-cover"
                            />
                            {image.uploading && (
                              <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50 rounded-lg">
                                <Spinner />
                              </div>
                            )}
                            {image.error && (
                              <div className="absolute inset-0 flex items-center justify-center bg-red-500 bg-opacity-50 rounded-lg">
                                <span className="text-[var(--text-on-bg)] text-sm font-medium">
                                  Upload failed
                                </span>
                              </div>
                            )}
                            <button
                              type="button"
                              disabled={loading || image.uploading}
                              className="absolute top-1.5 right-1.5 bg-[#0B032DB2] w-4 aspect-square rounded-full grid place-content-center"
                              onClick={() => removeImage(index)}
                            >
                              <XCloseDelete
                                size={8}
                                color={theme.colors.white}
                                weight={3}
                              />
                            </button>
                          </li>
                        ))}
                        {images.length + attachments.length < 4 && (
                          <li>
                            <IconButton
                              icon={<Plus />}
                              size={'standard'}
                              type={'neutral'}
                              rounded="standard"
                              onClick={() => fileInputRef.current?.click()}
                            />
                          </li>
                        )}
                      </ul>
                    )}
                  </div>

                  <div className="p-5 border-t border-[var(--border-divider)]">
                    <div className="flex gap-4 flex-col md:flex-row items-start md:justify-between md:items-center">
                      <ul className="flex items-start w-full md:w-auto md:items-center flex-col md:flex-row flex-wrap gap-3">
                        <li className="w-full md:w-fit">
                          <Controller
                            name="workstreamId"
                            control={control}
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Select workstream"
                                triggerIcon={<WorkStream className="w-4 h-4" />}
                                triggerButtonSize="large"
                                contentClassName="w-[200px]"
                                options={
                                  workspaceWorkstreams?.map(
                                    (workstream: any) => ({
                                      label: workstream.name,
                                      value: workstream.id,
                                    })
                                  ) || []
                                }
                                addOptionProps={{
                                  onAddOption: handleCreateWorkstream,
                                  buttonText: 'Create workstream',
                                  buttonLoading: isCreatingWorkstream,
                                }}
                                searchPlaceholder="Search workstream"
                              />
                            )}
                          />
                        </li>
                        <li className="w-full  md:w-fit">
                          <Controller
                            name="postType"
                            control={control}
                            render={({ field }) => (
                              <CustomSelect
                                {...field}
                                value={field.value}
                                onChange={field.onChange}
                                placeholder="Select type"
                                triggerIcon={<MegaPhone className="w-4 h-4" />}
                                triggerButtonSize="large"
                                contentClassName="w-[200px]"
                                options={
                                  Object.values(PostType)?.map(
                                    (type: PostType) => ({
                                      label: transformToTitleCase(type),
                                      value: type,
                                    })
                                  ) || []
                                }
                                renderSearch={false}
                              />
                            )}
                          />
                        </li>
                      </ul>
                      <div className="ml-auto gap-3 items-center flex">
                        <input
                          ref={fileInputRef}
                          type="file"
                          accept="image/*"
                          multiple
                          className="sr-only"
                          onChange={handleFileInputChange}
                        />
                        <div>
                          <IconButton
                            icon={<Attachment />}
                            size={'standard'}
                            type={'neutral'}
                            rounded="standard"
                            onClick={() => fileInputRef.current?.click()}
                          />
                        </div>
                        <Button
                          type="submit"
                          loading={loading}
                          disabled={
                            loading || isUploadingImages || !isValid || !isDirty
                            // prevAttachment === attachments
                          }
                          value={button}
                          size={'medium'}
                          btnType={'primary'}
                        />
                      </div>
                    </div>
                  </div>
                </form>
              </motion.div>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </Fragment>
  );
};

export default PostModal;
