import { useLocalStorage } from '@uidotdev/usehooks';
import { Fragment, useEffect, useMemo, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  useArchiveOneProject,
  useDeleteOneProject,
  useProject,
  useRestoreOneProject,
} from 'src/api/project/project.service';

import { ArrowBack, IconPen, MegaPhone, Menu } from 'src/assets/icons';
import DeleteProjectModal from 'src/components/projects/DeleteProjectModal';
import ProjectSidePanel from 'src/components/projects/ProjectSidePanel';
import IconButton from 'src/components/ui/Button/IconButton';

import PageLoader from 'src/components/ui/Loader/PageLoader';
import { useUser } from 'src/context/userContext';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import { colorStyles } from 'src/lib/colorPreset';
import ProjectModal from '../components/projects/ProjectModal';
import ProjectPageHeader from '../components/projects/ProjectPageHeader';
import ToastMessage from '../components/ToastMessage';
import { getColorTag } from '../lib/helpers';

function ProjectPage() {
  const { projectId, workspaceSlug } = useParams<{
    projectId: string;
    workspaceSlug: string;
  }>();
  const navigate = useNavigate();
  const location = useLocation();
  const [userId] = useLocalStorage('userId', undefined);
  const ROOT_URL = `/workspace/${workspaceSlug}/project`;
  const baseUrl = `${ROOT_URL}/${projectId}`;

  const { user } = useUser();
  const { workspaceProjects } = useWorkspaceContext();

  const [archiveOneProject, { loading: isArchiving }] = useArchiveOneProject();
  const [deleteOneProject, { loading: isDeleting }] = useDeleteOneProject();
  const [restoreOneProject] = useRestoreOneProject();

  const queryVariables = useMemo(
    () => ({
      where: {
        id: { equals: projectId },
        workspace: {
          slug: { equals: workspaceSlug },
        },
      },
    }),
    [projectId, workspaceSlug]
  );

  const { loading, data } = useProject({
    variables: queryVariables,
    skip: !projectId || !workspaceSlug,
    onError: (error: any) => {
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={`${error.message}: Redirecting to workspace projects page...`}
        />
      ));
      window.location.href = ROOT_URL;
    },
  });

  const [state, setState] = useState({
    deleteProject: false,
    editProject: false,
    editPost: false,
    openPanel: false,
    funcType: undefined as 'archive' | 'delete' | undefined,
  });

  const headingContainerRef = useRef<HTMLDivElement | null>(null);
  const [heightHeadingContainerRef, setHeightHeadingContainerRef] = useState(0);

  const handleCopyLink = () => {
    if (data?.project) {
      const origin = window.location.origin;
      const link = `${origin}/workspace/${data.project.workspace.slug}/project/${data.project.id}`;
      navigator.clipboard
        .writeText(link)
        .then(() => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title="Link to post is copied to clipboard"
            />
          ));
        })
        .catch(() => {
          toast((t) => (
            <ToastMessage id={t.id} title="Error copying text to clipboard" />
          ));
        });
    }
  };

  const handleArchiveProject = () => {
    archiveOneProject({
      variables: { where: { id: data.project.id } },
      onCompleted: (data) => {
        setState({ ...state, deleteProject: false });
        archiveOrDeleteNavigation();
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title="You can access archived projects from 'Settings'."
              buttonText={'Undo'}
              buttonAction={() => {
                toast.dismiss(t.id);
                restoreOneProject({
                  variables: { where: { id: data.archiveOneProject.id } },
                });
              }}
            />
          ),
          { duration: 5000 }
        );
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="There was an error archiving this project"
          />
        ));
      },
    });
  };

  const handleDeleteProject = () => {
    deleteOneProject({
      variables: { where: { id: data.project.id } },
      onCompleted: (data) => {
        setState({ ...state, deleteProject: false });
        archiveOrDeleteNavigation();
        toast(
          (t) => (
            <ToastMessage
              id={t.id}
              title={`This project has been deleted.`}
              buttonText={'Restore'}
              buttonAction={() => {
                toast.dismiss(t.id);
                restoreOneProject({
                  variables: { where: { id: data.deleteOneProject.id } },
                });
              }}
            />
          ),
          { duration: 5000 }
        );
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="There was an error deleting this project"
          />
        ));
      },
    });
  };

  const archiveOrDeleteNavigation = () => {
    if (workspaceProjects?.length === 1) {
      window.location.href = ROOT_URL;
    } else {
      const nextProject = workspaceProjects?.find(
        (p) => p.id !== data.project.id
      );
      console.log('nextProject', nextProject);
      if (nextProject) {
        navigate(`${ROOT_URL}/${nextProject.id}`, {
          replace: true,
        });
      } else {
        window.location.href = ROOT_URL;
      }
    }
  };

  useEffect(() => {
    const updateHeight = () => {
      if (headingContainerRef.current?.offsetHeight) {
        setHeightHeadingContainerRef(headingContainerRef.current.offsetHeight);
      }
    };
    updateHeight();
    window.addEventListener('resize', updateHeight);
    return () => window.removeEventListener('resize', updateHeight);
  }, [headingContainerRef.current?.offsetHeight, data?.project]);

  useEffect(() => {
    if (location.pathname === baseUrl) {
      navigate(`${baseUrl}/posts`, { replace: true });
    }
  }, [location, navigate, baseUrl]);

  const isPostsTab = location.pathname.includes('/posts');
  const isTasksTab = location.pathname.includes('/tasks');

  if (loading) {
    return <PageLoader />;
  }

  return (
    <Fragment>
      <div className="page-title z-10 flex items-center justify-between md:hidden h-14 ">
        <IconButton
          icon={<ArrowBack />}
          size={'small'}
          type={'ghost'}
          onClick={() => {
            navigate(-1);
          }}
        />
        <div className="flex items-center gap-2 capitalize">
          <div>
            <div
              className="flex items-center justify-center aspect-square h-5 w-5 rounded uppercase text-body-micro bold"
              style={colorStyles[getColorTag(data?.project.name)]}
            >
              {data.project.name[0]}
            </div>
          </div>
          <p className="text-body-small bold text-[var(--text-neutral)]">
            {data.project.name}
          </p>
        </div>
        <IconButton
          icon={<Menu />}
          size={'small'}
          type={'ghost'}
          onClick={() => setState({ ...state, openPanel: !state.openPanel })}
        />
      </div>
      <div className="w-full flex flex-col h-[100%] md:h-full mt-16 md:mt-0 rounded-lg overflow-hidden">
        {/* Header Section */}
        <div ref={headingContainerRef}>
          {data?.project && userId && (
            <ProjectPageHeader
              userId={userId}
              project={data.project}
              handleEdit={() => setState({ ...state, editProject: true })}
              handleDelete={() => setState({ ...state, deleteProject: true })}
              handleArchive={handleArchiveProject}
              handleCopyLink={handleCopyLink}
            >
              <div
                className="w-max flex items-center gap-x-2 rounded-lg bg-[var(--backgrounds-default)] min-h-[32px] p-[2px]"
                aria-label="Workspace project page"
              >
                <button
                  className={`p-[0px_16px_0px_16px] min-h-[28px] flex-1 flex items-center justify-center !gap-x-2 text-[var(--text-neutral)] leading-5 select-none outline-none cursor-pointer ${
                    isPostsTab
                      ? 'bg-[var(--backgrounds-on-bg)] text-[var(--text-default)]'
                      : ''
                  } rounded-md text-body-small medium`}
                  onClick={() => navigate(`${baseUrl}/posts`)}
                >
                  <MegaPhone className="h-4 w-4" />
                  <span>Posts</span>
                </button>
                <button
                  className={`p-[0px_16px_0px_16px] min-h-[28px] flex-1 flex items-center justify-center !gap-x-2 text-[var(--text-neutral)] leading-5 select-none outline-none cursor-pointer ${
                    isTasksTab
                      ? 'bg-[var(--backgrounds-on-bg)] text-[var(--text-default)]'
                      : ''
                  } rounded-md text-body-small medium`}
                  onClick={() => navigate(`${baseUrl}/tasks`)}
                >
                  <IconPen className="h-4 w-4" />
                  <span>Tasks</span>
                </button>
              </div>
            </ProjectPageHeader>
          )}
        </div>

        <Outlet
          context={{
            projectId,
            workspaceSlug,
            projectData: data.project,
            myData: user,
            heightHeadingContainerRef,
          }}
        />
      </div>

      <ProjectSidePanel
        userId={userId}
        project={data.project}
        projectId={projectId as string}
        workspaceSlug={workspaceSlug as string}
        state={state}
        setState={setState}
      />

      <ProjectModal
        show={state.editProject}
        project={data?.project}
        handleClose={() => setState({ ...state, editProject: false })}
      />

      <DeleteProjectModal
        show={state.deleteProject}
        handleClose={() => setState({ ...state, deleteProject: false })}
        handlePreArchive={handleArchiveProject}
        handlePreDelete={handleDeleteProject}
        archivingProject={isArchiving}
        deletingProject={isDeleting}
      />
    </Fragment>
  );
}

export default ProjectPage;
