import React, { useEffect } from 'react';

import toast from 'react-hot-toast';
import { useParams } from 'react-router-dom';
import { useUpdateOneWorkspaceLogo } from 'src/api/workspace/useUpdateOneWorkspaceLogo';
import { Plus } from 'src/assets/icons';
import { useImageUploader } from 'src/hooks/useImageUploader';
import { getInitials } from 'src/lib/helpers';
import { IMedia } from 'src/lib/types';
import ToastMessage from '../ToastMessage';
import DefaultAvatar from './Avatar/DefaultAvatar';
import Spinner from './spinner/bar-spinner/Spinner';

interface LogoProps {
  name: string | null | undefined;
  userId: string | undefined;
  size: number;
  fontSize: number;
  logo: IMedia | null | undefined;
}

const Logo: React.FC<LogoProps> = ({ userId, name, size, fontSize, logo }) => {
  const [updateOneWorkspaceLogo] = useUpdateOneWorkspaceLogo();
  const { images, handleFileChange } = useImageUploader({
    maxFiles: 1,
  });
  const { workspaceSlug } = useParams<{ workspaceSlug: string }>();

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleFileChange(event.target.files);
    if (event.target) event.target.value = '';
  };

  async function updateWorkspaceLogo(uploadedImage: any) {
    try {
      await updateOneWorkspaceLogo({
        variables: {
          data: {
            logo: {
              set: {
                filename: uploadedImage.file.name,
                mimetype: uploadedImage.file.type,
                url: uploadedImage.dataURL,
              },
            },
          },
          where: {
            slug: workspaceSlug,
          },
        },
      });
      toast((t) => (
        <ToastMessage id={t.id} title="Workspace logo updated successfully" />
      ));
    } catch (error: any) {
      toast((t) => (
        <ToastMessage id={t.id} title={`Error: ${error.message}`} />
      ));
    }
  }

  useEffect(() => {
    if (images.length > 0 && !images[0].uploading && !images[0].error) {
      updateWorkspaceLogo(images[0]);
    }
  }, [images]);

  return (
    <div
      className="image-uploader relative overflow-hidden rounded object-cover"
      style={{ '--size': `${size}px` } as React.CSSProperties}
    >
      <label htmlFor="file-upload" className="upload-label">
        <div className="default_state avatar_wrap relative">
          <div className="image-overlay absolute flex items-center justify-center top-2/4 left-1/2 w-full h-full bg-opacity-65 bg-black">
            <Plus />
          </div>

          {images.length > 0 && images[0].uploading && (
            <div className="absolute inset-0 flex items-center justify-center bg-black bg-opacity-50">
              <Spinner />
            </div>
          )}

          {images.length < 1 && !logo ? (
            <DefaultAvatar
              size={size}
              name={name as string}
              fontSize={fontSize}
              fontWeight={600}
              customInitial={getInitials(name as string)}
            />
          ) : (
            <img
              src={images.length > 0 ? images[0].dataURL : `${logo?.url}`}
              alt={name as string}
              className="uploaded-image"
            />
          )}
        </div>
        <input
          className="hidden"
          id="file-upload"
          type="file"
          accept="image/*"
          onChange={handleImageUpload}
        />
      </label>
    </div>
  );
};

export default Logo;
