import { useLocalStorage } from '@uidotdev/usehooks';
import { Navigate, Outlet } from 'react-router-dom';
import { UserProvider } from 'src/context/userContext';
import PageLoader from './ui/Loader/PageLoader';

function ProtectedRoute() {
  const [accessToken] = useLocalStorage('accessToken', undefined);

  if (accessToken === 'undefined') {
    return <PageLoader />;
  }

  if (!accessToken) {
    return <Navigate to="/auth/login" replace />;
  }

  return (
    <UserProvider>
      <Outlet />
    </UserProvider>
  );
}

export default ProtectedRoute;
