import { Outlet } from 'react-router-dom';

export default function AuthLayout() {
  return (
    <div className="flex min-h-screen w-full">
      <div className="outlet m-0 rounded-none !overflow-hidden h-screen flex justify-center items-baseline !bg-[var(--backgrounds-canvas)] md:h-[calc(100vh-16px)] md:m-2 md:rounded-lg md:!items-center">
        <div className="mt-16 w-full h-fit md:mt-0 flex flex-col items-center">
          <main className="flex flex-col max-w-[500px] items-center gap-14 h-full p-4 w-full">
            <Outlet />
          </main>
        </div>
      </div>
    </div>
  );
}
