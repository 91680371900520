import { PropsWithChildren } from 'react';
import { cn } from 'src/lib/utils';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/Popover';

interface IDatePickerPopoverProps extends PropsWithChildren<{}> {
  contentClassName?: string;
  triggerClassName?: string;
  disabled?: boolean;
  onOpenChange?: (isOpen: boolean) => void;
  triggerChildren: React.ReactNode;
  side?: 'top' | 'right' | 'bottom' | 'left';
  align?: 'start' | 'center' | 'end';
}

const ProjectDetailPopover = (props: IDatePickerPopoverProps) => {
  const {
    triggerClassName,
    contentClassName,
    disabled,
    onOpenChange,
    triggerChildren,
    children,
  } = props;

  return (
    <Popover onOpenChange={onOpenChange}>
      <PopoverTrigger
        disabled={disabled}
        className={cn('disabled:opacity-50 w-fit', triggerClassName)}
      >
        {triggerChildren}
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'min-w-[200px] flex flex-col items-center gap-y-1 border-[var(--border-on-bg)] rounded-md p-1 max-h-[300px] overflow-y-auto',
          contentClassName
        )}
        sideOffset={8}
        {...props}
      >
        {children}
      </PopoverContent>
    </Popover>
  );
};

export default ProjectDetailPopover;
