import { Fragment, useEffect, useMemo, useState } from 'react';
import { usePositionEnum } from 'src/api/usePositionEnum';
import Button from 'src/components/ui/Button/Button';
import Input from 'src/components/ui/inputs/Default';
import SelectInput from 'src/components/ui/inputs/Select';
import { convertCamelToText } from 'src/lib/utils';

interface ProfileDetailProps {
  name: string;
  userRole: string;
  setUserRole: (arg: RoleData) => void;
  setUserName: (name: string) => void;
  handleUpdateUserNameAndRole: () => void;
  loading: boolean;
}

interface RoleData {
  name: string;
  value: string;
}

export default function ProfileDetail({
  name,
  userRole,
  setUserName,
  setUserRole,
  handleUpdateUserNameAndRole,
  loading,
}: ProfileDetailProps) {
  const [selectModal, setSelectModal] = useState(false);
  const [role, setRole] = useState('');

  // setting up all user roles with the position enums
  const positionEnum = usePositionEnum();
  const formattedRoles = useMemo(() => {
    const positionEnumValues = positionEnum.data?.__type.enumValues ?? [];
    return positionEnumValues.map((item: { name: string }) => ({
      name: convertCamelToText(item.name),
      value: item.name,
    }));
  }, [positionEnum.data]);

  useEffect(() => {
    const role = formattedRoles.find(
      (enumValues: RoleData) => enumValues.value === userRole
    );
    setRole(role?.name ?? '');
  }, [userRole, formattedRoles]);

  function handleSelectChange(arg: RoleData) {
    setSelectModal(false);
    setUserRole(arg);
  }

  return (
    <Fragment>
      <Input
        type="text"
        value={name}
        feedback=""
        label="Full name"
        placeholder="Enter your full name"
        onChange={(e) => setUserName(e.target.value)}
      />
      <SelectInput
        showDropGroup={selectModal}
        value={role}
        style={{ maxHeight: '210px' }}
        data={formattedRoles}
        placeholder="Enter your role"
        onClick={() => setSelectModal(!selectModal)}
        onItemClick={handleSelectChange}
        clickOutside={() => setSelectModal(false)}
      />
      <div className="self-end">
        <Button
          btnType="primary"
          value="Update"
          size="medium"
          className="w-auto"
          loading={loading}
          onClick={handleUpdateUserNameAndRole}
          disabled={loading}
        />
      </div>
    </Fragment>
  );
}
