import classNames from 'classnames';
import React, { ChangeEvent, useEffect } from 'react';
import { AlertCirlcleError, CheckGoodYes } from 'react-basicons';
import { theme } from '../lib/helpers';

interface UrlInputProps {
  className?: string;
  domain?: string;
  error?: boolean;
  id: string;
  loading?: boolean;
  onChange: (value: string) => void;
  placeholder?: string;
  scheme?: 'http://' | 'https://';
  success?: boolean;
  value?: string;
  slugOnly?: boolean;
  generateFrom?: string;
  onManualEdit?: () => void;
}

const UrlInput: React.FC<UrlInputProps> = ({
  className,
  domain = '',
  error,
  id,
  loading,
  onChange,
  placeholder,
  scheme = 'https://',
  success,
  value = '',
  slugOnly = false,
  generateFrom,
  onManualEdit,
}) => {
  const cleanUrl = (url: string): string => {
    let cleaned = url.toLowerCase();

    // remove existing scheme
    cleaned = cleaned.replace(/^https?:\/\//, '');

    if (domain) {
      const domainRegex = new RegExp(`^${domain}/?`);
      cleaned = cleaned.replace(domainRegex, '');
    }

    return cleaned;
  };

  const formatFullUrl = (cleanedUrl: string): string => {
    if (!cleanedUrl) return '';
    return `${scheme}${domain}${domain ? '/' : ''}${cleanedUrl}`;
  };

  const cleanSlugValue = (input: string): string => {
    return input
      .toLowerCase()
      .replace(/[^a-z0-9-]/g, '-')
      .replace(/-+/g, '-')
      .replace(/^-|-$/g, '');
  };

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;

    if (onManualEdit && generateFrom !== undefined) {
      onManualEdit();
    }

    const cleanSlugUrl = cleanUrl(cleanSlugValue(inputValue));
    const cleanedUrl = cleanUrl(inputValue);

    onChange(slugOnly ? cleanSlugUrl : formatFullUrl(cleanedUrl));
  };

  useEffect(() => {
    if (generateFrom !== undefined) {
      const generatedSlug = cleanSlugValue(generateFrom);
      if (generatedSlug !== value) {
        onChange(slugOnly ? generatedSlug : formatFullUrl(generatedSlug));
      }
    }
  }, [generateFrom]);

  const displayValue = value ? cleanUrl(value) : '';

  const renderSuffixIcon = () => {
    if (success && value !== '') {
      return (
        <CheckGoodYes
          size={16}
          color={(theme.textColor.green as Record<string, string>)['600']}
          weight={2}
        />
      );
    }

    if (error && value !== '') {
      return (
        <AlertCirlcleError
          size={16}
          color={(theme.textColor.red as Record<string, string>)['500']}
          weight={2}
        />
      );
    }

    if (loading) {
      return (
        <img
          src="/images/CircleNotch.svg"
          className="animate-spin"
          alt="loading..."
        />
      );
    }

    return null;
  };

  return (
    <div className={classNames('link-input relative w-full')}>
      <div className="absolute pointer-events-none text-body-small regular top-0 left-0 flex items-center">
        <label htmlFor={id} className="px-4">
          {scheme}
        </label>
        <div className="v-line w-px h-[38px] mt-[1px] bg-[var(--border-input-default)]" />
        {domain && (
          <label className="pl-4" htmlFor={id}>
            {domain}/
          </label>
        )}
      </div>

      <input
        id={id}
        value={displayValue}
        onChange={handleChange}
        placeholder={placeholder}
        className={classNames(
          'input-clear input w-full placeholder:!lowercase',
          className
        )}
        style={{ paddingLeft: domain ? '188px' : '90px' }}
      />

      {renderSuffixIcon() && (
        <div className="absolute top-3 right-4">{renderSuffixIcon()}</div>
      )}
    </div>
  );
};

export default UrlInput;
