import { useMutation, useQuery } from '@apollo/client';
import { SEND_AUTH_TOKEN } from 'src/lib/mutations';
import { ME } from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useMe(args?: object) {
  const options = useApolloOptions();

  return useQuery(ME, {
    ...options,
    fetchPolicy: 'cache-and-network',
    ...args,
  });
}

export function useSendAuthToken() {
  return useMutation(SEND_AUTH_TOKEN);
}
