import React from 'react';
import { getAvatarColorClass } from 'src/lib/helpers';
import '../ui.css';

interface DefaultAvatarProps {
  name: string | null;
  size: number;
  fontSize: number;
  fontWeight: number;
  customInitial?: string;
}

const DefaultAvatar: React.FC<DefaultAvatarProps> = ({
  name = 'user',
  customInitial,
  size,
  fontSize,
  fontWeight,
}) => {
  const initial = customInitial ?? (name?.charAt(0)?.toUpperCase() || '');
  const colorClass = getAvatarColorClass(initial);

  return (
    <div>
      <div
        className={`avatar_wrap logo_avatar flex items-center justify-center aspect-square cursor-pointer ${colorClass}`}
        style={
          {
            '--size': `${size}px`,
            fontSize: `${fontSize}px`,
            fontWeight: fontWeight,
            // ...colorStyles[getColorTag(name)],
          } as unknown as React.CSSProperties
        }
      >
        <div>{initial}</div>
      </div>
    </div>
  );
};

export default DefaultAvatar;
