import { AnimatePresence, motion } from 'framer-motion';
import Modal from 'src/components/ui/Modals/Modal';

export default function DeleteTaskModal(props: {
  showModal: boolean;
  closeModal: () => void;
  confirmDelete: () => void;
  isDeleting: boolean;
}) {
  const { showModal, closeModal, confirmDelete, isDeleting } = props;

  return (
    <AnimatePresence>
      {showModal && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={showModal}
            data={{
              subtitle: 'You’re about to delete this task',
              snippet:
                'If you change your mind, you can restore it within the next 30 days. After that it will be permanently deleted from your workspace.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            onBtn1Click={closeModal}
            onBtn2Click={confirmDelete}
            loading={isDeleting}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
