import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocalStorage } from '@uidotdev/usehooks';
import BalloonEditor from 'ckeditor5-custom-build';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useSearchParams } from 'react-router-dom';
import DeletePostCommentModal from 'src/components/projects/comments/posts/DeletePostCommentModal';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import Timestamp from 'src/components/ui/TimeStamp';
import { useCommentEdit } from 'src/hooks/project/post/useCommentEditAction';
import { IComment, IUser } from 'src/types';
import PostCommentMenu from './PostCommentMenu';

interface ReplyCommentBodyProps {
  comment: IComment;
  collaborators: IUser[];
}

function ReplyCommentBody({ comment, collaborators }: ReplyCommentBodyProps) {
  const [searchParams] = useSearchParams();

  const [userId] = useLocalStorage('userId', undefined);
  const commentId = searchParams.get('commentId');

  const commentRef = useRef<HTMLDivElement | null>(null);
  const isCommentOwner = userId === comment.author.id;
  const isFocusedComment = commentId === comment.id;

  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);

  const handleCopy = () => {
    const origin = window.location.origin;
    const post = comment.post;
    const project = post.project;
    const workspace = project.workspace;

    const link = `${origin}/workspace/${workspace.slug}/project/${project.id}/posts/${post.id}?commentId=${comment.id}`;
    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title={'Link to comment is copied to clipboard'}
          />
        ));
      })
      .catch((err) => {
        toast((t) => (
          <ToastMessage id={t.id} title={'Error copying link to clipboard'} />
        ));
      });
  };

  const {
    isEditing,
    startEditing,
    cancelEditing,
    control,
    handleSubmit,
    handleEditComment,
    handleEditorChange,
    isUpdating,
    editedComment,
    editorConfig,
  } = useCommentEdit({
    comment,
    collaborators,
    onEditComplete: () => {},
  });

  useEffect(() => {
    if (isFocusedComment && commentRef.current instanceof HTMLElement) {
      const element = commentRef.current;

      element.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
      });

      element.classList.add('bg-[var(--backgrounds-hover-clicked)]');
      setTimeout(() => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      }, 2000);

      return () => {
        element.classList.remove('bg-[var(--backgrounds-hover-clicked)]');
      };
    }
  }, [isFocusedComment]);

  return (
    <div
      ref={commentRef}
      className="duration-1000 flex items-start gap-y-2 flex-col w-full"
    >
      <div className="flex items-center space-x-6 w-full">
        <div className="flex-1 flex gap-1.5 items-center">
          <Avatar
            alt={comment.author.detail?.fullName ?? comment.author.email}
            src={comment.author.detail.avatar?.url}
            size={20}
            name={comment.author.detail?.fullName}
          />
          <div className="flex items-center gap-1.5">
            <div className="text-body-small text-[var(--text-default)] font-medium">
              {comment.author.detail?.fullName ?? comment.author.email}
            </div>
            <div className="h-1 w-1 rounded-full bg-[#8D8D8D]" />

            <Timestamp
              className="text-body-micro text-[var(--text-neutral)] regular"
              createdAt={comment.createdAt}
            />
          </div>
        </div>

        <PostCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          handleCopy={handleCopy}
          handleEdit={startEditing}
          handleDelete={() => setDeleteComment(comment)}
          type={'comment'}
        />
      </div>

      {isEditing ? (
        <div className="space-y-4 w-full">
          <div className="w-full textarea-editor !pl-5 !pr-5">
            <Controller
              name="editedComment"
              control={control}
              render={({ field }) => (
                <CKEditor
                  editor={BalloonEditor}
                  data={field.value}
                  config={editorConfig as any}
                  onChange={(event, editor) => {
                    field.onChange(editor.getData());
                    handleEditorChange(editor);
                  }}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={cancelEditing}
              type="button"
              className={'w-fit'}
              size="small"
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSubmit(handleEditComment)}
              type="button"
              className={'w-fit'}
              size="small"
              loading={isUpdating}
              disabled={isUpdating || editedComment === comment.comment}
              value={'Save'}
              btnType={'primary'}
            />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div
            className={
              'w-full ck ck-content text-body-small regular text-[var(--text-default)]'
            }
          >
            {parse(comment.comment)}
          </div>
        </div>
      )}

      <DeletePostCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
        isReply={true}
        parentCommentId={comment.id as string}
      />
    </div>
  );
}

export default ReplyCommentBody;
