import { gql } from '@apollo/client';
import { AnimatePresence, motion } from 'framer-motion';
import toast from 'react-hot-toast';
import { useDeleteOnePostComment } from 'src/api/comment/post-comment.service';
import ToastMessage from 'src/components/ToastMessage';
import Modal from 'src/components/ui/Modals/Modal';
import { IComment } from 'src/types/entities';

export default function DeletePostCommentModal(props: {
  show: boolean;
  handleClose: Function;
  comment: IComment | null;
  isReply?: boolean | null;
  parentCommentId?: string | null;
}) {
  const { show, handleClose, comment, isReply, parentCommentId } = props;
  const [deleteOnePostComment, { loading }] = useDeleteOnePostComment();

  const handleDelete = () => {
    if (comment) {
      deleteOnePostComment({
        variables: {
          where: {
            id: comment.id,
          },
        },
        onCompleted: (data) => {
          toast((t) => (
            <ToastMessage id={t.id} title="This comment has been deleted" />
          ));
          handleClose();
        },
        update: (cache, { data }) => {
          if (!data?.deleteOnePostComment) {
            console.error('No data returned from deleteOnePostComment');
            return;
          }

          const deletedComment = data.deleteOnePostComment;
          const postId = deletedComment.post.id;
          const cacheId = cache.identify({ __typename: 'Post', id: postId });

          const existingPost = cache.readFragment<{ comments: IComment[] }>({
            id: cacheId,
            fragment: gql`
              fragment ExistingPostWithComments on Post {
                id
                comments {
                  id
                  comment
                  createdAt
                  responses {
                    id
                    comment
                    createdAt
                  }
                }
              }
            `,
          });

          if (!existingPost) {
            console.error('Post not found in cache');
            return;
          }

          let updatedComments;

          if (isReply && parentCommentId) {
            updatedComments = existingPost.comments.map((c) => {
              if (c.id === parentCommentId) {
                return {
                  ...c,
                  responses: c.responses.filter((r) => r.id !== comment.id),
                };
              }
              return c;
            });
          } else {
            updatedComments = existingPost.comments.filter(
              (c) => c.id !== comment.id
            );
          }

          cache.writeFragment({
            id: cacheId,
            fragment: gql`
              fragment UpdatedPostComments on Post {
                comments
              }
            `,
            data: {
              comments: updatedComments,
            },
          });
        },

        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={'There was an error deleting this comment'}
            />
          ));
        },
      });
    }
  };

  return (
    <AnimatePresence>
      {show && (
        <motion.div
          exit={{ y: 10, opacity: 0 }}
          transition={{ duration: 0.5 }}
          className="fixed z-50 w-screen h-screen top-0 left-0 flex flex-col justify-center items-center"
        >
          <Modal
            open={show}
            data={{
              subtitle: 'You’re about to delete this comment',
              snippet:
                'Are you sure you want to delete this comment? You cannot undo this action.',
              btn1: 'Cancel',
              btn2: 'Delete',
              btn1Type: 'neutral',
              btn2Type: 'destructive',
            }}
            onBtn1Click={() => handleClose()}
            onBtn2Click={() => handleDelete()}
            loading={loading}
          />
        </motion.div>
      )}
    </AnimatePresence>
  );
}
