import React, { ReactNode, useEffect, useRef, useState } from 'react';
import './sidenav.css';

const MIN_WIDTH = 244;
const DEFAULT_WIDTH = 250;

export default function DefaultLayout({ children }: { children: ReactNode }) {
  const sideNavRef = useRef<HTMLDivElement>(null);
  const resizerRef = useRef<HTMLDivElement>(null);
  const [width, setWidth] = useState(DEFAULT_WIDTH);

  useEffect(() => {
    const sideNav = sideNavRef.current;
    if (sideNav) {
      sideNav.style.width = `${DEFAULT_WIDTH}px`;
      sideNav.style.minWidth = `${MIN_WIDTH}px`;
    }
  }, []);

  const handleMouseDown = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    document.addEventListener('mousemove', handleMouseMove);
    document.addEventListener('mouseup', handleMouseUp);
  };

  const handleMouseMove = (e: MouseEvent) => {
    const newWidth = e.clientX;
    if (newWidth >= MIN_WIDTH) {
      setWidth(newWidth);
      if (sideNavRef.current) {
        sideNavRef.current.style.width = `${newWidth}px`;
      }
    }
  };

  const handleMouseUp = () => {
    document.removeEventListener('mousemove', handleMouseMove);
    document.removeEventListener('mouseup', handleMouseUp);
  };

  return (
    <div
      ref={sideNavRef}
      className="side_nav_wrapper h-full"
      style={{ width: `${width}px` }}
    >
      <div className="sidebar_nav_content">{children}</div>
      <div ref={resizerRef} onMouseDown={handleMouseDown} className="resizer" />
    </div>
  );
}
