import React from 'react';
import { cn } from 'src/lib/utils';
import { IComment } from 'src/types/entities';

function TaskComments({
  comments,
  renderComment,
}: {
  comments: IComment[];
  renderComment: (comment: IComment) => React.ReactNode;
}) {
  if (!!comments?.length) {
    return (
      <ul className="flex flex-col w-full pb-4 overflow-y-auto scrollbar-hidden pb-[100px]">
        {comments?.map((comment, index) => (
          <li
            key={comment.id}
            className={cn(
              'flex flex-col gap-2.5 w-full py-5',
              index < comments?.length - 1 &&
                'border-b border-[var(--border-default)]'
            )}
          >
            {renderComment(comment)}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <div className="flex flex-col items-center justify-center gap-1 mt-12 w-full"></div>
  );
}

export default TaskComments;
