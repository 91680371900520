import { gql } from '@apollo/client';
import { useState } from 'react';
import { useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import { useCreateOneTaskComment } from 'src/api/comment/task-comment.service';
import CustomMentionItem from 'src/components/common/editor/CustomMentionItem';
import ToastMessage from 'src/components/ToastMessage';
import { transformToTitleCase } from 'src/lib/helpers';
import { IComment, IUser } from 'src/types';

interface UseCommentProps {
  taskId: string;
  onCommentCreated?: () => void;
  onReplyCreated?: () => void;
  collaborators: IUser[];
  editorPlaceholder?: string;
}

export const useComment = ({
  taskId,
  onCommentCreated,
  onReplyCreated,
  editorPlaceholder = '',
  collaborators,
}: UseCommentProps) => {
  const [mentionedUserIDs, setMentionedUserIDs] = useState<string[]>([]);
  const { control, handleSubmit, reset } = useForm({
    defaultValues: {
      comment: '',
      reply: '',
    },
  });

  const [createOneTaskComment, { loading: isCreatingComment }] =
    useCreateOneTaskComment();

  const handleCreateComment = (
    data: { comment: string; reply: string },
    isReply: boolean = false,
    parentCommentId?: string
  ) => {
    const commentText = isReply ? data.reply : data.comment;
    if (!commentText.length || isCreatingComment) return;

    createOneTaskComment({
      variables: {
        data: {
          comment: commentText,
          task: {
            connect: { id: taskId },
          },
          mentionedUsers: {
            connect: mentionedUserIDs.map((id) => ({ id })),
          },
          ...(isReply &&
            parentCommentId && {
              parent: {
                connect: { id: parentCommentId },
              },
            }),
        },
      },
      onCompleted: () => {
        if (isReply) {
          onReplyCreated?.();
        } else {
          onCommentCreated?.();
        }
        reset();
      },
      update: (cache, { data }) => {
        if (!data?.createOneTaskComment) return;

        const newComment = data.createOneTaskComment;
        const commentTaskId = newComment.task.id;

        const cacheTaskId = cache.identify({
          __typename: 'Task',
          id: commentTaskId,
        });

        const existingTask = cache.readFragment<{ comments: IComment[] }>({
          id: cacheTaskId,
          fragment: gql`
            fragment ExistingTaskWithComment on Task {
              id
              comments {
                id
                comment
                createdAt
                mentionedUsers {
                  id
                  detail {
                    fullName
                  }
                }
                responses {
                  id
                  comment
                  createdAt
                  mentionedUsers {
                    id
                    detail {
                      fullName
                    }
                  }
                }
              }
            }
          `,
        });

        if (existingTask) {
          const fullNewComment = {
            ...newComment,
            createdAt: new Date().toISOString(),
            responses: [],
            __typename: 'TaskComment',
          };

          let updatedComments;

          if (isReply && parentCommentId) {
            updatedComments = existingTask.comments.map((comment) =>
              comment.id === parentCommentId
                ? {
                    ...comment,
                    responses: [...comment.responses, fullNewComment],
                  }
                : comment
            );
          } else {
            updatedComments = [...existingTask.comments, fullNewComment];
          }

          cache.writeFragment({
            id: cacheTaskId,
            fragment: gql`
              fragment UpdatedTaskComments on Task {
                comments
              }
            `,
            data: {
              comments: updatedComments,
            },
          });
        }
      },
      onError: (error) => {
        toast((t) => (
          <ToastMessage id={t.id} title={`Error: ${error.message}`} />
        ));
      },
    });
  };

  const handleEditorChange = (editor: any) => {
    const mentions: string[] = [];
    const model = editor.model;
    const documentRoot = model.document.getRoot();

    if (documentRoot) {
      const range = model.createRangeIn(documentRoot);
      for (const item of range.getItems()) {
        if (item.hasAttribute('mention')) {
          const mention = item.getAttribute('mention');
          if (mention && mention.userId) {
            mentions.push(mention.userId);
          }
        }
      }
    }

    setMentionedUserIDs(mentions);
  };

  const editorConfig = {
    placeholder: editorPlaceholder,
    mention: {
      feeds: [
        {
          marker: '@',
          feed: collaborators?.map((collaborator) => ({
            id: `@${collaborator.detail?.fullName ?? collaborator?.email}`,
            userId: collaborator.id,
            name: collaborator.detail?.fullName ?? collaborator?.email,
            position: transformToTitleCase(collaborator.detail?.position),
            avatar: collaborator.detail.avatar,
          })),
          itemRenderer: CustomMentionItem,
          minimumCharacters: 0,
        },
      ],
    },
  };

  return {
    control,
    handleSubmit,
    handleCreateComment,
    handleEditorChange,
    isCreatingComment,
    mentionedUserIDs,
    editorConfig,
  };
};
