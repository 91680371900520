export default function EmptyAssignee() {
  return (
    <svg
      width="20"
      height="21"
      viewBox="0 0 20 21"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect y="0.820801" width="20" height="20" rx="10" fill="#2A2A2A" />
      <path
        d="M13.2058 15.5588C13.7653 15.4189 14.1781 14.9088 14.038 14.3494C13.8635 13.6523 13.5028 13.009 12.9862 12.4924C12.236 11.7422 11.2186 11.3208 10.1578 11.3208C9.09689 11.3208 8.07948 11.7422 7.32933 12.4924C6.81273 13.009 6.45203 13.6523 6.2775 14.3494C6.13743 14.9088 6.55024 15.4189 7.1097 15.5588C9.11094 16.0591 11.2046 16.0591 13.2058 15.5588Z"
        fill="#8D8D8D"
      />
      <path
        d="M12.1578 8.3208C12.1578 9.42537 11.2623 10.3208 10.1578 10.3208C9.05321 10.3208 8.15778 9.42537 8.15778 8.3208C8.15778 7.21623 9.05321 6.3208 10.1578 6.3208C11.2623 6.3208 12.1578 7.21623 12.1578 8.3208Z"
        fill="#8D8D8D"
      />
    </svg>
  );
}
