import {
  IInbox,
  IMedia,
  IPost,
  IPostComment,
  IWorkspace,
  Position,
  UserRole,
} from 'src/lib/types';
import { IBilling, IProject, ITask } from '..';

export enum UserStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE',
}

export enum UserWorkspaceRole {
  ADMIN = 'ADMIN',
  MEMBER = 'MEMBER',
}

export type UserActionStatus = {
  hasCreatedPost: boolean;
  hasCreatedProject: boolean;
  hasInvitedTeamMembers: boolean;
  hasVerifiedDomain: boolean;
  id: string;
  user: IUser;
  userId: string;
};

export interface IUserWorkspace {
  id: string;
  userId: string;
  user: IUser;
  deactivatedAt: string;
  workspaceId: string;
  workspace: IWorkspace;
  isOwner: boolean;
  role: UserWorkspaceRole;
}

export interface IUser {
  actionStatus: UserActionStatus;
  assignedTasks: ITask[];
  billings: IBilling[];
  collaborationIDs: string[];
  collaborations: IProject[];
  commentMentionIDs: string[];
  commentMentions: IUser[];
  comments: IPostComment[];
  createdAt: Date;
  createdProjects: IProject[];
  createdTasks: ITask[];
  detail: {
    resettingPassword: any;
    id: string;
    avatar?: IMedia;
    fullName: string;
    position: Position;
  };
  email: string;
  emailConfirmed: boolean;
  googleId: string;
  id: string;
  inbox: IInbox[];
  microsoftId: String;
  notificationSettings: any;
  postMentionIDs: string[];
  postMentions: IUser[];
  posts: IPost[];
  projects: IProject[];
  role: UserRole;
  slackId: String;
  status: UserStatus;
  stripeCustomerId: string;
  suggestedWorkspaces: IWorkspace[];
  taskCommentMentionIDs: string[];
  taskCommentMentions: IUser[];
  twoFactorEnabled: boolean;
  updatedAt: Date;
  userWorkspaces: IUserWorkspace[];
  workspaceIDs: string[];
}
