import { useLocalStorage } from '@uidotdev/usehooks';
import { Fragment, useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useOutletContext } from 'react-router-dom';
import TextareaAutosize from 'react-textarea-autosize';
import { useJoinOneProject } from 'src/api/project/project.service';
import { ArrowUp, UserCircle } from 'src/assets/icons';
import ToastMessage from 'src/components/ToastMessage';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import { userExistAsCollaborator } from 'src/lib/helpers';
import { IProject } from 'src/types';
import PostModal from './PostModal';
import PostsArena from './PostsArena';

interface PostTabContainerProps {
  projectId: string;
  workspaceSlug: string;
  projectData: IProject;
  myData: any;
  heightHeadingContainerRef: number;
}

const PostTabContainer = () => {
  const {
    projectId,
    workspaceSlug,
    projectData,
    myData,
    heightHeadingContainerRef,
  } = useOutletContext<PostTabContainerProps>();
  const [userId] = useLocalStorage('userId', undefined);
  const { workspaceWorkstreams } = useWorkspaceContext();
  const userCollaborates = userExistAsCollaborator(userId, projectData);

  const [createPost, setCreatePost] = useState(false);
  const [showJoinSuccess, setShowJoinSuccess] = useState(false);

  const [joinOneProject, { loading: isJoiningProject }] = useJoinOneProject();

  const handleJoinProject = async () => {
    try {
      await joinOneProject({
        variables: {
          id: userId,
          where: { id: projectId },
        },
      });

      setShowJoinSuccess(true);
      toast((t) => (
        <ToastMessage
          id={t.id}
          title="You have successfully joined the project"
        />
      ));
    } catch (error) {
      console.error(error);
      toast((t) => (
        <ToastMessage
          id={t.id}
          title={`Failed to join the project. Please try again`}
        />
      ));
    }
  };

  useEffect(() => {
    if (showJoinSuccess) {
      setTimeout(() => {
        setShowJoinSuccess(false);
      }, 10000 * 3);
    }
  }, [showJoinSuccess]);

  return (
    <Fragment>
      <div className="h-[100%] w-[100%] relative">
        {projectId && workspaceSlug && (
          <PostsArena
            myData={myData}
            projectData={projectData}
            heightHeadingContainerRef={heightHeadingContainerRef}
            workspaceSlug={workspaceSlug}
            collaborators={projectData?.collaborators}
            workStreams={workspaceWorkstreams || []}
            handleCreatePost={() => setCreatePost(true)}
            isJoinedSuccess={showJoinSuccess}
          />
        )}

        <div className="px-[32px] pt-4 pb-8 absolute z-5 bottom-0 right-0 h-[92px] w-full transition-all bg-[var(--backgrounds-on-canvas)] border-t border-[var(--border-default)]">
          <div className="w-full absolute h-[70px] top-[-71px] left-0 scrollBg_bottom" />
          {userCollaborates && (
            <div className="flex relative w-full">
              <div className="absolute left-0 inset-y-0 px-2 grid place-content-center">
                <Avatar
                  name={myData?.detail?.fullName ?? myData?.email}
                  src={myData?.detail.avatar?.url}
                  alt={`${myData?.detail?.fullName} pfp`}
                  size={24}
                />
              </div>
              <TextareaAutosize
                name="form-field-name"
                placeholder="What would you like to share?"
                className="input resize-none bg-none placeholder:text-base placeholder:!font-medium !rounded-full w-full !bg-[var(--backgrounds-default)] !pl-11 !p-2 placeholder:text-[var(--text-disabled)] shadow-none"
                onClick={() => setCreatePost(true)}
                readOnly
              />
              <div className="absolute right-0 inset-y-0 px-1.5 grid place-content-center">
                <IconButton
                  onClick={() => setCreatePost(true)}
                  type="primary"
                  size="standard"
                  rounded="full"
                  icon={<ArrowUp />}
                />
              </div>
            </div>
          )}

          {!userCollaborates && (
            <div className="flex items-center justify-between relative w-full bg-[var(--backgrounds-hover-clicked)] rounded-[16px] pl-4 pr-2 py-2">
              <div className="flex items-center gap-2">
                <span>
                  <UserCircle />
                </span>
                <p className="text-body-small text-[var(--text-neutral)] font-normal">
                  You’re currently not a member of this project, want to be part
                  of the conversation?
                </p>
              </div>

              <Button
                value="Join"
                size="medium"
                btnType="primary"
                loading={isJoiningProject}
                onClick={handleJoinProject}
                className={'w-fit'}
              />
            </div>
          )}
        </div>
      </div>

      {workspaceSlug && (
        <PostModal
          show={createPost}
          handleClose={() => setCreatePost(false)}
          collaborators={projectData?.collaborators}
        />
      )}
    </Fragment>
  );
};

export default PostTabContainer;
