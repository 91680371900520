import { IMedia, IProjectTopic } from 'src/lib/types';
import { IComment, IUser } from 'src/types';

export enum TaskPriority {
  HIGH = 'HIGH',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
}
export type TTaskPriority = 'HIGH' | 'LOW' | 'MEDIUM';

export enum TaskStatus {
  NOT_STARTED = 'NOT_STARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETED = 'COMPLETED',
}

export type TTaskStatus = 'IN_PROGRESS' | 'NOT_STARTED' | 'COMPLETED';

export interface ITask {
  archivedAt: string;
  assignee: IUser;
  assigneeId: string;
  attachments: IMedia[];
  createdAt: string;
  createdBy: IUser;
  createdById: string;
  deleteAt: string;
  description: string;
  dueDate: string;
  id: string;
  priority: TTaskPriority;
  project: string;
  projectId: string;
  status: TTaskStatus;
  title: string;
  updatedAt: string;
  workStream: IProjectTopic[];
  workStreamIDs: string[];
  comments: IComment[];
}

export type ITaskInbox = {
  subject: string;
  taskAuthorFullName: string;
  taskTitle: string;
  taskDescription: string;
  taskCreatedAt: string;
  projectId?: string;
  projectName?: string;
  taskId?: string;
};
