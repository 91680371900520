import React, {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useState,
} from 'react';

type Theme = 'light' | 'dark' | 'system';

interface ThemeContextProps {
  theme: Theme;
  toggleTheme: (newTheme: Theme) => void;
  themeImage: string;
}

const ThemeContext = createContext<ThemeContextProps | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [theme, setTheme] = useState<Theme>(() => {
    const savedTheme = localStorage.getItem('theme') as Theme | null;
    return savedTheme || 'system';
  });

  const [themeImage, setThemeImage] = useState<string>('');

  useEffect(() => {
    const applyTheme = (theme: Theme) => {
      if (theme === 'system') {
        const prefersDarkScheme = window.matchMedia(
          '(prefers-color-scheme: dark)'
        ).matches;
        const systemTheme = prefersDarkScheme ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', systemTheme);
        setThemeImage(
          systemTheme === 'dark'
            ? '/path/to/dark-theme-image.jpg'
            : '/path/to/light-theme-image.jpg'
        );
      } else {
        document.documentElement.setAttribute('data-theme', theme);
        setThemeImage(
          theme === 'dark'
            ? '/path/to/dark-theme-image.jpg'
            : '/path/to/light-theme-image.jpg'
        );
      }
    };

    applyTheme(theme);

    const handleSystemThemeChange = (e: MediaQueryListEvent) => {
      if (theme === 'system') {
        const systemTheme = e.matches ? 'dark' : 'light';
        document.documentElement.setAttribute('data-theme', systemTheme);
        setThemeImage(
          systemTheme === 'dark'
            ? '/path/to/dark-theme-image.jpg'
            : '/path/to/light-theme-image.jpg'
        );
      }
    };

    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    mediaQuery.addEventListener('change', handleSystemThemeChange);

    return () => {
      mediaQuery.removeEventListener('change', handleSystemThemeChange);
    };
  }, [theme]);

  const toggleTheme = (newTheme: Theme) => {
    setTheme(newTheme);
    localStorage.setItem('theme', newTheme);
  };

  return (
    <ThemeContext.Provider value={{ theme, toggleTheme, themeImage }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useTheme = (): ThemeContextProps => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useTheme must be used within a ThemeProvider');
  }
  return context;
};
