import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocalStorage } from '@uidotdev/usehooks';
import BalloonEditor from 'ckeditor5-custom-build';
import parse from 'html-react-parser';
import { useState } from 'react';
import { Controller } from 'react-hook-form';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import Timestamp from 'src/components/ui/TimeStamp';
import { useCommentEdit } from 'src/hooks/project/task/useCommentEditAction';
import { IComment, IUser } from 'src/types';
import DeleteTaskCommentModal from './DeleteTaskCommentModal';
import TaskCommentMenu from './TaskCommentMenu';

interface IReplyCommentBodyProps {
  comment: IComment;
  collaborators: IUser[];
}

function ReplyCommentBody({ comment, collaborators }: IReplyCommentBodyProps) {
  const [userId] = useLocalStorage('userId', undefined);

  const isCommentOwner = userId === comment.author.id;
  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);

  const {
    isEditing,
    startEditing,
    cancelEditing,
    control,
    handleSubmit,
    handleEditComment,
    handleEditorChange,
    isUpdating,
    editedComment,
    editorConfig,
  } = useCommentEdit({
    comment,
    collaborators,
    onEditComplete: () => {},
  });

  return (
    <div className="duration-1000 flex items-start gap-y-2 flex-col w-full">
      <div className="flex items-center space-x-6 w-full">
        <div className="flex-1 flex gap-1.5 items-center">
          <Avatar
            alt={comment.author.detail?.fullName}
            src={comment.author.detail.avatar?.url}
            size={20}
            name={comment.author.detail?.fullName ?? comment.author.email}
          />
          <div className="flex items-center gap-1.5">
            <div className="text-body-micro medium text-[var(--text-default)]">
              {comment.author.detail?.fullName ?? comment.author.email}
            </div>
            <svg
              width="5"
              height="4"
              viewBox="0 0 5 4"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M2.5 3.16477C2.21354 3.16477 1.95194 3.09493 1.7152 2.95526C1.47846 2.81321 1.28906 2.62382 1.14702 2.38707C1.00734 2.15033 0.9375 1.88873 0.9375 1.60227C0.9375 1.31345 1.00734 1.05185 1.14702 0.817472C1.28906 0.580729 1.47846 0.392519 1.7152 0.252841C1.95194 0.110795 2.21354 0.0397725 2.5 0.0397725C2.78883 0.0397725 3.05043 0.110795 3.2848 0.252841C3.52154 0.392519 3.70975 0.580729 3.84943 0.817472C3.99148 1.05185 4.0625 1.31345 4.0625 1.60227C4.0625 1.88873 3.99148 2.15033 3.84943 2.38707C3.70975 2.62382 3.52154 2.81321 3.2848 2.95526C3.05043 3.09493 2.78883 3.16477 2.5 3.16477Z"
                fill="#8D8D8D"
              />
            </svg>

            <Timestamp
              className="text-caption regular text-[var(--text-neutral)]"
              createdAt={comment.createdAt}
            />
          </div>
        </div>

        <TaskCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          handleEdit={startEditing}
          handleDelete={() => setDeleteComment(comment)}
          type={'comment'}
        />
      </div>

      {isEditing ? (
        <div className="space-y-4 w-full">
          <div className="w-full textarea-editor !pl-5 !pr-5">
            <Controller
              name="editedComment"
              control={control}
              render={({ field }) => (
                <CKEditor
                  editor={BalloonEditor}
                  data={field.value}
                  config={editorConfig as any}
                  onChange={(event, editor) => {
                    field.onChange(editor.getData());
                    handleEditorChange(editor);
                  }}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={cancelEditing}
              type="button"
              className={'w-fit'}
              size="small"
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSubmit(handleEditComment)}
              type="button"
              className={'w-fit'}
              size="small"
              loading={isUpdating}
              disabled={isUpdating || editedComment === comment.comment}
              value={'Save'}
              btnType={'primary'}
            />
          </div>
        </div>
      ) : (
        <div className="w-full">
          <div
            className={
              'ck ck-content text-body-micro regular text-[var(--text-default)] w-full'
            }
          >
            {parse(comment.comment)}
          </div>
        </div>
      )}

      <DeleteTaskCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
        isReply={true}
        parentCommentId={comment.id as string}
      />
    </div>
  );
}

export default ReplyCommentBody;
