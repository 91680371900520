import clsx from 'clsx';
import React from 'react';
import { tv } from 'tailwind-variants';
import Spinner from '../spinner/circle-spinner/Spinner';

export default function IconButton({
  open,
  icon,
  size,
  type,
  rounded,
  state,
  className,
  disabled,
  onClick,
  loading = false,
}: {
  open?: boolean;
  icon: React.ReactNode;
  size: 'micro' | 'small' | 'standard' | 'large';
  type: 'ghost' | 'neutral' | 'primary';
  rounded?: 'micro' | 'small' | 'standard' | 'large' | 'full';
  state?: 'open' | undefined;
  className?: string;
  disabled?: boolean;
  onClick?: () => void;
  loading?: boolean;
}) {
  return (
    <button
      disabled={disabled}
      className={`${clsx(
        'icon_button rounded-lg flex items-center justify-center aspect-square transition-all duration-200 overflow-hidden cursor-pointer relative disabled:cursor-not-allowed',
        size,
        type,
        className,
        {
          open: open,
        }
      )}
      ${iconButtonStyles({ rounded: rounded, state: state })}
      `}
      type="button"
      onClick={onClick}
    >
      {loading && (
        <div className="absolute inset-0 bg-[inherit] grid place-items-center">
          <Spinner />
        </div>
      )}
      <div className="transition-all duration-200">{icon}</div>
    </button>
  );
}

const iconButtonStyles = tv({
  variants: {
    rounded: {
      micro: '!rounded',
      small: '!rounded-md',
      standard: '!rounded-lg',
      large: '!rounded-lg',
      full: '!rounded-full',
    },
    state: {
      open: 'bg-[var(--button-neutral-hover-clicked)]',
    },
  },
  defaultVariants: {
    rounded: 'small',
  },
});
