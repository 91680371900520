import React, { PropsWithChildren } from 'react';
import { ChevronDown } from 'src/assets/icons';
import { Checkbox } from 'src/components/ui/NewCheckbox';
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from 'src/components/ui/Popover';
import { cn } from 'src/lib/utils';

type Option = {
  label?: string;
  value: string;
  icon?: React.ReactNode;
  other?: any;
};

interface IProjectFilterDropdownProps extends PropsWithChildren<{}> {
  value: string[];
  onChange: (value: string[]) => void;
  label: string;
  icon: React.ReactNode;
  options: Option[];
  renderOption?: (option: Option) => React.ReactNode;
  customOptionItem?: (option: Option) => React.ReactNode;
  className?: string;
}

const ProjectFilterDropdown = (props: IProjectFilterDropdownProps) => {
  const {
    value,
    onChange,
    options,
    label,
    icon,
    customOptionItem,
    className,
    renderOption,
  } = props;

  const handleSelectOption = (option: Option, e: React.MouseEvent) => {
    e.preventDefault();

    const newValue = value.includes(option.value)
      ? value.filter((val) => val !== option.value)
      : [...value, option.value];

    onChange(newValue);
  };

  const renderSelectOptions = () => {
    return options?.map((option) => {
      const isSelected = value.includes(option.value);

      if (renderOption) {
        return renderOption(option);
      }

      return (
        <label
          key={option.value}
          htmlFor={option.value}
          className="w-full flex items-center justify-between gap-x-2 px-2 min-h-[32px] hover:bg-[var(--backgrounds-hover-clicked)] cursor-pointer rounded"
          onClick={(e) => handleSelectOption(option, e)}
        >
          {!customOptionItem ? (
            <span className="text-body-small medium text-[var(--text-neutral)]">
              {option.label}
            </span>
          ) : (
            customOptionItem(option)
          )}
          <Checkbox
            id={option.value}
            checked={isSelected}
            onChange={(e: any) => handleSelectOption(option, e)}
          />
        </label>
      );
    });
  };

  return (
    <Popover>
      <PopoverTrigger asChild>
        <div className="flex items-center justify-center gap-x-1 w-max border !border-[var(--border-on-bg)] p-[0px_8px_0px_8px] rounded-full text-body-mini medium text-[var(--text-neutral)] h-[24px] !cursor-pointer select-none">
          {icon}
          <span>{label}</span>
          {value.length > 0 && (
            <span className="w-3 h-3 flex items-center justify-center bold text-caption text-[var(--backgrounds-on-canvas)] bg-[var(--text-default)] rounded-full">
              {value.length}
            </span>
          )}
          <ChevronDown />
        </div>
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'min-w-[200px] flex flex-col items-center gap-y-1 border-[var(--border-on-bg)] rounded-md p-1 max-h-[300px] overflow-y-auto',
          className
        )}
        sideOffset={8}
      >
        {renderSelectOptions()}
      </PopoverContent>
    </Popover>
  );
};

export default ProjectFilterDropdown;
