import { motion } from 'framer-motion';
import { Fragment, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useInboxCount } from 'src/api/inbox/inbox.service';
import { Close, Inbox, LogoNeutral, MegaPhone, Rocket } from 'src/assets/icons';
import SideNavMenuBtn from 'src/components/sideNav/SideNavMenu';
import { useUser } from 'src/context/userContext';
import { useWorkspaceContext } from 'src/context/workspaceContext';
import InboxPage from '../features/Inbox/InboxPage';
import ProjectModal from '../projects/ProjectModal';
import Badge from '../ui/Badge';
import IconButton from '../ui/Button/IconButton';
import NavigationHeader from './NavigationHeader';

export default function HomeSideNav() {
  const { workspaceSlug } = useParams();

  const {
    user,
    userSetupComplete,
    handleSkipUserAction,
    isSkippingUserAction,
  } = useUser();
  const { currentWorkspace } = useWorkspaceContext();

  const WORKSPACE_URL_BASE = `/workspace/${workspaceSlug}/project`;

  const { data: inboxData } = useInboxCount({
    variables: currentWorkspace?.workspace.id
      ? {
          workspaceId: currentWorkspace?.workspace.id,
        }
      : {},
  });
  const unreadInboxCount = inboxData?.inboxCount || 0;

  const [createProject, setCreateProject] = useState(false);
  const [showInbox, setShowInbox] = useState<boolean>(false);
  const [showDismissButton, setShowDismissButton] = useState<boolean>(false);

  return (
    <Fragment>
      <div className="side_nav_group h-full justify-between">
        <div className="flex flex-col gap-6 w-full">
          <div className="menu_btn_group">
            {!userSetupComplete && (
              <Link to={`/workspace/${workspaceSlug}/get-started`}>
                <SideNavMenuBtn
                  type="home"
                  label={'Get Started'}
                  icon={<Rocket className="w-4 h-4" />}
                  link={`/workspace/${workspaceSlug}/get-started`}
                  onMouseEnter={() => {
                    if (isSkippingUserAction) return;
                    setShowDismissButton(true);
                  }}
                  onMouseLeave={() => {
                    if (isSkippingUserAction) return;
                    setShowDismissButton(false);
                  }}
                  renderActionButton={() => (
                    <Fragment>
                      {showDismissButton && (
                        <motion.div
                          initial={{ scale: 0 }}
                          animate={{
                            scale: showDismissButton ? 1 : 0,
                          }}
                          className="w-fit"
                        >
                          <IconButton
                            type="ghost"
                            size="micro"
                            onClick={handleSkipUserAction}
                            icon={<Close />}
                            loading={isSkippingUserAction}
                          />
                        </motion.div>
                      )}
                    </Fragment>
                  )}
                />
              </Link>
            )}
            <div
              className={`w-full rounded-lg ${
                showInbox && 'bg-[var(--backgrounds-hover-clicked)]'
              }`}
              onClick={() => setShowInbox(!showInbox)}
            >
              <SideNavMenuBtn
                type="home"
                label={'Inbox'}
                icon={<Inbox className="w-4 h-4" />}
                showBadge={unreadInboxCount > 0}
                badge={unreadInboxCount}
                showActiveState={false}
              />
            </div>
            <Link to={`/workspace/${workspaceSlug}/feeds`}>
              <SideNavMenuBtn
                type="home"
                label={'Feed'}
                icon={<MegaPhone className="w-4 h-4" />}
                link={`/workspace/${workspaceSlug}/feeds`}
              />
            </Link>
          </div>
          <NavigationHeader
            label="Projects"
            openInbox={createProject}
            baseLink={`${WORKSPACE_URL_BASE}`}
            secNavFunc={() => setCreateProject(true)}
          />
        </div>
        <ProjectModal
          show={createProject}
          handleClose={() => setCreateProject(false)}
        />
        <InboxPage
          showInbox={showInbox}
          handleClick={() => setShowInbox(false)}
        />
        <div className="w-fit">
          <div className="px-3 gap-2 py-0.5 h-8 flex items-center rounded-lg">
            <div className="">
              <LogoNeutral />
            </div>
            <Badge value={'EARLY ACCESS'} />
          </div>
        </div>
      </div>
    </Fragment>
  );
}
