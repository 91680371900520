import { TaskStatus } from 'src/types/entities';

export const MAX_FILE_UPLOAD_SIZE = {
  bytes: 1 * 1024 * 1024,
  humanReadable: '6MB',
};

export const avatarColorClasses = [
  'avatar-blue-gem',
  'avatar-oxford-blue',
  'avatar-willow-grove',
  'avatar-persian-indigo',
  'avatar-meteor',
  'avatar-korma',
  'avatar-honey-flower',
  'avatar-green-leaf',
  'avatar-martinique',
  'avatar-cloud-burst',
  'avatar-ultramarine',
  'avatar-whisper',
  'avatar-eastern-blue',
  'avatar-tuna',
  'avatar-bunting',
  'avatar-green-pea',
  'avatar-kingfisher-daisy',
  'avatar-shiraz',
  'avatar-rouge',
];

export const TASK_STATUS = [
  {
    value: TaskStatus.NOT_STARTED,
    label: 'Not Started',
  },
  {
    value: TaskStatus.IN_PROGRESS,
    label: 'In Progress',
  },
  {
    value: TaskStatus.COMPLETED,
    label: 'Completed',
  },
];
