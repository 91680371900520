import React, { createContext, ReactNode, useContext, useState } from 'react';

interface ModalState {
  type: string;
  props?: any;
}

interface ModalContextType {
  showModal: (type: string, props?: any) => void;
  hideModal: () => void;
  modalState: ModalState | null;
}

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<{ children: ReactNode }> = ({
  children,
}) => {
  const [modalState, setModalState] = useState<ModalState | null>(null);

  const showModal = (type: string, props?: any) => {
    setModalState({ type, props });
  };

  const hideModal = () => {
    setModalState(null);
  };

  return (
    <ModalContext.Provider value={{ showModal, hideModal, modalState }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = (): ModalContextType => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
