import { CKEditor } from '@ckeditor/ckeditor5-react';
import { useLocalStorage } from '@uidotdev/usehooks';
import BalloonEditor from 'ckeditor5-custom-build';
import { AnimatePresence, motion } from 'framer-motion';
import parse from 'html-react-parser';
import { useEffect, useRef, useState } from 'react';
import { Controller } from 'react-hook-form';
import { ChevronDown } from 'src/assets/icons';
import Avatar from 'src/components/ui/Avatar/Avatar';
import Button from 'src/components/ui/Button/Button';
import IconButton from 'src/components/ui/Button/IconButton';
import Timestamp from 'src/components/ui/TimeStamp';
import { useCommentEdit } from 'src/hooks/project/task/useCommentEditAction';
import { IComment, IUser } from 'src/types';
import DeleteTaskCommentModal from './DeleteTaskCommentModal';
import TaskCommentMenu from './TaskCommentMenu';

interface ITaskCommentBodyProps {
  comment: IComment;
  collaborators: IUser[];
}

function TaskCommentBody({ comment, collaborators }: ITaskCommentBodyProps) {
  const [userId] = useLocalStorage('userId', undefined);
  const isCommentOwner = userId === comment.author.id;
  const [deleteComment, setDeleteComment] = useState<IComment | null>(null);

  const {
    isEditing,
    startEditing,
    cancelEditing,
    control,
    handleSubmit,
    handleEditComment,
    handleEditorChange,
    isUpdating,
    editedComment,
    editorConfig,
  } = useCommentEdit({
    comment,
    collaborators,
    onEditComplete: () => {},
  });

  const [isExpanded, setIsExpanded] = useState(false);
  const [isTruncated, setIsTruncated] = useState(false);
  const [isHovered, setIsHovered] = useState(false);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    setIsHovered(!isHovered);
  };

  useEffect(() => {
    if (contentRef.current) {
      const lineHeight = parseFloat(
        getComputedStyle(contentRef.current).lineHeight
      );
      const maxHeight = lineHeight * 6;

      if (contentRef.current.scrollHeight > maxHeight) {
        setIsTruncated(false);
      }
    }
  }, [comment]);

  return (
    <div className="duration-1000 flex flex-col gap-4">
      <div className="flex items-center space-x-6">
        <div className="flex-1 flex space-x-2 items-center">
          <Avatar
            alt={comment.author.detail?.fullName ?? comment.author?.email}
            src={comment.author.detail.avatar?.url}
            size={28}
            name={comment.author.detail?.fullName}
          />
          <div className="">
            <div className="text-body-small text-[var(--text-default)] font-medium">
              {comment.author.detail?.fullName ?? comment.author?.email}
            </div>
            <Timestamp
              className="text-body-micro text-[var(--text-neutral)] regular"
              createdAt={comment.createdAt}
            />
          </div>
        </div>

        <TaskCommentMenu
          canEdit={isCommentOwner}
          canDelete={isCommentOwner}
          handleEdit={startEditing}
          handleDelete={() => setDeleteComment(comment)}
          type={'comment'}
        />
      </div>

      {isEditing ? (
        <div className="space-y-4">
          <div className="w-full textarea-editor !pl-5 !pr-5">
            <Controller
              name="editedComment"
              control={control}
              render={({ field }) => (
                <CKEditor
                  editor={BalloonEditor}
                  data={field.value}
                  config={editorConfig as any}
                  onChange={(event, editor) => {
                    field.onChange(editor.getData());
                    handleEditorChange(editor);
                  }}
                />
              )}
            />
          </div>

          <div className="flex items-center justify-end space-x-3">
            <Button
              onClick={cancelEditing}
              type="button"
              size="small"
              className={'w-fit'}
              value={'Cancel'}
              btnType={'neutral'}
            />
            <Button
              onClick={handleSubmit(handleEditComment)}
              type="button"
              size="small"
              className={'w-fit'}
              loading={isUpdating}
              disabled={isUpdating || editedComment === comment.comment}
              value={'Save'}
              btnType={'primary'}
            />
          </div>
        </div>
      ) : (
        <div className="w-full relative">
          <div
            ref={contentRef}
            className={`ck ck-content text-body-small regular text-[var(--text-default)] ${
              isExpanded ? '' : 'article truncate-lines-'
            }`}
          >
            {parse(comment.comment)}
          </div>

          {isTruncated && (
            <div
              className="absolute bottom-0 h-[72px] w-full flex flex-col items-center justify-end"
              style={{
                background:
                  !isExpanded || isHovered ? 'var(--truncate-bg)' : 'none',
              }}
              onMouseEnter={() => setIsHovered(true)}
              onMouseLeave={() => setIsHovered(false)}
            >
              <AnimatePresence>
                {isExpanded && isHovered && (
                  <motion.div
                    initial={{ y: 10, opacity: 0 }}
                    animate={{ y: 0, opacity: 1 }}
                    exit={{ y: 10, opacity: 0 }}
                    transition={{ duration: 0.3 }}
                  >
                    <IconButton
                      icon={<ChevronDown className="rotate-180" />}
                      size={'standard'}
                      type={'neutral'}
                      rounded="full"
                      onClick={toggleExpand}
                    />
                  </motion.div>
                )}

                {!isExpanded && (
                  <IconButton
                    icon={<ChevronDown />}
                    size={'standard'}
                    type={'neutral'}
                    onClick={toggleExpand}
                  />
                )}
              </AnimatePresence>
            </div>
          )}
        </div>
      )}

      <DeleteTaskCommentModal
        comment={deleteComment}
        show={Boolean(deleteComment)}
        handleClose={() => setDeleteComment(null)}
      />
    </div>
  );
}

export default TaskCommentBody;
