import { useMutation } from '@apollo/client';
import { useApolloOptions } from '../api';

import {
  EXIT_ONE_WORKSPACE,
  UPDATE_ONE_USER_AVATAR,
  UPDATE_ONE_USER_DETAIL,
  UPDATE_ONE_USER_PASSWORD,
  UPDATE_USER_ACTION_STATUS,
  UPSERT_ONE_USER_DETAIL,
  UPSERT_ONE_USER_NOTIFICATION_SETTINGS,
} from 'src/lib/mutations';
import { ME } from 'src/lib/queries';

export function useUpdateUserActionStatus() {
  const options = useApolloOptions();
  return useMutation(UPDATE_USER_ACTION_STATUS, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useUpdateOneUserAvatar() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_USER_AVATAR, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useUpdateOneUserDetail() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_USER_DETAIL, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useUpdateOneUserPassword() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_USER_PASSWORD, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useUpsertOneUserDetail() {
  const options = useApolloOptions();

  return useMutation(UPSERT_ONE_USER_DETAIL, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useUpsertOneUserNotificationSettings() {
  const options = useApolloOptions();

  return useMutation(UPSERT_ONE_USER_NOTIFICATION_SETTINGS, {
    ...options,
    refetchQueries: [ME],
  });
}

export function useExitOneWorkspace() {
  const options = useApolloOptions();
  return useMutation(EXIT_ONE_WORKSPACE, {
    ...options,
    refetchQueries: [ME],
  });
}
