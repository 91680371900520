import './ui.css';

interface HorizontalDividerProps {
  size?: 'large';
  className?: string;
}

const HorizontalDivider: React.FC<HorizontalDividerProps> = ({
  size,
  className,
}) => {
  return (
    <div
      className={`hr ${size === 'large' ? 'large' : 'small'} ${className}`}
    />
  );
};

export default HorizontalDivider;
