import React, { useState } from 'react';
import { Check } from 'src/assets/icons';
import DarkMode from 'src/components/pages/Preference/DarkMode';
import LightMode from 'src/components/pages/Preference/LightMode';
import SystemMode from 'src/components/pages/Preference/SystemMode';
import HeaderText from 'src/components/ui/HeaderTexts';
import HorizontalDivider from 'src/components/ui/HorizontalDivider';
import SelectInput from 'src/components/ui/inputs/Select';
import { useTheme } from 'src/context/themeContext';

// Assuming Theme is defined in your themeContext
type Theme = 'light' | 'dark' | 'system';
interface RoleData {
  name: string;
  value: string;
}

const themeOptions: { type: Theme; Component: React.ComponentType }[] = [
  { type: 'light', Component: LightMode },
  { type: 'dark', Component: DarkMode },
  { type: 'system', Component: SystemMode },
];

export default function Preference() {
  const { theme, toggleTheme } = useTheme();
  const [timeSelect, setTimeSelect] = useState(false);
  const [timeZone, setTimeZone] = useState<RoleData>({
    name: 'GMT+1',
    value: 'GMT+1',
  });
  const [languageSelect, setLanguageSelect] = useState(false);
  const [language, setLanguage] = useState<RoleData>({
    name: 'English',
    value: 'en',
  });

  function handleTimeSelectChange(arg: RoleData) {
    setTimeSelect(false);
    setTimeZone(arg);
  }

  return (
    <div className="workspace-page flex flex-col gap-10">
      <div className="header_div">
        <HeaderText title="Preference" />
        <HorizontalDivider />
      </div>
      <div className="flex flex-col gap-6 items-start">
        <HeaderText subTitle="Theme" />
        <div className="flex items-center gap-8">
          {themeOptions.map(({ type, Component }) => (
            <div key={type} className="flex flex-col items-center gap-3">
              <button
                style={{
                  outline:
                    theme === type
                      ? '2px solid var(--border-input-active)'
                      : 'none',
                  padding: '14px',
                  backgroundColor: 'var(--backgrounds-hover-clicked)',
                  borderRadius: '1rem',
                  transition:
                    'outline 0.3s ease-in-out, background-color 0.3s ease-in-out',
                }}
                onClick={() => toggleTheme(type)}
                className="flex flex-col gap-3 items-center justify-center"
              >
                <Component />
              </button>
              <div
                className={`px-2 py-1 rounded-full text-heading-6 medium flex items-center h-6 ${
                  theme === type
                    ? 'text-[var(--text-default)] bg-[var(--backgrounds-hover-clicked)] gap-2'
                    : 'text-[var(--text-disabled)]'
                }`}
                style={{
                  transition:
                    'color 0.3s ease-in-out, background-color 0.3s ease-in-out',
                }}
              >
                {type.charAt(0).toUpperCase() + type.slice(1)}
                {theme === type && (
                  <Check className="w-3 h-3 preference_icon" />
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
      <HorizontalDivider />
      <div className="flex flex-col gap-6">
        <div className="flex flex-col gap-2">
          <HeaderText subTitle="Language & region" />
          <SelectInput
            showDropGroup={languageSelect}
            value={language.name}
            style={{ maxHeight: '210px' }}
            data={[
              {
                name: 'English',
                value: 'en',
              },
            ]}
            placeholder="Enter your role"
            onClick={() => setLanguageSelect(false)}
            onItemClick={() => {}}
            clickOutside={() => setLanguageSelect(false)}
          />
        </div>
        <div className="flex flex-col gap-2">
          <HeaderText subTitle="Time zone" />
          <SelectInput
            showDropGroup={timeSelect}
            value={timeZone.name}
            style={{ maxHeight: '210px' }}
            data={[
              {
                name: 'English',
                value: 'en',
              },
            ]}
            placeholder="Enter your role"
            onClick={() => setTimeSelect(false)}
            onItemClick={() => {}}
            clickOutside={() => setTimeSelect(false)}
          />
        </div>
      </div>
    </div>
  );
}
