import { IComment, IMedia, IProject, IProjectTopic, IUser } from '..';

export enum PostType {
  GENERAL = 'GENERAL',
  IDEAS = 'IDEAS',
  DECISION = 'DECISION',
  SUGGESTION = 'SUGGESTION',
}

export interface IPost {
  attachments: IMedia[];
  author: IUser;
  authorId: string;
  comment: string;
  comments: IComment[];
  createdAt: string;
  id: string;
  mentionedUserIDs: string[];
  mentionedUsers: IUser[];
  project: IProject;
  projectId: string;
  title: string;
  topic: IProjectTopic;
  topicId: string;
  type: PostType;
}
