import { format, isThisYear, parseISO, startOfDay, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react';

// Function to format relative time
function formatRelativeTime(createdAt: string): string {
  const date = parseISO(createdAt);
  const now = new Date();
  const diffInSeconds = (now.getTime() - date.getTime()) / 1000;

  if (diffInSeconds < 60) {
    return diffInSeconds < 30 ? 'Just now' : '1 min ago';
  } else if (diffInSeconds < 3600) {
    const mins = Math.floor(diffInSeconds / 60);
    return `${mins} min${mins > 1 ? 's' : ''} ago`;
  } else if (diffInSeconds < 86400) {
    const hours = Math.floor(diffInSeconds / 3600);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }

  const today = startOfDay(new Date());
  const yesterday = subDays(today, 1);
  const dateMidnight = startOfDay(date);

  if (dateMidnight.getTime() === yesterday.getTime()) {
    return `Yesterday, ${format(date, 'hh:mm aa')}`;
  } else if (dateMidnight.getTime() === today.getTime()) {
    return `Today, ${format(date, 'hh:mm aa')}`;
  } else {
    if (isThisYear(date)) {
      return format(date, 'MMM d');
    } else {
      return format(date, 'MMM d, yyyy');
    }
  }
}

interface TimestampProps extends React.HTMLAttributes<HTMLParagraphElement> {
  createdAt: string;
}

const Timestamp: React.FC<TimestampProps> = ({
  createdAt = new Date().toISOString(),
  ...rest
}) => {
  const [formattedTime, setFormattedTime] = useState<string>(() =>
    formatRelativeTime(createdAt)
  );

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFormattedTime(formatRelativeTime(createdAt));
    }, 60000);

    setFormattedTime(formatRelativeTime(createdAt));

    return () => clearInterval(intervalId);
  }, [createdAt]);

  return <p {...rest}>{formattedTime}</p>;
};

export default Timestamp;
