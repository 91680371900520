import React from 'react';
import { tagColorStyles } from 'src/lib/colorPreset';
import '../ui.css';

interface TagProps {
  size: 'large' | 'medium' | 'small';
  type: 'filled' | 'stroke';
  leftIcon?: React.ReactNode;
  rightIcon?: React.ReactNode;
  value: string;
  useDefaultColor?: boolean;
}

const sizeStyles = {
  large: { fontSize: '16px', padding: '8px 16px' },
  medium: {
    fontSize: '12px',
    padding: '0px 6px',
    height: '20px',
    borderRadius: '6px',
    gap: '4px',
  },
  small: { fontSize: '12px', padding: '4px 8px' },
};

const ColorTag: React.FC<TagProps> = ({
  size,
  type,
  leftIcon,
  rightIcon,
  value,
  useDefaultColor = false,
}) => {
  const colorKey = useDefaultColor
    ? 'default'
    : getTagColorKey(value.charAt(0).toUpperCase());
  const color = tagColorStyles[colorKey] || tagColorStyles['default'];

  const styles: React.CSSProperties = {
    ...color,
    ...sizeStyles[size],
    border: type === 'stroke' ? `1px solid ${color.color}` : 'none',
    display: 'flex',
    alignItems: 'center',
    '--fill': color.color as string,
  };

  return (
    <div className={`color-tag w-fit ${color}`} style={styles}>
      {leftIcon && <div className="icon left-icon">{leftIcon}</div>}
      <span className="medium">{value}</span>
      {rightIcon && <div className="icon right-icon">{rightIcon}</div>}
    </div>
  );
};

const getTagColorKey = (letter: string) => {
  if (letter >= 'A' && letter <= 'C') return 'range1';
  if (letter >= 'D' && letter <= 'F') return 'range2';
  if (letter >= 'G' && letter <= 'I') return 'range3';
  if (letter >= 'J' && letter <= 'L') return 'range4';
  if (letter >= 'M' && letter <= 'O') return 'range5';
  if (letter >= 'P' && letter <= 'R') return 'range6';
  if (letter >= 'S' && letter <= 'V') return 'range7';
  if (letter >= 'W' && letter <= 'Z') return 'range8';
  return 'default';
};

export default ColorTag;
