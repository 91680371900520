import './ui.css';

interface CheckBoxProps {
  type: 'default' | 'disabled';
  isChecked: boolean;
  onCheckboxChange: () => void;
}

const CheckBox: React.FC<CheckBoxProps> = ({
  isChecked,
  onCheckboxChange,
  type,
}) => {
  return (
    <>
      <input
        type="checkbox"
        checked={isChecked}
        onChange={onCheckboxChange}
        style={{ display: 'none' }} // Hide the default checkbox input
      />
      <label htmlFor="checkbox">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
          onClick={onCheckboxChange} // Toggle checkbox state when SVG is clicked
          style={{ cursor: 'pointer' }}
        >
          {/* disabled */}
          {type === 'disabled' && (
            <rect
              className="disabled"
              x="2.29167"
              y="2.29167"
              width="15.4167"
              height="15.4167"
              rx="2.70833"
              strokeWidth="1.25"
              style={{ display: isChecked ? 'none' : 'initial' }}
            />
          )}

          {/* default */}
          {!isChecked && (
            <rect
              className="default"
              x="2.29167"
              y="2.29167"
              width="15.4167"
              height="15.4167"
              rx="2.70833"
              strokeWidth="1.25"
            />
          )}

          {/* checked */}
          {isChecked && (
            <path
              d="M13.4917 1.66666H6.50834C3.475 1.66666 1.66667 3.475 1.66667 6.50833V13.4833C1.66667 16.525 3.475 18.3333 6.50834 18.3333H13.4833C16.5167 18.3333 18.325 16.525 18.325 13.4917V6.50833C18.3333 3.475 16.525 1.66666 13.4917 1.66666ZM13.9833 8.08333L9.25834 12.8083C9.14167 12.925 8.98334 12.9917 8.81667 12.9917C8.65 12.9917 8.49167 12.925 8.37501 12.8083L6.01667 10.45C5.775 10.2083 5.775 9.80833 6.01667 9.56666C6.25834 9.325 6.65834 9.325 6.9 9.56666L8.81667 11.4833L13.1 7.2C13.3417 6.95833 13.7417 6.95833 13.9833 7.2C14.225 7.44166 14.225 7.83333 13.9833 8.08333Z"
              fill="#8178EF"
            />
          )}
        </svg>
      </label>
    </>
  );
};

export default CheckBox;
