import { PropsWithChildren } from 'react';
import { cn } from 'src/lib/utils';
import { DatePicker } from '../ui/DatePicker';
import { Popover, PopoverContent, PopoverTrigger } from '../ui/Popover';

interface IDatePickerPopoverProps extends PropsWithChildren<{}> {
  contentClassName?: string;
  triggerClassName?: string;
  disabled?: boolean;
  value: string;
  onOpenChange?: (isOpen: boolean) => void;
  onChange: (date: string) => void;
}

const DatePickerPopover = (props: IDatePickerPopoverProps) => {
  const {
    children,
    triggerClassName,
    contentClassName,
    disabled,
    value,
    onOpenChange,
    onChange,
  } = props;
  return (
    <Popover onOpenChange={onOpenChange} {...props}>
      <PopoverTrigger
        disabled={disabled}
        className={cn('disabled:opacity-50', triggerClassName)}
      >
        {children}
      </PopoverTrigger>
      <PopoverContent
        className={cn(
          'min-w-[350px] flex flex-col items-center gap-y-1 border-[var(--border-on-bg)] rounded-md p-1',
          contentClassName
        )}
        sideOffset={8}
      >
        <DatePicker
          mode="single"
          initialFocus
          selected={value ? new Date(value) : undefined}
          onSelect={(date) => date && onChange(date.toISOString())}
          disabled={disabled}
          // disabled={(date) =>
          //   date > new Date() || date < new Date('1900-01-01')
          // }
        />
      </PopoverContent>
    </Popover>
  );
};

export default DatePickerPopover;
