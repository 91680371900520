import { useMutation } from '@apollo/client';
import {
  CREATE_CHECKOUT_SESSION,
  GENERATE_UPLOAD_SIGNATURE,
} from 'src/lib/mutations';
import { useApolloOptions } from '../api';

export function useGenerateUploadSignature() {
  const options = useApolloOptions();
  return useMutation(GENERATE_UPLOAD_SIGNATURE, options);
}

export function useCreateCheckoutSession() {
  const options = useApolloOptions();
  return useMutation(CREATE_CHECKOUT_SESSION, options);
}
