import { useMutation, useQuery } from '@apollo/client';
import {
  ARCHIVE_ONE_PROJECT,
  CREATE_ONE_PROJECT,
  CREATE_ONE_PROJECT_GOAL,
  CREATE_ONE_PROJECT_TOPIC,
  DELETE_ONE_PROJECT,
  JOIN_ONE_PROJECT,
  RESTORE_ONE_PROJECT,
  UPDATE_ONE_PROJECT,
} from 'src/lib/mutations';
import {
  ARCHIVED_PROJECTS,
  PROJECT,
  PROJECTS,
  PROJECT_GOALS,
  PROJECT_TOPICS,
  WORKSPACE,
  WORKSPACES,
} from 'src/lib/queries';
import { useApolloOptions } from '../api';

export function useProject(args: object) {
  const options = useApolloOptions();

  return useQuery(PROJECT, {
    ...options,
    fetchPolicy: 'cache-and-network' as const,
    ...args,
  });
}

export function useProjects(args: object) {
  const options = useApolloOptions();

  return useQuery(PROJECTS, {
    ...options,
    ...args,
  });
}

export function useArchivedProjects(args: object) {
  const options = useApolloOptions();

  return useQuery(ARCHIVED_PROJECTS, {
    ...options,
    ...args,
  });
}

export function useProjectTopics(args: object) {
  const options = useApolloOptions();
  return useQuery(PROJECT_TOPICS, { ...options, ...args });
}

export function useProjectGoals(args: object) {
  const options = useApolloOptions();
  return useQuery(PROJECT_GOALS, { ...options, ...args });
}

export function useCreateOneProject() {
  const options = useApolloOptions();

  return useMutation(CREATE_ONE_PROJECT, {
    ...options,
    refetchQueries: [WORKSPACES, WORKSPACE],
  });
}

export function useUpdateOneProject() {
  const options = useApolloOptions();

  return useMutation(UPDATE_ONE_PROJECT, {
    ...options,
    refetchQueries: [PROJECT],
  });
}

export function useJoinOneProject() {
  const options = useApolloOptions();

  return useMutation(JOIN_ONE_PROJECT, {
    ...options,
    refetchQueries: [PROJECT],
  });
}

export function useArchiveOneProject() {
  const options = useApolloOptions();

  return useMutation(ARCHIVE_ONE_PROJECT, {
    ...options,
    refetchQueries: [WORKSPACE, ARCHIVED_PROJECTS],
  });
}

export function useDeleteOneProject() {
  const options = useApolloOptions();

  return useMutation(DELETE_ONE_PROJECT, {
    ...options,
    refetchQueries: [WORKSPACE, ARCHIVED_PROJECTS],
  });
}

export function useRestoreOneProject() {
  const options = useApolloOptions();

  return useMutation(RESTORE_ONE_PROJECT, {
    ...options,
    refetchQueries: [WORKSPACE],
  });
}

export function useCreateOneProjectTopic() {
  const options = useApolloOptions();
  return useMutation(CREATE_ONE_PROJECT_TOPIC, {
    ...options,
  });
}

export function useCreateOneProjectGoal() {
  const options = useApolloOptions();
  return useMutation(CREATE_ONE_PROJECT_GOAL, options);
}
