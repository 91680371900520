import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
} from 'react';
import { toast } from 'react-hot-toast';
import { useParams, useSearchParams } from 'react-router-dom';
import { useDeleteOneTask, useTasks } from 'src/api/task/task.service';
import ToastMessage from 'src/components/ToastMessage';
import { ITask } from 'src/types/entities';

interface TaskModalState {
  showModal: boolean;
  details: ITask | null;
}

interface DeleteConfirmationState {
  showModal: boolean;
  taskToDelete: ITask | null;
}

interface TaskContextType {
  tasks: ITask[] | undefined;
  isLoading: boolean;
  taskModal: TaskModalState;
  deleteConfirmation: DeleteConfirmationState;
  openTaskDetails: (task: ITask) => void;
  closeTaskDetails: () => void;
  openDeleteConfirmation: (task: ITask) => void;
  closeDeleteConfirmation: () => void;
  confirmDeleteTask: () => Promise<void>;
  isDeleting: boolean;
  refetchTasks: () => void;
}

const TaskContext = createContext<TaskContextType | undefined>(undefined);

export const TaskContextProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { projectId } = useParams<{ projectId: string }>();
  const [searchParams, setSearchParams] = useSearchParams();

  const [taskModal, setTaskModal] = React.useState<TaskModalState>({
    showModal: false,
    details: null,
  });
  const [deleteConfirmation, setDeleteConfirmation] =
    React.useState<DeleteConfirmationState>({
      showModal: false,
      taskToDelete: null,
    });

  const queryVariables = useMemo(
    () => ({
      where: { projectId: { equals: projectId } },
      orderBy: { createdAt: 'desc' as const },
    }),
    [projectId]
  );

  const skipQuery = !projectId;

  const { loading, data, refetch } = useTasks({
    variables: queryVariables,
    skip: skipQuery,
    onError: (error: any) => {
      toast((t) => <ToastMessage id={t.id} title={`${error.message}`} />);
    },
  });

  const [deleteOneTask, { loading: deleteOneTaskLoading }] = useDeleteOneTask();

  const openTaskDetails = useCallback(
    (task: ITask) => {
      setTaskModal({ showModal: true, details: task });
      setSearchParams({ taskId: task.id }, { replace: true });
    },
    [setSearchParams]
  );

  const closeTaskDetails = useCallback(() => {
    setTaskModal({ showModal: false, details: null });
    searchParams.delete('taskId');
    setSearchParams(searchParams, { replace: true });
  }, [searchParams, setSearchParams]);

  const openDeleteConfirmation = useCallback((task: ITask) => {
    setDeleteConfirmation({ showModal: true, taskToDelete: task });
  }, []);

  const closeDeleteConfirmation = useCallback(() => {
    setDeleteConfirmation({ showModal: false, taskToDelete: null });
  }, []);

  const confirmDeleteTask = useCallback(async () => {
    if (!deleteConfirmation.taskToDelete || !projectId) return;

    try {
      await deleteOneTask({
        variables: {
          where: {
            id: deleteConfirmation.taskToDelete.id,
            projectId: { equals: projectId },
          },
        },
        onCompleted: () => {
          toast((t) => (
            <ToastMessage id={t.id} title="Task deleted successfully" />
          ));
          closeDeleteConfirmation();
          closeTaskDetails();
        },
        onError: (error) => {
          toast((t) => (
            <ToastMessage
              id={t.id}
              title={`Error deleting task: ${error.message}`}
            />
          ));
        },
      });
    } catch (error) {
      console.error('Error deleting task:', error);
    }
  }, [
    deleteConfirmation.taskToDelete,
    deleteOneTask,
    projectId,
    closeDeleteConfirmation,
    closeTaskDetails,
  ]);

  const refetchTasks = useCallback(() => {
    if (projectId && !skipQuery) {
      refetch(queryVariables);
    }
  }, [projectId, skipQuery, refetch, queryVariables]);

  useEffect(() => {
    if (!skipQuery) {
      refetchTasks();
    }
  }, [refetchTasks, skipQuery]);

  useEffect(() => {
    const taskId = searchParams.get('taskId');
    if (taskId && data?.tasks) {
      const task = data.tasks.find((t: ITask) => t.id === taskId);
      if (task) {
        openTaskDetails(task);
      } else {
        toast((t) => (
          <ToastMessage
            id={t.id}
            title="Task not found. Redirecting to project view"
          />
        ));
        searchParams.delete('taskId');
        setSearchParams(searchParams, { replace: true });
        closeTaskDetails();
      }
    }
  }, [closeTaskDetails, openTaskDetails, searchParams, setSearchParams, data]);

  const contextValue = useMemo<TaskContextType>(
    () => ({
      tasks: data?.tasks,
      isLoading: loading,
      taskModal,
      deleteConfirmation,
      openTaskDetails,
      closeTaskDetails,
      openDeleteConfirmation,
      closeDeleteConfirmation,
      confirmDeleteTask,
      isDeleting: deleteOneTaskLoading,
      refetchTasks,
    }),
    [
      data?.tasks,
      loading,
      taskModal,
      deleteConfirmation,
      openTaskDetails,
      closeTaskDetails,
      openDeleteConfirmation,
      closeDeleteConfirmation,
      confirmDeleteTask,
      deleteOneTaskLoading,
      refetchTasks,
    ]
  );

  return (
    <TaskContext.Provider value={contextValue}>{children}</TaskContext.Provider>
  );
};

export const useTaskContext = () => {
  const context = useContext(TaskContext);
  if (context === undefined) {
    throw new Error('useTaskContext must be used within a TaskProvider');
  }
  return context;
};
